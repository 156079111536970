

.cards {
    --item-width: 111px;
    list-style: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
}

.cards .card:first-of-type {
    /* margin-left: 0px; */
}

.cards .card:last-of-type {
    margin-right: calc(50% - (var(--item-width) / 2));
}

.card {
    view-timeline-name: --item-visible;
    /* animation-timeline: view(y 10dvh); */
    /* view-timeline-name: [ none | <dashed-ident>]# */
    /* view-timeline-name: --image-zoom; */
        /* view-timeline-axis: y; */
    /* view-timeline: --image-zoom y; */
    view-timeline-axis: inline;
    animation: linear adjust-z-index both;
    animation-timeline: --item-visible;
    transform: translate3d(0, 0, 0);
    flex: 0 0 auto;
    perspective: 1000px;
    position: relative;
    z-index: 1;
    will-change: z-index;
    display: block;
    scroll-snap-align: center;
    text-decoration: none;
    background-color: transparent!important;
    border: none!important;
}

.card>.image {
    position: relative;
    display: block;
    animation: linear rotate both;
    animation-timeline: --item-visible;
    will-change: transform;
    width: var(--item-width);
    aspect-ratio: 250/350;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translate3d(-50%, 0, 0) rotate3d(0, 1, 0, -45deg) scale(0.5);
    transform: translate3d(-50%, 0, 0) rotate3d(0, 1, 0, -45deg) scale(0.5);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.image>img {
    display: block;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image .reflection {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(black 60%, rgba(0, 0, 0, 0.5)), var(--image);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 1rem;
    transform: scaleY(-1) translateY(-0.5em);
    opacity: 0.5;
}
/* Hide scrollbar but allow scrolling */
.scroll-content {
    overflow: auto;
}

.scroll-content::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.scroll-content {
    scrollbar-width: none;
    /* Firefox 64 and later */
}

.scroll-content {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}
::-webkit-scrollbar {
    display: none;
}
  @media (max-width: 600px) {
    .cards {
            --item-width: 87px;
           
        }
                .cards .card:first-of-type {
                    margin-left: calc(-20% - (var(--item-width) / 4));
                }
  }
@keyframes adjust-z-index {
    0% {
        z-index: 1;
    }

    50% {
        z-index: 100;
    }

    100% {
        z-index: 1;
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: translate3d(-50%, 0, 0) rotate3d(0, 1, 0, -45deg) scale(0.5);
        transform: translate3d(-50%, 0, 0) rotate3d(0, 1, 0, -45deg) scale(0.5);
        opacity: 0.25;
    }

    35% {
        -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, -40deg) scale(0.75);
        transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, -40deg) scale(0.75);
    }

    50% {
        -webkit-transform: translate3d(0, 0, 1em) rotate3d(0, 1, 0, 0) scale(1);
        transform: translate3d(0, 0, 1em) rotate3d(0, 1, 0, 0) scale(1);
        opacity: 1;
    }

    65% {
        -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, 40deg) scale(0.75);
        transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, 40deg) scale(0.75);
    }

    100% {
        -webkit-transform: translate3d(50%, 0, 0) rotate3d(0, 1, 0, 45deg) scale(0.5);
        transform: translate3d(50%, 0, 0) rotate3d(0, 1, 0, 45deg) scale(0.5);
        opacity: 0.25;
    }
}