  .landing-left {
      padding-top: 13em;
      background-image: url(../images/collage-13.jpg);
      background-size: cover;
      background-repeat: no-repeat;
  }

  .landing-right {
      min-height: auto;
  }

  .parent-grid {
      width: 50%;
      height: 100%;
      overflow: hidden;
  }


  .home-services-grid {
      background-image: url(../images/service.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      /* min-height: 33.3333333333vh; */
      align-content: center;
      transition: transform 0.5s ease-in-out;
  }

  .home-services-grid:hover {
      animation: zoomIn 0.5s forwards;
      overflow: hidden;
  }

  .real-estate-grid {
      background-image: url(../images/portfolio.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      align-content: center;
      /* min-height: 33.3333333333vh; */
      transition: transform 0.5s ease-in-out;
  }

  .real-estate-grid:hover {
      animation: zoomIn 0.5s forwards;
      overflow: hidden;
  }

  .fish-farming-grid {
      background-image: url(../images/fishy-y.png);
      background-size: cover;
      background-repeat: no-repeat;
      align-content: center;
      /* min-height: 33.3333333333vh; */
      transition: transform 0.5s ease-in-out;
  }

  .fish-farming-grid:hover {
      animation: zoomIn 0.5s forwards;
      overflow: hidden;
  }

  .faq-grid {
      background-image: url(../images/faq.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      align-content: center;
      /* min-height: 33.3333333333vh; */
      transition: transform 0.5s ease-in-out;
  }

  .faq-grid:hover {
      animation: zoomIn 0.5s forwards;
      overflow: hidden;
  }

  .right-grid-item {
      /* min-height: 50vh; */
      min-height: 33.3333333333vh;
  }

  .contact-grid {
      background-image: url(../images/contact.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      align-content: center;
      /* min-height: 33.3333333333vh; */
      transition: transform 0.5s ease-in-out;
  }

  .contact-grid:hover {
      animation: zoomIn 0.5s forwards;
      overflow: hidden;
  }

  .about-grid {
      background-image: url(../images/about.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      align-content: center;
      /* min-height: 33.3333333333vh; */
      transition: transform 0.5s ease-in-out;
  }

  .about-grid:hover {
      animation: zoomIn 0.5s forwards;
      overflow: hidden;
  }

  .grid-font-responsive {
      font-size: 33px;
  }

  .grid-font-text {
      font-size: 30px;
      /* font-weight: 300; */
      /* font-family: "Montserrat", sans-serif; */
      /* font-family: "Josefin Sans", sans-serif;
        font-weight: 400; */
  }

  .welcome-kobo-text {
      font-size: 3.2em;
      margin-top: 30px;
  }

  .kobo-logo-nav {
      width: 50px !important;
      margin-right: 5px;
  }

  .kobo-logo-position-landing {
      position: absolute;
      top: 12px;
      left: 29px;
  }

  .kobo-logo-left-img {
      width: 40px;
  }

  .hero-text-under {
      font-size: 18px;
      width: 70%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  }

  .hero-text-under-services {
      font-size: 18px;
      width: 50%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  }

  .kobo-hero-image {
      width: 25em;
      opacity: .7;
  }

  .loader-text {
      font-size: 25px;
  }

  .kobo-catchy-text {
      font-size: 20px;
  }

  @keyframes App-logo-spin {
      from {
          transform: rotate(0deg);
      }

      to {
          transform: rotate(360deg);
      }
  }

  .hero-text-bunch {
      width: 84%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  }

  .responsive-display-none {
      display: block;
  }

  .home-service-hero-text-top {
      font-size: 43px !important;
  }

  .hero-title-services {
      font-size: 36px;
      color: #fff;
  }

  .logo-nav-text {
      font-size: 21px;
  }

  .hero-img-services-responsive {
      visibility: hidden;
  }

  .display-3-kobo {
      font-size: 4.3em;
  }

  .display-2-kobo {
      font-size: 37px;
  }

  .writer_p {
      font-size: 14px;
  }

  .hero__slideContent--right {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #3a72a4;
      clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 0);
      left: 5px;
      top: -8px;
      z-index: 1;
  }


  .kobo-auth-container {
      margin-top: -5em;
  }

  .my-account-section {
      margin-top: 2em;
  }

  .change-loca {
      font-size: 12px;
      color: #007bff;
      cursor: pointer;
  }

  .loca-show {

      font-size: 14px;
  }
.kobo-w75{
    width: 75%!important;
}
.kobo-dash-title{
    font-size: 30px;
}
#kobo-side-menu {
    display: block;
}
.landing-side-menu {
    width: 70vw;
    height: 100%;
    background-color: #1f384d;
    position: absolute;
    z-index: 999;
    right: 0;
    /* transform: translateX(100%); */
    /* transition: transform 0.3s ease; */
    padding: 20px;
    box-sizing: border-box;
    /* display: none; */
}

.landing-side-menu.open {
    transform: translateX(0);
}

.vtm-side-menu-header {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-bottom: 74px;
    color: #ffffffc9;
    width: 100%;
}

.vtm-side-menu-header h2 {
    margin: 0;
    font-size: 22px;
}

.vtm-close-btn {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 34px;
    cursor: pointer;
    font-weight: 200;
}
.kobo-fish-her0-text{
    font-size: 63px;
}
.vtm-menu-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.vtm-menu-items li {
    margin: 15px 0;
}

.vtm-menu-items a {
    color: #ffffffc9;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 10px 0;
    transition: background-color 0.3s ease;
    font-weight: 200;
}

.vtm-menu-items a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.vtm-side-menu-footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: white;
}

.no-scroll {
    overflow: hidden;
    width: 100vw;
    /* Prevents horizontal overflow */
}
  @media (max-width: 600px) {
    #kobo-side-menu{
        display: none;
    }
      .change-loca {
          font-size: 10px;
      }
          .kobo-dash-title {
              font-size: 21px;
          }
                  .kobo-fish-her0-text {
                      font-size: 33px;
                  }
.kobo-w75 {
    width: 100%!important;
}
      .loca-show {

          font-size: 12px;
      }

      .my-account-section {
          margin-top: -1em;
      }

      .kobo-auth-container {
          margin-top: -7em;
      }

      /* .hero__slideContent--right {
           
            background-color: transparent;
        } */

      .hero__slideContent--right {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          /* background-color: #f58c11; */
          background-color: #3a72a4;
          clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 0);
          left: 7px;
          top: -6px;
          z-index: 1;
      }

      .typewriter_h1 {

          font-size: 1.5rem;
          font-weight: 500;

      }

      .writer_p {
          font-size: 12px;
      }

      .kobo-logo-left-img {
          width: 32px;
      }

      .display-2-kobo {
          font-size: 23px;
      }

      .display-3-kobo {
          font-size: 3em;
      }

      .hero-img-services-responsive {
          visibility: visible;
          animation-duration: 1s;
          animation: bannerMovement 2s infinite ease-in-out;
      }

      .status-box .status-number {
          -ms-flex-negative: 0;
          flex-shrink: 0;
          font-size: 30px;
          font-weight: 400;
          line-height: 1;
          color: #076fcc;
          margin: 0 26px 0 0;
      }

      .logo-nav-text {
          font-size: 20px;
      }

      .hero-text-under-services {
          font-size: 18px;
          width: 50%;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
      }

      .hero-title-services {
          font-size: 27px;
          color: #fff;
      }

      .hero-text-under-services {
          font-size: 18px;
          width: 90%;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
      }

      .kobo-logo-nav {
          width: 50px !important;
      }

      .responsive-display-none {
          display: none !important;
      }

      .home-service-hero-text-top {
          font-size: 23px !important;
      }

      .kobo-catchy-text {
          font-size: 14px;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
      }

      .kobo-hero-image {
          width: 14em;
      }

      .loader-text {
          font-size: 20px;
      }

      .hero-text-under {
          font-size: 14px;
          width: 90%;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
      }

      .landing-left {
          padding-top: 13%;
          padding-bottom: 1em;
          min-height: 50vh;
          /* max-height: 50vh; */

      }

      .landing-right {
          min-height: 50vh;
      }

      .welcome-kobo-text {
          font-size: 2em;
          margin-top: -10px;
      }

      .grid-font-text {
          font-size: 18px;
      }

      .grid-font-responsive {
          font-size: 25px;
      }

      .home-services-grid {
          /* background-image: url(../images/service.jpg); */
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 14em;
          align-content: center;
      }

      .real-estate-grid {
          /* background-image: url(../images/portfolio.jpg); */
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 14em;
      }

      .faq-grid {
          background-image: url(../images/faq.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          align-content: center;
          min-height: 14em;
      }

      .fish-farming-grid {
          background-image: url(../images/fishh.png);
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 14em;
      }
  }