@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ADLaM+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');



.montserrat-700 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

.montserrat-500 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.montserrat-600 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
ul li, strong, address{
    font-family: "Outfit", sans-serif!important;
}
.outfit-800 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
}

.outfit-700 {
    font-family: "Outfit", sans-serif!important;
    font-optical-sizing: auto;
    font-weight: 700!important;
    font-style: normal;
}

.outfit-600 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
.outfit-500 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}
.outfit-400 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.quicksand-800 {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
}
.quicksand-700 {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}
.quicksand-600 {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
.quicksand-500 {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.pacifico-regular-800 {
    font-family: "Pacifico", cursive;
    font-weight: 800;
    font-style: normal;
}
.montserrat-600 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
}
.josefin-sans-500 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
    font-optical-sizing: auto;
    font-style: normal;
}

.oswald-300 {
    font-family: "Oswald", sans-serif!important;
    font-optical-sizing: auto;
    font-weight: 300!important;
    font-style: normal;
}
.oswald-360 {
    font-family: "Oswald", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 360 !important;
    font-style: normal;
}

.oswald-500 {
    font-family: "Oswald", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 500 !important;
    font-style: normal;
}

#drawer-kobo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #241f1fc4;
    z-index: 99999;
    overflow: hidden !important;
    display: none;
}
#drawer-kobo-meeting {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #241f1fc4;
    z-index: 99999;
    overflow: hidden !important;
    display: none;
}
#preloader-meeting {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #211f24b3;
    z-index: 99999;
    overflow: hidden !important;
    backdrop-filter: blur(3px);
    /* display: none; */
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fffffffb;
    z-index: 99999;
    overflow: hidden !important;
}

#status {
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40vh;
    text-align: center;
}

.adlam-display-regular-700 {
    font-family: "ADLaM Display", system-ui!important;
    font-weight: 700!important;
    font-style: normal;
}

.price-plan-buy-btn {
    border: none;
    background: #067cc4;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
}

.danger-badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    color: #fff;
    background-color: #dc3545;
    border-radius: 10rem;
    text-align: center;
    vertical-align: baseline;
}

.success-badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    color: #fff;
    background-color: green;
    border-radius: 10rem;
    text-align: center;
    vertical-align: baseline;
}

.pending-badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    color: #fff;
    background-color: #e2ab4a;
    border-radius: 10rem;
    text-align: center;
    vertical-align: baseline;
}