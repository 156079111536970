.facial-verification-container {
    text-align: center;
}

.capture-area video,
.image-preview img {
    margin-top: 20px;
    border: 10px solid #17b16e14;
    border-radius: 5px;
    margin-left: -11px;
}

.capture-button,
.start-camera-button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #29ad14;
    color: #fff;
    border: none;
}

.submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #29ad14;
    color: #fff;
    border: none;
    cursor: pointer;
}