.site-wrapper,
body,
html {
    height: 100%;
    width: 100%;
    
}

/* body {
    font-family: "Outfit", sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.74;
    position: relative;
    visibility: visible;
    overflow-x: hidden;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    letter-spacing: 0;
    color: #696969;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
    
    font-family: "Outfit", sans-serif!important;
    font-weight: 700;
    line-height: 1.3;
    margin-top: 0;
    color: #333;
}
label, input, button{
    font-family: "Outfit", sans-serif !important;
}
p {

    font-family: "Outfit", sans-serif!important;
    line-height: 1.3;
    margin-top: 0;
    color: #333;
}

a {

    font-family: "Outfit", sans-serif !important;
    line-height: 1.3;
    margin-top: 0;
    /* font-weight: 600!important; */
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: inherit;
    margin: 0;
}

p:last-child {
    margin-bottom: 0;
}

a,
button {
    line-height: inherit;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

a,
button,
img,
input {
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

:focus {
    outline: 0 !important;
}

a:focus {
    text-decoration: none;
    color: inherit;
    outline: 0;
}

a:hover {
    text-decoration: none;
    color: #076fcc;
}

button,
input[type=submit] {
    cursor: pointer;
}

img {
    max-width: 100%
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
}

ul:last-child {
    margin-bottom: 0;
}

hr {
    border-top-width: 2px;
}

svg:not(:root) {
    overflow: hidden;
}

.playfair-font {
    font-family: "Playfair Display", serif;
}

.margin-15 {
    margin: 15px;
}

.section-border-bottom {
    border-bottom: 1px solid #e7e7e7;
}

.jarallax {
    position: relative;
    z-index: 0;
}



.max-width-100 {
    max-width: 100%
}

.fix {
    overflow: hidden;
}

.main-wrapper,
.section {
    float: left;
    width: 100%
}

@media only screen and (min-width:1600px) {

    .section-fluid>.container,
    .section-fluid>.container-fluid {
        width: 100%;
        max-width: none;
        padding-right: 150px;
        padding-left: 150px;
    }
}

@media only screen and (min-width:1600px) {

    .section-fluid-two>.container,
    .section-fluid-two>.container-fluid {
        width: 100%;
        max-width: none;
        padding-right: 240px;
        padding-left: 240px;
    }
}

@media only screen and (min-width:1200px) {
    .container {
        max-width: 1200px;
    }
}

@media only screen and (max-width:575px) {
    .container {
        max-width: 450px;
    }
}

.bg-primary {
    background-color: #076fcc !important;
}

.text-primary {
    color: #076fcc !important;
}

.bg-primary-two {
    background-color: #3a7e69 !important;
}

.text-primary-two {
    color: #3a7e69 !important;
}

.bg-primary-three {
    background-color: #ef6f31 !important;
}

.text-primary-three {
    color: #ef6f31 !important;
}

.bg-secondary {
    background-color: #3f3a64 !important;
}

.text-secondary {
    color: #3f3a64 !important;
}

.bg-secondary-light {
    background-color: #8c89a2 !important;
}

.text-secondary-light {
    color: #8c89a2 !important;
}

.bg-secondary-three {
    background-color: #6ac5f1 !important;
}

.text-secondary-three {
    color: #6ac5f1 !important;
}

.bg-success {
    background-color: #4caf50 !important;
}

.text-success {
    color: #4caf50 !important;
}

.bg-danger {
    background-color: #f44336 !important;
}

.text-danger {
    color: #f44336 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.text-warning {
    color: #ffc107 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.text-info {
    color: #17a2b8 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.text-light {
    color: #f8f9fa !important;
}

.bg-dark {
    background-color: #333 !important;
}

.text-dark {
    color: #333 !important;
}

.bg-black {
    background-color: #000 !important;
}

.text-black {
    color: #000 !important;
}

.bg-grey {
    background-color: #f8f9fd !important;
}

.text-grey {
    color: #f8f9fd !important;
}

.bg-body {
    background-color: #696969 !important;
}

.text-body {
    color: #696969 !important;
}

.bg-off-white {
    background-color: #f6f2ed !important;
}

.text-off-white {
    color: #f6f2ed !important;
}

.bg-light-orange {
    background-color: rgba(239, 111, 49, .1) !important;
}

.text-light-orange {
    color: rgba(239, 111, 49, .1) !important;
}

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

[data-overlay] {
    position: relative;
    z-index: 1;
}

[data-overlay]::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
}

.overlay {
    position: relative;
}

.overlay::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .4);
}

[data-overlay="0"]::before {
    opacity: 0;
}

[data-overlay="5"]::before {
    opacity: .05;
}

[data-overlay="10"]::before {
    opacity: .1;
}

[data-overlay="15"]::before {
    opacity: .15;
}

[data-overlay="20"]::before {
    opacity: .2;
}

[data-overlay="25"]::before {
    opacity: .25;
}

[data-overlay="30"]::before {
    opacity: .3;
}

[data-overlay="35"]::before {
    opacity: .35;
}

[data-overlay="40"]::before {
    opacity: .4;
}

[data-overlay="45"]::before {
    opacity: .45;
}

[data-overlay="50"]::before {
    opacity: .5;
}

[data-overlay="55"]::before {
    opacity: .55;
}

[data-overlay="60"]::before {
    opacity: .6;
}

[data-overlay="65"]::before {
    opacity: .65;
}

[data-overlay="70"]::before {
    opacity: .7;
}

[data-overlay="75"]::before {
    opacity: .75;
}

[data-overlay="80"]::before {
    opacity: .8;
}

[data-overlay="85"]::before {
    opacity: .85;
}

[data-overlay="90"]::before {
    opacity: .9;
}

[data-overlay="95"]::before {
    opacity: .95;
}

[data-overlay="100"]::before {
    opacity: 1;
}

[data-bg-image] {
    background-repeat: repeat;
    background-position: top center;
}

[data-bg-image]:not(.bg-pattern) {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}

.bg-pattern-one {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain !important;
}

.about-section,
.about-video-section,
.cta-section,
.download-app-section,
.newsletter-section,
.teaching-section {
    overflow: hidden;
}

.overflow-inherit {
    overflow: inherit !important;
}

.scroll-top {
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 999;
    -webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, .03);
    box-shadow: 0 30px 50px rgba(0, 0, 0, .03);
    display: block;
    padding: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: #076fcc;
    background-size: 200% auto;
    background-position: left center;
    color: #fff;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    overflow: hidden;
}

@media only screen and (max-width:479px) {
    .scroll-top {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
    }
}

.scroll-top.show {
    visibility: visible;
    opacity: 1;
    bottom: 60px;
}

.scroll-top i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.scroll-top .arrow-top {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
    -webkit-transform: translate(-50%, 80px);
    -ms-transform: translate(-50%, 80px);
    transform: translate(-50%, 80px);
}

.scroll-top:hover {
    background-position: right center;
}

.scroll-top:hover .arrow-top {
    -webkit-transform: translate(-50%, -80px);
    -ms-transform: translate(-50%, -80px);
    transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.border-top-dashed {
    border-top: 1px dashed #d2d2d2;
}

.border-bottom-dashed {
    border-bottom: 1px dashed #d2d2d2;
}

.img-hover-color {
    -webkit-filter: grayscale(100);
    filter: grayscale(100);
}

.img-hover-color:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.section-bottom-shape {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 70px;
}

.section-bottom-shape.fill-color-gray svg path {
    fill: #f8f8f8;
}

.section-bottom-shape svg {
    width: 100%;
    height: 100%
}

.section-bottom-shape svg path {
    fill: #fff;
}

.section-bottom-shape-two {
    position: absolute;
    z-index: -1;
    bottom: -1px;
    left: 0;
    width: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    overflow: hidden;
}

.section-bottom-shape-two svg {
    width: 100%;
    height: 100%;
    height: 310px;
    -webkit-transform: translateX(0) rotateY(180deg);
    transform: translateX(0) rotateY(180deg);
}

@media only screen and (max-width:767px) {
    .section-bottom-shape-two svg {
        height: 80px;
    }
}

.section-bottom-shape-two svg path {
    fill: #f8f8f8;
}

.section-bottom-shape-three {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    width: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    overflow: hidden;
}

.section-bottom-shape-three svg {
    width: 100%;
    height: 100%;
    height: 440px;
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%) rotateY(180deg);
    transform: translateX(-50%) rotateY(180deg);
}

@media only screen and (max-width:767px) {
    .section-bottom-shape-three svg {
        height: 80px;
    }
}

.section-bottom-shape-three svg path {
    fill: rgba(51, 51, 51, .06);
}

.section-bottom-shape-four {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    width: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    overflow: hidden;
}

.section-bottom-shape-four svg {
    height: 600px;
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media only screen and (max-width:767px) {
    .section-bottom-shape-four svg {
        height: 80px;
    }
}

.section-bottom-shape-four svg path {
    fill: rgba(255, 255, 255, .04);
}

.section-bottom-shape-five {
    position: absolute;
    z-index: -1;
    bottom: -1px;
    left: 0;
    width: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    overflow: hidden;
}

.section-bottom-shape-five svg {
    width: calc(100% + 1.3px);
    height: 100%;
    height: 300px;
    display: block;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media only screen and (max-width:767px) {
    .section-bottom-shape-five svg {
        height: 80px;
    }
}

.section-bottom-shape-five svg path {
    fill: #faf8f6;
}

.theme-color {
    color: #076fcc !important;
}

.swiper-pagination {
    position: static;
    display: block;
    line-height: 1;
    margin-top: 20px;
}

.swiper-pagination.theme-color {
    margin-top: 50px;
}

.swiper-pagination.theme-color .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
}

.swiper-pagination.theme-color .swiper-pagination-bullet:before {
    background: #5c5b6e;
}

.swiper-pagination.theme-color .swiper-pagination-bullet:hover:before {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: #076fcc;
}

.swiper-pagination.theme-color .swiper-pagination-bullet-active:before {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: #076fcc;
}

.swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 0;
    border-radius: 0;
    background: 0 0;
    opacity: 1;
    position: relative;
    outline: 0;
    margin-right: 20px;
    margin: 0 8px !important;
}

.swiper-pagination .swiper-pagination-bullet:last-child {
    margin-right: 0;
}

.swiper-pagination .swiper-pagination-bullet:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #d8d8d8;
    z-index: 1;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.swiper-pagination .swiper-pagination-bullet:hover:before {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: #333;
}

.swiper-pagination .swiper-pagination-bullet-active:before {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: #333;
}

.site-wrapper-reveal {
    background-color: #fff;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet {
    margin: 8px;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet:hover:before {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: #fff;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet-active::before {
    opacity: 1;
    background: #fff;
    width: 16px;
    height: 16px;
    -webkit-box-shadow: 0 0 9px #ededed;
    box-shadow: 0 0 9px #ededed;
}

.swiper-nav-button {
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .1s linear 2s, opacity .1s linear 2s;
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .1s linear 2s, opacity .1s linear 2s;
    transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .1s linear 2s, opacity .1s linear 2s;
    background-image: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    width: 48px;
    height: 48px;
    font-size: 24px;
    color: #6d70a6;
    border: 0;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .01);
    box-shadow: 0 0 5px rgba(0, 0, 0, .01);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin: 0;
    top: 50%
}

.swiper-nav-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    color: #fff;
    background: #fff;
}

.swiper-nav-button:hover {
    color: #fff;
}

.swiper-nav-button:hover::before {
    color: #fff;
    background: #333;
}

.swiper-container {
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.swiper-container:hover .swiper-nav-button {
    opacity: 1;
    visibility: visible;
}

.swiper-nav-button i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.swiper-nav-button i::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    content: '\f104'
}

.swiper-nav-prev i::before {
    content: '\f104'
}

.swiper-button-next i::before {
    content: '\f105'
}

.swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}

.section-padding-top-0 {
    padding-top: 0 !important;
}

.section-padding-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.section-padding {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }
}

@media only screen and (max-width:767px) {
    .section-padding {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }
}

.section-padding-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-padding-200 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-200 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-200 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.section-padding-t80-b100 {
    padding-top: 80px;
    padding-bottom: 100px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-t80-b100 {
        padding-top: 60px;
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-t80-b100 {
        padding-top: 45px;
        padding-bottom: 60px;
    }
}

.section-padding-top {
    padding-top: 100px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-top {
        padding-top: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-top {
        padding-top: 60px;
    }
}

.section-padding-top-190 {
    padding-top: 190px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-top-190 {
        padding-top: 170px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-top-190 {
        padding-top: 150px;
    }
}

.section-padding-top-200 {
    padding-top: 200px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-padding-top-200 {
        padding-top: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-top-200 {
        padding-top: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-top-200 {
        padding-top: 60px;
    }
}

.section-padding-top-250 {
    padding-top: 250px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .section-padding-top-250 {
        padding-top: 100px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-padding-top-250 {
        padding-top: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-top-250 {
        padding-top: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-top-250 {
        padding-top: 60px;
    }
}

.section-padding-top-320 {
    padding-top: 320px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-top-320 {
        padding-top: 170px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-top-320 {
        padding-top: 150px;
    }
}

.section-padding-bottom {
    padding-bottom: 100px !important;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom {
        padding-bottom: 80px !important;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom {
        padding-bottom: 60px !important;
    }
}

.section-padding-bottom-80 {
    padding-bottom: 80px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom-80 {
        padding-bottom: 60px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom-80 {
        padding-bottom: 40px;
    }
}

.section-padding-bottom-70 {
    padding-bottom: 70px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom-70 {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom-70 {
        padding-bottom: 60px;
    }
}

.section-padding-bottom-90 {
    padding-bottom: 90px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom-90 {
        padding-bottom: 70px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom-90 {
        padding-bottom: 50px;
    }
}

.section-padding-bottom-120 {
    padding-bottom: 120px !important;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom-120 {
        padding-bottom: 100px !important;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom-120 {
        padding-bottom: 80px !important;
    }
}

.section-padding-bottom-160 {
    padding-bottom: 160px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-padding-bottom-160 {
        padding-bottom: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom-160 {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom-160 {
        padding-bottom: 60px;
    }
}

.section-padding-bottom-180 {
    padding-bottom: 180px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-padding-bottom-180 {
        padding-bottom: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom-180 {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom-180 {
        padding-bottom: 60px;
    }
}

.section-padding-bottom-200 {
    padding-bottom: 200px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .section-padding-bottom-200 {
        padding-bottom: 100px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-padding-bottom-200 {
        padding-bottom: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-padding-bottom-200 {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .section-padding-bottom-200 {
        padding-bottom: 60px;
    }
}

.section-padding-dark {
    padding-top: 100px;
    padding-bottom: 50px;
}

#video-section-1 {
    margin-bottom: 140px;
}

.plr-40 {
    padding: 0 40px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .plr-40 {
        padding: 0 15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .plr-40 {
        padding: 0 15px;
    }
}

@media only screen and (max-width:767px) {
    .plr-40 {
        padding: 0 15px;
    }
}

.margin-bottom-100 {
    margin-bottom: 100px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .margin-bottom-100 {
        margin-bottom: 80px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .margin-bottom-100 {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width:767px) {
    .margin-bottom-100 {
        margin-bottom: 40px;
    }
}

.margin-bottom-85 {
    margin-bottom: 85px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .margin-bottom-85 {
        margin-bottom: 60px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .margin-bottom-85 {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width:767px) {
    .margin-bottom-85 {
        margin-bottom: 30px;
    }
}

.mb-minus-90 {
    margin-bottom: -90px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mb-minus-90 {
        margin-bottom: -50px;
    }
}

.mt-minus-125 {
    margin-top: -125px;
    position: relative;
    z-index: 9;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .mt-minus-125 {
        margin-top: -90px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mt-minus-125 {
        margin-top: -90px;
    }
}

@media only screen and (max-width:767px) {
    .mt-minus-125 {
        margin-top: -90px;
    }
}

.ml-minus-150 {
    margin-left: -150px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .ml-minus-150 {
        margin-left: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .ml-minus-150 {
        margin-left: 0;
        margin-bottom: 50px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .ml-minus-150 {
        margin-left: 0;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width:767px) {
    .ml-minus-150 {
        margin-left: 0;
        margin-bottom: 30px;
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666%;
        flex: 0 0 41.66666%;
        max-width: 41.66666%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666%;
        flex: 0 0 66.66666%;
        max-width: 66.66666%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666%;
        flex: 0 0 91.66666%;
        max-width: 91.66666%
    }
}

@media only screen and (min-width:1600px) {
    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
}

@media only screen and (min-width:1600px) {
    .order-xxl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
}

.max-mb-10 {
    margin-bottom: 10px !important;
}

.max-mb-n10 {
    margin-bottom: -10px;
}

.max-mb-20 {
    margin-bottom: 20px !important;
}

.max-mb-n20 {
    margin-bottom: -20px;
}

.max-mb-30 {
    margin-bottom: 30px !important;
}

.max-mb-n30 {
    margin-bottom: -30px;
}

.max-mb-40 {
    margin-bottom: 40px !important;
}

.max-mb-n40 {
    margin-bottom: -40px;
}

.max-mb-50 {
    margin-bottom: 50px !important;
}

.max-mb-n50 {
    margin-bottom: -50px;
}

.max-mb-60 {
    margin-bottom: 60px !important;
}

.max-mb-n60 {
    margin-bottom: -60px;
}

.max-mb-70 {
    margin-bottom: 70px !important;
}

.max-mb-n70 {
    margin-bottom: -70px;
}

.max-mb-80 {
    margin-bottom: 80px !important;
}

.max-mb-n80 {
    margin-bottom: -80px;
}

.max-mb-90 {
    margin-bottom: 90px !important;
}

.max-mb-n90 {
    margin-bottom: -90px;
}

.max-mb-100 {
    margin-bottom: 100px !important;
}

.max-mb-n100 {
    margin-bottom: -100px;
}

.max-mt-10 {
    margin-top: 10px;
}

.max-mt-n10 {
    margin-top: -10px;
}

.max-mt-20 {
    margin-top: 20px;
}

.max-mt-n20 {
    margin-top: -20px;
}

.max-mt-30 {
    margin-top: 30px;
}

.max-mt-n30 {
    margin-top: -30px;
}

.max-mt-40 {
    margin-top: 40px;
}

.max-mt-n40 {
    margin-top: -40px;
}

.max-mt-50 {
    margin-top: 50px;
}

.max-mt-n50 {
    margin-top: -50px;
}

.max-mt-60 {
    margin-top: 60px;
}

.max-mt-n60 {
    margin-top: -60px;
}

.max-mt-70 {
    margin-top: 70px;
}

.max-mt-n70 {
    margin-top: -70px;
}

.max-mt-80 {
    margin-top: 80px;
}

.max-mt-n80 {
    margin-top: -80px;
}

.max-mt-90 {
    margin-top: 90px;
}

.max-mt-n90 {
    margin-top: -90px;
}

.max-mt-100 {
    margin-top: 100px;
}

.max-mt-n100 {
    margin-top: -100px;
}

.max-pb-10 {
    padding-bottom: 10px;
}

.max-pb-20 {
    padding-bottom: 20px;
}

.max-pb-30 {
    padding-bottom: 30px;
}

.max-pb-40 {
    padding-bottom: 40px;
}

.max-pb-50 {
    padding-bottom: 50px;
}

.max-pb-60 {
    padding-bottom: 60px;
}

.max-pb-70 {
    padding-bottom: 70px;
}

.max-pb-80 {
    padding-bottom: 80px;
}

.max-pb-90 {
    padding-bottom: 90px;
}

.max-pb-100 {
    padding-bottom: 100px;
}

.max-pt-10 {
    padding-top: 10px;
}

.max-pt-20 {
    padding-top: 20px;
}

.max-pt-30 {
    padding-top: 30px;
}

.max-pt-40 {
    padding-top: 40px;
}

.max-pt-50 {
    padding-top: 50px;
}

.max-pt-60 {
    padding-top: 60px;
}

.max-pt-70 {
    padding-top: 70px;
}

.max-pt-80 {
    padding-top: 80px;
}

.max-pt-90 {
    padding-top: 90px;
}

.max-pt-100 {
    padding-top: 100px;
}

.row-25 {
    margin-left: -25px;
    margin-right: -25px;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .row-25 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .row-25 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .row-25 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width:767px) {
    .row-25 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.row-25>[class*=col] {
    padding-left: 25px;
    padding-right: 25px;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .row-25>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .row-25>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .row-25>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width:767px) {
    .row-25>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.row-30 {
    margin-left: -30px;
    margin-right: -30px;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .row-30 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .row-30 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .row-30 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width:767px) {
    .row-30 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.row-30>[class*=col] {
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .row-30>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .row-30>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .row-30>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width:767px) {
    .row-30>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.row-40 {
    margin-left: -40px;
    margin-right: -40px;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .row-40 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .row-40 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .row-40 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width:767px) {
    .row-40 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.row-40>[class*=col] {
    padding-left: 40px;
    padding-right: 40px;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .row-40>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .row-40>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .row-40>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width:767px) {
    .row-40>[class*=col] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-150 {
    margin-top: 150px !important;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .mt-md-0 {
        margin-top: 0 !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mt-sm-0 {
        margin-top: 0 !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mt-sm-50 {
        margin-top: 50px !important;
    }
}

@media only screen and (max-width:767px) {
    .mt-xs-0 {
        margin-top: 0 !important;
    }
}

@media only screen and (max-width:767px) {
    .mt-xs-50 {
        margin-top: 50px !important;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .mb-md-50 {
        margin-bottom: 50px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mb-sm-30 {
        margin-bottom: 30px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mb-sm-40 {
        margin-bottom: 40px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mb-sm-50 {
        margin-bottom: 50px !important;
    }
}

@media only screen and (max-width:767px) {
    .mb-xs-30 {
        margin-bottom: 30px !important;
    }
}

@media only screen and (max-width:767px) {
    .mb-xs-40 {
        margin-bottom: 40px !important;
    }
}

@media only screen and (max-width:767px) {
    .mb-xs-50 {
        margin-bottom: 50px !important;
    }
}

.ml-15 {
    margin-left: 15px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.pl-80 {
    padding-left: 80px !important;
}

.pl-85 {
    padding-left: 85px !important;
}

.pl-90 {
    padding-left: 90px !important;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .pl-lg-15 {
        padding-left: 15px !important;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .pl-md-0 {
        padding-left: 0 !important;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .pl-md-15 {
        padding-left: 15px !important;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .pl-md-20 {
        padding-left: 20px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .pl-sm-0 {
        padding-left: 0 !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .pl-sm-15 {
        padding-left: 15px !important;
    }
}

@media only screen and (max-width:767px) {
    .pl-xs-0 {
        padding-left: 0 !important;
    }
}

@media only screen and (max-width:767px) {
    .pl-xs-15 {
        padding-left: 15px !important;
    }
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-55 {
    padding-right: 55px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-80 {
    padding-right: 80px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .pr-lg-0 {
        padding-right: 0 !important;
    }
}

@media only screen and (min-width:1200px) and (max-width:1499px) {
    .pr-lg-15 {
        padding-right: 15px !important;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .pr-md-0 {
        padding-right: 0 !important;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .pr-md-15 {
        padding-right: 15px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .pr-sm-0 {
        padding-right: 0 !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .pr-sm-15 {
        padding-right: 15px !important;
    }
}

@media only screen and (max-width:767px) {
    .pr-xs-0 {
        padding-right: 0 !important;
    }
}

@media only screen and (max-width:767px) {
    .pr-xs-15 {
        padding-right: 15px !important;
    }
}

input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url] {
    width: 100%;
    min-height: 56px;
    padding: 3px 20px;
    color: #696969;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    outline: 0;
    background-color: #f5f5f5;
}

input[type=email]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=text]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder {
    color: #9f9f9f;
}

input[type=email]:-moz-placeholder,
input[type=number]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=search]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=text]:-moz-placeholder,
input[type=url]:-moz-placeholder {
    color: #9f9f9f;
}

input[type=email]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=text]::-moz-placeholder,
input[type=url]::-moz-placeholder {
    color: #9f9f9f;
}

input[type=email]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=text]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder {
    color: #9f9f9f;
}

input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=url]:focus {
    border-color: #076fcc;
    background-color: #fff;
}

textarea {
    width: 100%;
    min-height: 220px;
    padding: 10px 20px;
    color: #696969;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    outline: 0;
    background-color: #f5f5f5;
}

textarea::-webkit-input-placeholder {
    color: #9f9f9f;
}

textarea:-moz-placeholder {
    color: #9f9f9f;
}

textarea::-moz-placeholder {
    color: #9f9f9f;
}

textarea:-ms-input-placeholder {
    color: #9f9f9f;
}

textarea:focus {
    border-color: #076fcc;
    background-color: #fff;
}

.instructor-register-from {
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 30px rgba(0, 0, 0, .1);
    box-shadow: 0 2px 30px rgba(0, 0, 0, .1);
    padding: 56px 50px 60px;
}

@media only screen and (max-width:479px) {
    .instructor-register-from {
        padding: 30px 15px 30px;
    }
}

.instructor-register-from .title {
    font-size: 34px;
    line-height: 1.42;
    margin: 0 0 36px;
    text-align: center;
}

@media only screen and (max-width:479px) {
    .instructor-register-from .title {
        font-size: 28px;
        margin-bottom: 20px;
    }
}

.learn-press-message {
    padding: 10px 12px 10px 15px;
    background: #f5f5f5;
    position: relative;
    margin: 0 0 20px;
    border-radius: 5px;
    padding-left: 45px;
}

.learn-press-message::before {
    content: '\f05a';
    color: #00adff;
    width: auto;
    height: auto;
    font-size: 16px;
    background: 0 0 !important;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    font-family: "Font Awesome 5 Pro"
}

.comment-form-cookies-consent {
    text-align: center;
    margin-bottom: 20px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.comment-form-cookies-consent input {
    margin-right: 5px;
    margin-top: 5px;
}

.login-form-wrapper {
    padding: 30px 50px 50px;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(51, 51, 51, .1);
    box-shadow: 0 0 10px rgba(51, 51, 51, .1);
}

@media only screen and (max-width:479px) {
    .login-form-wrapper {
        padding: 30px 15px 30px;
    }
}

.login-form-wrapper .title {
    font-size: 34px;
    line-height: 1.42;
    font-weight: 700;
    margin-bottom: 18px;
}

.single-input label {
    color: #333;
}

.single-input .description {
    font-style: italic;
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 0;
}

.lost-your-password-wrap {
    text-align: right;
}

@media only screen and (max-width:575px) {
    .lost-your-password-wrap {
        text-align: left;
    }
}

@media only screen and (max-width:479px) {
    .lost-your-password-wrap {
        text-align: left;
    }
}

.checkbox-input input {
    margin: 0 10px 0 3px;
}

.btn {
    font-size: 14px;
    font-weight: 700;
    line-height: 50px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 52px;
    padding: 0 40px;
    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 5px;
    outline: 0;
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-icon {
    width: 56px;
    padding: 15px;
}

.btn.disabled {
    opacity: .65;
}

.btn-sm {
    font-size: 12px;
    padding: 5px 20px;
}

.btn-xs {
    height: 32px;
    line-height: 30px;
    padding: 0 20px;
}

.btn-md {
    font-size: 14px;
    padding: 10px 30px;
}

.btn-lg {
    font-size: 18px;
    padding: 20px 50px;
}

.btn-xl {
    font-size: 20px;
    padding: 25px 60px;
}

.btn-width-100 {
    width: 100%
}

.btn-width-260 {
    width: 260px;
}

.btn-width-300 {
    width: 300px;
}

.btn-width-180 {
    width: 180px;
}

.btn-height-60 {
    height: 60px;
    line-height: 58px;
}

.btn-primary {
    border-color: #076fcc;
    background-color: #076fcc;
    color: #fff;
}

.btn-primary:focus,
.btn-primary:hover {
    border-color: transparent;
    background-color: #1a8d7a;
    color: #fff;
}

.btn-outline-primary {
    color: #076fcc;
    border-color: #076fcc;
    background-color: transparent;
}

.btn-outline-primary:hover {
    border-color: transparent;
    background-color: #076fcc;
    color: #fff;
}

.btn-primary-two {
    border-color: #3a7e69;
    background-color: #3a7e69;
    color: #fff;
}

.btn-primary-two:focus,
.btn-primary-two:hover {
    border-color: transparent;
    background-color: #2e6453;
    color: #fff;
}

.btn-outline-primary-two {
    color: #3a7e69;
    border-color: #3a7e69;
    background-color: transparent;
}

.btn-outline-primary-two:hover {
    border-color: transparent;
    background-color: #3a7e69;
    color: #fff;
}

.btn-primary-three {
    border-color: #ef6f31;
    background-color: #ef6f31;
    color: #fff;
}

.btn-primary-three:focus,
.btn-primary-three:hover {
    border-color: transparent;
    background-color: #e85812;
    color: #fff;
}

.btn-outline-primary-three {
    color: #ef6f31;
    border-color: #ef6f31;
    background-color: transparent;
}

.btn-outline-primary-three:hover {
    border-color: transparent;
    background-color: #ef6f31;
    color: #fff;
}

.btn-secondary {
    border-color: #3f3a64;
    background-color: #3f3a64;
    color: #fff;
}

.btn-secondary:focus,
.btn-secondary:hover {
    border-color: transparent;
    background-color: #302c4c;
    color: #fff;
}

.btn-outline-secondary {
    color: #3f3a64;
    border-color: #3f3a64;
    background-color: transparent;
}

.btn-outline-secondary:hover {
    border-color: transparent;
    background-color: #3f3a64;
    color: #fff;
}

.btn-secondary-light {
    border-color: #8c89a2;
    background-color: #8c89a2;
    color: #fff;
}

.btn-secondary-light:focus,
.btn-secondary-light:hover {
    border-color: transparent;
    background-color: #777491;
    color: #fff;
}

.btn-outline-secondary-light {
    color: #8c89a2;
    border-color: #8c89a2;
    background-color: transparent;
}

.btn-outline-secondary-light:hover {
    border-color: transparent;
    background-color: #8c89a2;
    color: #fff;
}

.btn-secondary-three {
    border-color: #6ac5f1;
    background-color: #6ac5f1;
    color: #fff;
}

.btn-secondary-three:focus,
.btn-secondary-three:hover {
    border-color: transparent;
    background-color: #47b7ee;
    color: #fff;
}

.btn-outline-secondary-three {
    color: #6ac5f1;
    border-color: #6ac5f1;
    background-color: transparent;
}

.btn-outline-secondary-three:hover {
    border-color: transparent;
    background-color: #6ac5f1;
    color: #fff;
}

.btn-success {
    border-color: #4caf50;
    background-color: #4caf50;
    color: #fff;
}

.btn-success:focus,
.btn-success:hover {
    border-color: transparent;
    background-color: #409444;
    color: #fff;
}

.btn-outline-success {
    color: #4caf50;
    border-color: #4caf50;
    background-color: transparent;
}

.btn-outline-success:hover {
    border-color: transparent;
    background-color: #4caf50;
    color: #fff;
}

.btn-danger {
    border-color: #f44336;
    background-color: #f44336;
    color: #fff;
}

.btn-danger:focus,
.btn-danger:hover {
    border-color: transparent;
    background-color: #f22112;
    color: #fff;
}

.btn-outline-danger {
    color: #f44336;
    border-color: #f44336;
    background-color: transparent;
}

.btn-outline-danger:hover {
    border-color: transparent;
    background-color: #f44336;
    color: #fff;
}

.btn-warning {
    border-color: #ffc107;
    background-color: #ffc107;
    color: #333;
}

.btn-warning:focus,
.btn-warning:hover {
    border-color: transparent;
    background-color: #e0a800;
    color: #333;
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
    background-color: transparent;
}

.btn-outline-warning:hover {
    border-color: transparent;
    background-color: #ffc107;
    color: #333;
}

.btn-info {
    border-color: #17a2b8;
    background-color: #17a2b8;
    color: #fff;
}

.btn-info:focus,
.btn-info:hover {
    border-color: transparent;
    background-color: #138496;
    color: #fff;
}

.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
    background-color: transparent;
}

.btn-outline-info:hover {
    border-color: transparent;
    background-color: #17a2b8;
    color: #fff;
}

.btn-light {
    border-color: #f8f9fa;
    background-color: #f8f9fa;
    color: #333;
}

.btn-light:focus,
.btn-light:hover {
    border-color: transparent;
    background-color: #e2e6ea;
    color: #333;
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:hover {
    border-color: transparent;
    background-color: #f8f9fa;
    color: #333;
}

.btn-dark {
    border-color: #333;
    background-color: #333;
    color: #fff;
}

.btn-dark:focus,
.btn-dark:hover {
    border-color: transparent;
    background-color: #202020;
    color: #fff;
}

.btn-outline-dark {
    color: #333;
    border-color: #333;
    background-color: transparent;
}

.btn-outline-dark:hover {
    border-color: transparent;
    background-color: #333;
    color: #fff;
}

.btn-black {
    border-color: #000;
    background-color: #000;
    color: #fff;
}

.btn-black:focus,
.btn-black:hover {
    border-color: transparent;
    background-color: #000;
    color: #fff;
}

.btn-outline-black {
    color: #000;
    border-color: #000;
    background-color: transparent;
}

.btn-outline-black:hover {
    border-color: transparent;
    background-color: #000;
    color: #fff;
}

.btn-grey {
    border-color: #f8f9fd;
    background-color: #f8f9fd;
    color: #fff;
}

.btn-grey:focus,
.btn-grey:hover {
    border-color: transparent;
    background-color: #dae0f5;
    color: #fff;
}

.btn-outline-grey {
    color: #f8f9fd;
    border-color: #f8f9fd;
    background-color: transparent;
}

.btn-outline-grey:hover {
    border-color: transparent;
    background-color: #f8f9fd;
    color: #fff;
}

.btn-body {
    border-color: #696969;
    background-color: #696969;
    color: #fff;
}

.btn-body:focus,
.btn-body:hover {
    border-color: transparent;
    background-color: #565656;
    color: #fff;
}

.btn-outline-body {
    color: #696969;
    border-color: #696969;
    background-color: transparent;
}

.btn-outline-body:hover {
    border-color: transparent;
    background-color: #696969;
    color: #fff;
}

.btn-off-white {
    border-color: #f6f2ed;
    background-color: #f6f2ed;
    color: #333;
}

.btn-off-white:focus,
.btn-off-white:hover {
    border-color: transparent;
    background-color: #e9e0d4;
    color: #fff;
}

.btn-outline-off-white {
    color: #f6f2ed;
    border-color: #f6f2ed;
    background-color: transparent;
}

.btn-outline-off-white:hover {
    border-color: transparent;
    background-color: #f6f2ed;
    color: #fff;
}

.btn-light-orange {
    border-color: rgba(239, 111, 49, .1);
    background-color: rgba(239, 111, 49, .1);
    color: #fff;
}

.btn-light-orange:focus,
.btn-light-orange:hover {
    border-color: transparent;
    background-color: rgba(232, 88, 18, .1);
    color: #fff;
}

.btn-outline-light-orange {
    color: rgba(239, 111, 49, .1);
    border-color: rgba(239, 111, 49, .1);
    background-color: transparent;
}

.btn-outline-light-orange:hover {
    border-color: transparent;
    background-color: rgba(239, 111, 49, .1);
    color: #fff;
}

.btn-hover-primary:hover,
.btn-hover-primary:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #076fcc;
    color: #fff;
}

.btn-outline-hover-primary:hover,
.btn-outline-hover-primary:not(:disabled):not(.disabled).active {
    color: #076fcc;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-primary-two:hover,
.btn-hover-primary-two:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #3a7e69;
    color: #fff;
}

.btn-outline-hover-primary-two:hover,
.btn-outline-hover-primary-two:not(:disabled):not(.disabled).active {
    color: #3a7e69;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-primary-three:hover,
.btn-hover-primary-three:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #ef6f31;
    color: #fff;
}

.btn-outline-hover-primary-three:hover,
.btn-outline-hover-primary-three:not(:disabled):not(.disabled).active {
    color: #ef6f31;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-secondary:hover,
.btn-hover-secondary:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #3f3a64;
    color: #fff;
}

.btn-outline-hover-secondary:hover,
.btn-outline-hover-secondary:not(:disabled):not(.disabled).active {
    color: #3f3a64;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-secondary-light:hover,
.btn-hover-secondary-light:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #8c89a2;
    color: #fff;
}

.btn-outline-hover-secondary-light:hover,
.btn-outline-hover-secondary-light:not(:disabled):not(.disabled).active {
    color: #8c89a2;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-secondary-three:hover,
.btn-hover-secondary-three:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #6ac5f1;
    color: #fff;
}

.btn-outline-hover-secondary-three:hover,
.btn-outline-hover-secondary-three:not(:disabled):not(.disabled).active {
    color: #6ac5f1;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-success:hover,
.btn-hover-success:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #4caf50;
    color: #fff;
}

.btn-outline-hover-success:hover,
.btn-outline-hover-success:not(:disabled):not(.disabled).active {
    color: #4caf50;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-danger:hover,
.btn-hover-danger:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #f44336;
    color: #fff;
}

.btn-outline-hover-danger:hover,
.btn-outline-hover-danger:not(:disabled):not(.disabled).active {
    color: #f44336;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-warning:hover,
.btn-hover-warning:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #ffc107;
    color: #333;
}

.btn-outline-hover-warning:hover,
.btn-outline-hover-warning:not(:disabled):not(.disabled).active {
    color: #ffc107;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-info:hover,
.btn-hover-info:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #17a2b8;
    color: #fff;
}

.btn-outline-hover-info:hover,
.btn-outline-hover-info:not(:disabled):not(.disabled).active {
    color: #17a2b8;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-light:hover,
.btn-hover-light:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #f8f9fa;
    color: #333;
}

.btn-outline-hover-light:hover,
.btn-outline-hover-light:not(:disabled):not(.disabled).active {
    color: #f8f9fa;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-dark:hover,
.btn-hover-dark:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #333;
    color: #fff;
}

.btn-outline-hover-dark:hover,
.btn-outline-hover-dark:not(:disabled):not(.disabled).active {
    color: #333;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-black:hover,
.btn-hover-black:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #000;
    color: #fff;
}

.btn-outline-hover-black:hover,
.btn-outline-hover-black:not(:disabled):not(.disabled).active {
    color: #000;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-grey:hover,
.btn-hover-grey:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #f8f9fd;
    color: #fff;
}

.btn-outline-hover-grey:hover,
.btn-outline-hover-grey:not(:disabled):not(.disabled).active {
    color: #f8f9fd;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-body:hover,
.btn-hover-body:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #696969;
    color: #fff;
}

.btn-outline-hover-body:hover,
.btn-outline-hover-body:not(:disabled):not(.disabled).active {
    color: #696969;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-off-white:hover,
.btn-hover-off-white:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: #f6f2ed;
    color: #fff;
}

.btn-outline-hover-off-white:hover,
.btn-outline-hover-off-white:not(:disabled):not(.disabled).active {
    color: #f6f2ed;
    border-color: transparent;
    background-color: transparent;
}

.btn-hover-light-orange:hover,
.btn-hover-light-orange:not(:disabled):not(.disabled).active {
    border-color: transparent;
    background-color: rgba(239, 111, 49, .1);
    color: #fff;
}

.btn-outline-hover-light-orange:hover,
.btn-outline-hover-light-orange:not(:disabled):not(.disabled).active {
    color: rgba(239, 111, 49, .1);
    border-color: transparent;
    background-color: transparent;
}

.load-more-btn {
    min-width: 250px;
    border-color: #eee;
}

.learn-more-btn {
    border: 2px solid #eee;
}

.link {
    font-weight: 500;
    line-height: 1.67em;
    color: #8c89a2;
}

.link-lg {
    font-size: 18px;
}

.link.primary-three mark {
    color: #ef6f31;
}

.link.primary-three mark::after {
    background-color: #ef6f31;
}

.link.link-color mark {
    color: #3a7e69;
}

.link.link-color mark::after {
    background-color: #3a7e69;
}

.link mark {
    font-weight: 700;
    line-height: 1;
    position: relative;
    padding: 3px 0;
    color: #076fcc;
    background-color: transparent;
}

.link mark::after,
.link mark::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    background: #e0e0e0;
}

.link mark::after {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1), -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -webkit-transform: scaleX(0) translateZ(0);
    transform: scaleX(0) translateZ(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    background-color: #076fcc;
}

.link:hover {
    color: #8c89a2;
}

.link:hover mark::before {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1), -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -webkit-transform: scaleX(0) translateZ(0);
    transform: scaleX(0) translateZ(0);
}

.link:hover mark::after {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
}

.breadcrumb {
    padding: 12px 0;
    background-color: transparent;
}

.breadcrumb li {
    font-size: 14px;
    line-height: 1.67;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #333;
}

.breadcrumb li+li::before {
    margin: 0 15px;
    color: #696969;
}

.breadcrumb li a {
    position: relative;
    display: block;
    color: #696969;
}

.breadcrumb li a::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    content: "";
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    background-color: #333;
}

.breadcrumb li a:hover {
    color: #333;
}

.breadcrumb li a:hover::before {
    left: 0;
    width: 100%
}

.banner-slider .banner-slider-nav {
    cursor: pointer;
    background: rgba(0, 0, 0, .5);
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    display: block;
    text-align: center;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.banner-slider .banner-slider-nav::after {
    display: none;
}

.banner-slider .banner-slider-nav i {
    font-size: 30px;
    line-height: 1;
    vertical-align: middle;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .banner-slider .banner-slider-nav {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }

    .banner-slider .banner-slider-nav i {
        font-size: 18px;
    }
}

@media only screen and (max-width:767px) {
    .banner-slider .banner-slider-nav {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .banner-slider .banner-slider-nav i {
        font-size: 18px;
    }
}

.banner-slider .banner-slider-prev {
    left: 30px;
}

.banner-slider .banner-slider-next {
    right: 30px;
}

.banner-slider .banner-slider-next::after {
    display: none;
}

.banner-slider:hover .banner-slider-nav {
    opacity: 1;
    visibility: visible;
}

.banner-slider-item {
    width: 100%;
    height: 700px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: relative;
    z-index: 1;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .banner-slider-item {
        height: 700px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .banner-slider-item {
        height: 500px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .banner-slider-item {
        height: 500px;
    }
}

@media only screen and (max-width:767px) {
    .banner-slider-item {
        height: 450px;
    }
}

.banner-content-area {
    padding-bottom: 50px;
}

.banner-slider-content-2.left {
    text-align: left;
}

.banner-slider-content-2.center {
    text-align: center;
}

.banner-slider-content-2.right {
    text-align: right;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .banner-slider-content-2.right {
        text-align: left;
    }
}

@media only screen and (max-width:767px) {
    .banner-slider-content-2.right {
        text-align: left;
    }
}

.banner-slider-content-2>* {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

.banner-slider-content-2 h1 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .banner-slider-content-2 h1 {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:767px) {
    .banner-slider-content-2 h1 {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:575px) {
    .banner-slider-content-2 h1 {
        font-size: 32px;
        line-height: 38px;
    }
}

.banner-slider-content-2 h3 {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 2px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.banner-slider-content-2 h3 .number {
    font-size: 34px;
    line-height: 34px;
    vertical-align: middle;
}

.banner-slider-content-2 h3 .line {
    display: inline-block;
    width: 60px;
    height: 1px;
    background-color: #fff;
    vertical-align: middle;
    margin-right: 30px;
    margin-left: 15px;
}

@media only screen and (max-width:479px) {
    .banner-slider-content-2 h3 .line {
        display: none;
    }
}

.banner-slider-content-2 p {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    color: #fff;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .banner-slider-content-2 p {
        font-size: 20px;
        line-height: 30px;
    }
}

@media only screen and (max-width:767px) {
    .banner-slider-content-2 p {
        font-size: 20px;
        line-height: 30px;
    }
}

@media only screen and (max-width:479px) {
    .banner-slider-content-2 p {
        font-size: 16px;
        line-height: 26px;
    }
}

.swiper-slide-active .banner-slider-content-2>* {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.swiper-slide-active .banner-slider-content-2>:nth-child(1) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.swiper-slide-active .banner-slider-content-2>:nth-child(2) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.swiper-slide-active .banner-slider-content-2>:nth-child(3) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.swiper-slide-active .banner-slider-content-2>:nth-child(4) {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.swiper-slide-active .banner-slider-content-2>:nth-child(5) {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.swiper-slide-active .banner-slider-content-2>:nth-child(6) {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.page-banner-slider .swiper-slide {
    -webkit-transform: scale(1.35);
    -ms-transform: scale(1.35);
    transform: scale(1.35);
    transform: scale(1.35);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-slide-active .swiper-slide {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    -o-transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1), -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
}

.page-banner-title {
    max-width: 770px;
    margin: 0 auto;
    text-align: center;
}

.page-banner-title .title {
    font-size: 34px;
    line-height: 48px;
    color: #fff;
    margin-bottom: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .page-banner-title .title {
        font-size: 32px;
        line-height: 46px;
    }
}

@media only screen and (max-width:767px) {
    .page-banner-title .title {
        font-size: 24px;
        line-height: 36px;
    }
}

.page-banner-title.course-banner-title {
    max-width: 100%
}

.page-banner-title.course-banner-title .title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.17;
    color: #fff;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .page-banner-title.course-banner-title .title {
        font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .page-banner-title.course-banner-title .title {
        font-size: 36px;
    }
}

@media only screen and (max-width:767px) {
    .page-banner-title.course-banner-title .title {
        font-size: 28px;
    }
}

.page-title-bar-meta {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.67;
    text-transform: capitalize;
    color: #fff;
    margin-top: 15px;
    text-align: center;
}

.page-title-bar-meta>div {
    display: inline-block;
    padding: 5px 10px;
}

.page-title-bar-meta>div .meta-icon {
    font-size: 14px;
    margin-right: 7px;
}

.page-title-bar-meta>div .meta-icon img {
    width: 32px;
    vertical-align: middle;
    border-radius: 50%
}

.table {
    color: #696969;
}

.table thead tr th {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    padding: 20px 10px 20px 0;
    letter-spacing: 1.38px;
    text-transform: uppercase;
    color: #333;
    border-color: #f3f3f3;
    border-top: none;
}

.table thead tr th.product-thumbnail {
    width: 105px;
}

.table tbody tr td {
    padding: 20px 10px 20px 0;
    vertical-align: middle;
    border-color: #f3f3f3;
}

.table tbody tr td.product-remove {
    padding-right: 0;
}

.table tbody tr td.product-price .amount,
.table tbody tr td.product-subtotal .amount {
    font-weight: 600;
    color: #8c89a2;
}

.table tbody tr td.product-quantity .quantity {
    position: relative;
    width: 110px;
}

.table tbody tr td.product-quantity .quantity button {
    position: absolute;
    right: 0;
    width: 36px;
    height: 50%;
    padding: 0;
    border: none;
    background-color: transparent;
}

.table tbody tr td.product-quantity .quantity button::before {
    position: absolute;
    right: 14px;
    content: "";
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

.table tbody tr td.product-quantity .quantity button.increase::before {
    bottom: 4px;
    border-bottom: 5px solid #ccc;
}

.table tbody tr td.product-quantity .quantity button.increase:hover::before {
    border-bottom-color: #076fcc;
}

.table tbody tr td.product-quantity .quantity button.decrease {
    top: 50%
}

.table tbody tr td.product-quantity .quantity button.decrease::before {
    top: 4px;
    border-top: 5px solid #ccc;
}

.table tbody tr td.product-quantity .quantity button.decrease:hover::before {
    border-top-color: #076fcc;
}

.table tbody tr td.product-quantity .quantity .qty {
    min-height: 48px;
    padding: 2px 36px 2px 10px;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.table tfoot tr td {
    padding: 10px 20px;
    border-color: #f3f3f3;
}

.table-striped tbody tr:nth-child(2n+1) td {
    background: #fff;
}

.table-striped tbody tr:nth-child(2n) td {
    background: rgba(0, 0, 0, .01);
}

.icon-box {
    position: relative;
    display: block;
    padding: 40px 20px 30px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    z-index: 99;
}

.icon-box.icon-box-left {
    padding: 0;
}

.icon-box.icon-box-left:hover {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.icon-box.h5-icon-box {
    padding: 0;
}

.icon-box.h5-icon-box .content .title {
    font-size: 20px;
    color: #333;
}

.icon-box.h5-icon-box .content .link {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    min-height: 40px;
    background-color: transparent;
    border-radius: 5px;
    color: #8c89a2;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.icon-box.h5-icon-box .content .link i {
    font-size: 16px;
    line-height: 14px;
    margin-left: 14px;
}

.icon-box.h5-icon-box:hover {
    z-index: 1;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.icon-box.h5-icon-box:hover .content .link {
    background-color: #f8f8f8;
    color: #076fcc;
}

.icon-box .icon {
    margin-bottom: 24px;
}

.icon-box .icon svg {
    width: 60px;
    height: 60px;
}

.icon-box .content .title {
    font-size: 24px;
    line-height: 1.5;
    margin: 0;
    color: #3f3a64;
}

.icon-box .content .title.fz-20 {
    font-size: 20px;
}

.icon-box .content .desc {
    margin-top: 10px;
}

.icon-box .content .desc p {
    line-height: 1.6;
    color: #696969;
}

.icon-box .content .link {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 34px;
    padding: 5px 0;
    color: #8c89a2;
}

.icon-box .content .link i {
    font-size: 16px;
    line-height: 14px;
    margin-left: 14px;
}

.icon-box:hover {
    z-index: 1;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.icon-box:hover .content .link {
    color: #076fcc;
}

.health-icon-box {
    position: relative;
    display: block;
    padding: 30px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    z-index: 99;
}

.health-icon-box .icon {
    margin-bottom: 40px;
}

@media only screen and (max-width:767px) {
    .health-icon-box .icon {
        margin-bottom: 20px;
    }
}

.health-icon-box .content .title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
    color: #3f3a64;
}

@media only screen and (max-width:767px) {
    .health-icon-box .content .title {
        font-size: 22px;
    }
}

.health-icon-box .content .desc {
    margin-top: 15px;
}

.health-icon-box .content .desc p {
    font-size: 18px;
    line-height: 1.67;
    color: #696969;
}

.health-icon-box:hover {
    z-index: 1;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.icon-box-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.icon-box-list .icon {
    margin-right: 35px;
}

.icon-box-list .icon svg {
    width: 48px;
    height: 48px;
}

.icon-box-list .content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.icon-box-list .content .title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
    color: #333;
}

.icon-box-list .content .desc {
    margin-top: 10px;
}

.icon-box-list .content .desc p {
    line-height: 1.6;
    color: #696969;
}

.potentials-image {
    text-align: right;
    margin-right: -75px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .potentials-image {
        margin-right: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .potentials-image {
        margin-right: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .potentials-image {
        margin-right: 0;
    }
}

@media only screen and (max-width:767px) {
    .potentials-image {
        margin-right: 0;
    }
}

.single-health-service {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.single-health-service .icon {
    margin-right: 34px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .single-health-service .icon {
        margin-right: 20px;
    }
}

@media only screen and (max-width:479px) {
    .single-health-service .icon {
        margin-right: 20px;
    }
}

.single-health-service .content .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    color: #3f3a64;
    margin: 0;
}

@media only screen and (max-width:479px) {
    .single-health-service .content .title {
        font-size: 20px;
    }
}

.single-health-service .content .description {
    margin-top: 25px;
}

.single-health-service .content .description ul {
    padding-left: 20px;
    margin: 0;
}

.single-health-service .content .description ul li {
    font-size: 18px;
    line-height: 1.67;
}

@media only screen and (max-width:479px) {
    .single-health-service .content .description ul li {
        font-size: 16px;
        line-height: 1.37;
    }
}

.single-health-service .content .description ul li+li {
    margin-top: 10px;
}

.service-shape-animation {
    position: relative;
    z-index: 9;
}

.service-shape-animation .shape {
    position: absolute;
    z-index: -1;
}

.service-shape-animation .shape-1 {
    top: -80px;
    left: 5%
}

.service-shape-animation .shape-2 {
    top: -36px;
    right: 5%
}

.service-shape-animation .shape-3 {
    z-index: -1;
    top: 5%;
    right: 50px;
}

.service-shape-animation .shape-4 {
    left: -130px;
    top: 40%
}

.service-shape-animation .shape-5 {
    left: -130px;
    bottom: 0;
}

.service-shape-animation .shape-6 {
    right: 5%;
    bottom: 8%
}

.service-shape-animation .shape-7 {
    right: -60px;
    bottom: -70px;
}

.service-shape-animation .shape-8 {
    left: 10%;
    bottom: -109px;
}

.service-shape-animation .shape-9 {
    left: 40%;
    bottom: -85px;
}

.service-shape-animation .shape-10 {
    left: 47%;
    bottom: -140px;
}

.service-shape-animation .shape-11 {
    left: 10%;
    top: 5%
}

.service-shape-animation .shape-12 {
    left: 3%;
    bottom: 8%
}

.service-shape-animation .shape-13 {
    right: -50px;
    top: 5%
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .service-shape-animation .shape {
        position: absolute;
        z-index: -1;
    }

    .service-shape-animation .shape-1 {
        top: -40px;
        left: 0;
        width: 150px;
    }

    .service-shape-animation .shape-2 {
        top: -36px;
        right: 5%;
        width: 150px;
    }

    .service-shape-animation .shape-3 {
        z-index: -1;
        top: 5%;
        right: 50px;
        width: 150px;
    }

    .service-shape-animation .shape-4 {
        left: 0;
        top: 40%;
        width: 150px;
    }

    .service-shape-animation .shape-5 {
        left: 0;
        bottom: 0;
        width: 150px;
    }

    .service-shape-animation .shape-6 {
        right: 5%;
        bottom: 8%;
        width: 150px;
    }

    .service-shape-animation .shape-7 {
        right: 0;
        bottom: -20px;
        width: 150px;
    }

    .service-shape-animation .shape-8 {
        left: 10%;
        bottom: -50px;
        width: 150px;
    }

    .service-shape-animation .shape-9 {
        left: 40%;
        bottom: 0;
        width: 150px;
    }

    .service-shape-animation .shape-10 {
        left: 47%;
        bottom: -30px;
        width: 150px;
    }

    .service-shape-animation .shape-11 {
        left: 10%;
        top: 5%;
        width: 150px;
    }

    .service-shape-animation .shape-12 {
        left: 3%;
        bottom: 8%;
        width: 150px;
    }

    .service-shape-animation .shape-13 {
        right: 0;
        top: 5%;
        width: 150px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .service-shape-animation .shape {
        position: absolute;
        z-index: -1;
    }

    .service-shape-animation .shape-1 {
        top: -40px;
        left: 0;
        width: 150px;
    }

    .service-shape-animation .shape-2 {
        top: -36px;
        right: 5%;
        width: 150px;
    }

    .service-shape-animation .shape-3 {
        z-index: -1;
        top: 5%;
        right: 50px;
        width: 150px;
    }

    .service-shape-animation .shape-4 {
        left: 0;
        top: 40%;
        width: 150px;
    }

    .service-shape-animation .shape-5 {
        left: 0;
        bottom: 0;
        width: 150px;
    }

    .service-shape-animation .shape-6 {
        right: 5%;
        bottom: 8%;
        width: 150px;
    }

    .service-shape-animation .shape-7 {
        right: 0;
        bottom: -20px;
        width: 150px;
    }

    .service-shape-animation .shape-8 {
        left: 10%;
        bottom: -50px;
        width: 150px;
    }

    .service-shape-animation .shape-9 {
        left: 40%;
        bottom: 0;
        width: 150px;
    }

    .service-shape-animation .shape-10 {
        left: 47%;
        bottom: -30px;
        width: 150px;
    }

    .service-shape-animation .shape-11 {
        left: 10%;
        top: 5%;
        width: 150px;
    }

    .service-shape-animation .shape-12 {
        left: 3%;
        bottom: 8%;
        width: 150px;
    }

    .service-shape-animation .shape-13 {
        right: 0;
        top: 5%;
        width: 150px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .service-shape-animation .shape {
        position: absolute;
        z-index: -1;
    }

    .service-shape-animation .shape-1 {
        top: -40px;
        left: 0;
        width: 100px;
    }

    .service-shape-animation .shape-2 {
        top: -36px;
        right: 5%;
        width: 150px;
    }

    .service-shape-animation .shape-3 {
        display: none;
    }

    .service-shape-animation .shape-4 {
        display: none;
    }

    .service-shape-animation .shape-5 {
        left: 0;
        bottom: 0;
        width: 150px;
    }

    .service-shape-animation .shape-6 {
        right: 5%;
        bottom: 8%;
        width: 100px;
    }

    .service-shape-animation .shape-7 {
        display: none;
    }

    .service-shape-animation .shape-8 {
        display: none;
    }

    .service-shape-animation .shape-9 {
        display: none;
    }

    .service-shape-animation .shape-10 {
        display: none;
    }

    .service-shape-animation .shape-11 {
        left: 10%;
        top: 5%;
        width: 140px;
    }

    .service-shape-animation .shape-12 {
        left: 3%;
        bottom: 8%;
        width: 80px;
    }

    .service-shape-animation .shape-13 {
        right: 0;
        top: 5%;
        width: 120px;
    }
}

@media only screen and (max-width:767px) {
    .service-shape-animation .shape {
        position: absolute;
        z-index: -1;
    }

    .service-shape-animation .shape-1 {
        top: -40px;
        left: 0;
        width: 100px;
    }

    .service-shape-animation .shape-2 {
        top: -36px;
        right: 5%;
        width: 150px;
    }

    .service-shape-animation .shape-3 {
        display: none;
    }

    .service-shape-animation .shape-4 {
        display: none;
    }

    .service-shape-animation .shape-5 {
        left: 0;
        bottom: 0;
        width: 150px;
    }

    .service-shape-animation .shape-6 {
        right: 5%;
        bottom: 8%;
        width: 100px;
    }

    .service-shape-animation .shape-7 {
        display: none;
    }

    .service-shape-animation .shape-8 {
        display: none;
    }

    .service-shape-animation .shape-9 {
        display: none;
    }

    .service-shape-animation .shape-10 {
        display: none;
    }

    .service-shape-animation .shape-11 {
        left: 10%;
        top: 5%;
        width: 140px;
    }

    .service-shape-animation .shape-12 {
        left: 3%;
        bottom: 8%;
        width: 80px;
    }

    .service-shape-animation .shape-13 {
        right: 0;
        top: 5%;
        width: 120px;
    }
}

@media only screen and (max-width:479px) {
    .service-shape-animation .shape {
        position: absolute;
        z-index: -1;
        display: none;
    }
}

.funfact-border {
    padding: 99px 15px 52px;
    background-position: inherit;
}

@media only screen and (max-width:767px) {
    .funfact-border {
        background-position: top center !important;
    }
}

.funfact-border-three {
    padding: 132px 15px 90px;
    background-position: 15px 50%;
    background-repeat: repeat-x;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .funfact-border-three {
        padding: 100px 0;
        background-position: center center;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .funfact-border-three {
        padding: 80px 0;
        background-position: center center;
    }
}

@media only screen and (max-width:767px) {
    .funfact-border-three {
        padding: 60px 0 50px;
        background-position: center center;
    }
}

.funfact {
    text-align: center;
}

.funfact.funfact-style-two .title {
    max-width: 180px;
    font-size: 24px;
    line-height: 1.34;
    color: #3f3a64;
    margin: 0 auto;
    margin-bottom: 30px;
}

.funfact.funfact-style-two .number {
    font-size: 64px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .funfact.funfact-style-two .number {
        font-size: 48px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .funfact.funfact-style-two .number {
        font-size: 48px;
    }
}

@media only screen and (max-width:767px) {
    .funfact.funfact-style-two .number {
        font-size: 48px;
    }
}

.funfact.funfact-style-two .text {
    color: #696969;
}

.funfact.funfact-3 .icon {
    color: #f8f8f8;
    margin-bottom: 35px;
}

.funfact.funfact-3 .icon svg {
    width: 60px;
    height: 60px;
}

.funfact.funfact-3 .icon svg path {
    stroke: currentColor;
}

.funfact.funfact-3 .text {
    font-weight: 500;
    line-height: 1.4;
    margin-top: 10px;
    color: rgba(249, 249, 249, .7);
}

.funfact.funfact-motivation .number {
    font-size: 40px;
    font-weight: 600;
}

.funfact.funfact-motivation .text {
    font-weight: 500;
    line-height: 1.4;
    color: #333;
}

.funfact .number {
    font-size: 48px;
    font-weight: 800;
    line-height: 1;
    display: block;
    color: #076fcc;
}

.funfact .text {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.34;
    margin-top: 14px;
    margin-bottom: 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #3f3a64;
}

.pagination {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.pagination.center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.pagination li a {
    font-size: 16px;
    font-weight: 800;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 48px;
    padding: 10px 15px;
    text-transform: uppercase;
    color: #ababab;
    border-radius: 50%
}

.pagination li a:not(.prev):not(.next) {
    width: 48px;
}

.pagination li a:hover {
    color: #333;
}

.pagination li a.active {
    cursor: default;
    pointer-events: none;
    color: #333;
    background-color: #eee;
}

[class*=hintT] {
    position: relative;
    z-index: 9;
}

[class*=hintT]::before {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
    position: absolute;
    z-index: 9;
    visibility: hidden;
    padding: 8px 10px;
    content: attr(data-hint);
    -webkit-transition: opacity .3s ease, visibility .3s ease, -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    -o-transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24), -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    white-space: nowrap;
    white-space: nowrap;
    text-transform: initial;
    pointer-events: none;
    opacity: 0;
    color: #fff;
    border-radius: 4px;
    background: #333;
    -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, .3);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, .3);
}

[class*=hintT]::after {
    position: absolute;
    z-index: 9;
    visibility: hidden;
    content: "";
    -webkit-transition: opacity .3s ease, visibility .3s ease, -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    -o-transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24), -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    opacity: 0;
    border: 6px solid transparent;
    background: 0 0;
}

[class*=hintT]:hover {
    z-index: 11;
}

[class*=hintT]:hover::after,
[class*=hintT]:hover::before {
    visibility: visible;
    opacity: 1;
}

.hintT-top::before {
    bottom: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(4px);
    -ms-transform: translateX(-50%) translateY(4px);
    transform: translateX(-50%) translateY(4px);
}

.hintT-top::after {
    bottom: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(15px);
    -ms-transform: translateX(-50%) translateY(15px);
    transform: translateX(-50%) translateY(15px);
    border-top-color: #333;
}

.hintT-top:hover::before {
    -webkit-transform: translateX(-50%) translateY(-6px);
    -ms-transform: translateX(-50%) translateY(-6px);
    transform: translateX(-50%) translateY(-6px);
}

.hintT-top:hover::after {
    -webkit-transform: translateX(-50%) translateY(5px);
    -ms-transform: translateX(-50%) translateY(5px);
    transform: translateX(-50%) translateY(5px);
}

.hintT-right::before {
    top: 50%;
    left: 100%;
    -webkit-transform: translateY(-50%) translateX(-4px);
    -ms-transform: translateY(-50%) translateX(-4px);
    transform: translateY(-50%) translateX(-4px);
}

.hintT-right::after {
    top: 50%;
    left: 100%;
    -webkit-transform: translateY(-50%) translateX(-15px);
    -ms-transform: translateY(-50%) translateX(-15px);
    transform: translateY(-50%) translateX(-15px);
    border-right-color: #333;
}

.hintT-right:hover::before {
    -webkit-transform: translateY(-50%) translateX(6px);
    -ms-transform: translateY(-50%) translateX(6px);
    transform: translateY(-50%) translateX(6px);
}

.hintT-right:hover::after {
    -webkit-transform: translateY(-50%) translateX(-5px);
    -ms-transform: translateY(-50%) translateX(-5px);
    transform: translateY(-50%) translateX(-5px);
}

.hintT-bottom::before {
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-4px);
    -ms-transform: translateX(-50%) translateY(-4px);
    transform: translateX(-50%) translateY(-4px);
}

.hintT-bottom::after {
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-15px);
    -ms-transform: translateX(-50%) translateY(-15px);
    transform: translateX(-50%) translateY(-15px);
    border-bottom-color: #333;
}

.hintT-bottom:hover::before {
    -webkit-transform: translateX(-50%) translateY(6px);
    -ms-transform: translateX(-50%) translateY(6px);
    transform: translateX(-50%) translateY(6px);
}

.hintT-bottom:hover::after {
    -webkit-transform: translateX(-50%) translateY(-5px);
    -ms-transform: translateX(-50%) translateY(-5px);
    transform: translateX(-50%) translateY(-5px);
}

.hintT-left::before {
    top: 50%;
    right: 100%;
    -webkit-transform: translateY(-50%) translateX(4px);
    -ms-transform: translateY(-50%) translateX(4px);
    transform: translateY(-50%) translateX(4px);
}

.hintT-left::after {
    top: 50%;
    right: 100%;
    -webkit-transform: translateY(-50%) translateX(15px);
    -ms-transform: translateY(-50%) translateX(15px);
    transform: translateY(-50%) translateX(15px);
    border-left-color: #333;
}

.hintT-left:hover::before {
    -webkit-transform: translateY(-50%) translateX(-6px);
    -ms-transform: translateY(-50%) translateX(-6px);
    transform: translateY(-50%) translateX(-6px);
}

.hintT-left:hover::after {
    -webkit-transform: translateY(-50%) translateX(5px);
    -ms-transform: translateY(-50%) translateX(5px);
    transform: translateY(-50%) translateX(5px);
}

.hintT-primary::before {
    color: #fff;
    background-color: #076fcc;
}

.hintT-primary.hintT-top::after {
    border-top-color: #076fcc;
}

.hintT-primary.hintT-right::after {
    border-right-color: #076fcc;
}

.hintT-primary.hintT-bottom::after {
    border-bottom-color: #076fcc;
}

.hintT-primary.hintT-left::after {
    border-left-color: #076fcc;
}

.faq-left-image {
    position: relative;
    z-index: 9;
    padding: 0 0 0 40px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .faq-left-image {
        padding-left: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .faq-left-image {
        padding-left: 0;
        margin-bottom: 50px;
        overflow: hidden;
    }
}

@media only screen and (max-width:767px) {
    .faq-left-image {
        padding-left: 0;
        margin-bottom: 50px;
        overflow: hidden;
    }
}

.faq-left-image .shape {
    position: absolute;
    z-index: 1;
}

.faq-left-image .shape-1 {
    top: 50px;
    left: -60px;
    z-index: -1;
    width: 392px;
    height: 392px;
    -webkit-transform: rotate(0) scale(1, 1);
    -ms-transform: rotate(0) scale(1, 1);
    transform: rotate(0) scale(1, 1);
}

.faq-left-image .shape-1 svg {
    width: 100%;
    height: 100%
}

.faq-left-image .shape-1 svg * {
    fill: #b6b7d2;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .faq-left-image .shape-1 {
        left: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .faq-left-image .shape-1 {
        left: 0;
    }
}

@media only screen and (max-width:767px) {
    .faq-left-image .shape-1 {
        left: 0;
    }
}

@media only screen and (max-width:479px) {
    .faq-left-image .shape-1 {
        left: 0;
        width: 200px;
        height: 200px;
    }
}

.mx-accordion .card {
    border: 0 none;
    margin-bottom: 30px;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.mx-accordion .card-header {
    position: relative;
    background: 0 0;
    padding: 0;
    border: 0 none;
}

.mx-accordion .card-header h5 a.acc-btn {
    color: #696969;
    font-size: 18px;
    line-height: 1.2;
    padding: 0;
    font-weight: 600;
    border: 0 none;
    font-family: Gilroy;
    -webkit-appearance: none;
    display: block;
    background: #fff;
    border: 1px solid #eee;
    padding: 20px 26px;
    margin: -1px -1px 0;
    position: relative;
    border-radius: 5px;
}

.mx-accordion .card-header h5 a.acc-btn::before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f055";
    right: 16px;
    font-size: 20px;
    color: #8c89a2;
}

.mx-accordion .card-header h5 a.acc-btn[aria-expanded=true] {
    background: #076fcc;
    color: #fff;
}

.mx-accordion .card-header h5 a.acc-btn[aria-expanded=true]::before {
    content: "\f056";
    color: #fff;
}

.mx-accordion .card-header h5 a.border-0 {
    border: 0;
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 0 15px 50px rgba(51, 51, 51, .1);
    box-shadow: 0 15px 50px rgba(51, 51, 51, .1);
}

.mx-accordion .card-body {
    padding: 27px 25px 39px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.74;
    font-size: 15px;
    font-family: Gilroy;
    border: 0 none;
    -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, .07);
    box-shadow: 0 20px 40px rgba(0, 0, 0, .07);
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    border-radius: 0 0 5px 5px;
}

.faq-info .title {
    font-size: 48px;
    line-height: 1.17em;
    color: #fff;
    margin-bottom: 15px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .faq-info .title {
        font-size: 40px;
    }
}

@media only screen and (max-width:767px) {
    .faq-info .title {
        font-size: 32px;
    }
}

@media only screen and (max-width:479px) {
    .faq-info .title {
        font-size: 26px;
    }
}

.mx-accordion-two .card {
    background-color: transparent;
    border: 0 none;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding: 10px 0;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.mx-accordion-two .card-header {
    position: relative;
    background: 0 0;
    padding: 0;
    border: 0 none;
}

.mx-accordion-two .card-header h5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 64px;
}

.mx-accordion-two .card-header h5 a.acc-btn {
    color: #fff;
    font-size: 26px;
    line-height: 1.3;
    padding: 0;
    font-weight: 600;
    border: 0 none;
    font-family: Gilroy;
    -webkit-appearance: none;
    display: block;
    background: 0 0 !important;
    border: 0;
    position: relative;
    margin-left: 40px;
}

@media only screen and (max-width:767px) {
    .mx-accordion-two .card-header h5 a.acc-btn {
        font-size: 18px;
    }
}

.mx-accordion-two .card-header h5 a.acc-btn::before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    left: -40px;
    font-size: 20px;
    color: #fff;
}

.mx-accordion-two .card-header h5 a.acc-btn[aria-expanded=true] {
    color: #076fcc;
}

.mx-accordion-two .card-header h5 a.acc-btn[aria-expanded=true]::before {
    content: "\f056";
    color: #076fcc;
}

.mx-accordion-two .card-header h5 a.border-0 {
    border: 0;
}

.mx-accordion-two .card-body {
    background-color: transparent;
    padding: 0 35px 25px 45px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.74;
    font-size: 15px;
    font-family: Gilroy;
    color: #fff;
    border: 0 none;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.success-quote-heading {
    padding: 38px 0 0 45px;
    background-image: url(../images/icons/testimonial-icon.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: auto;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .success-quote-heading {
        padding-left: 0;
    }
}

@media only screen and (max-width:767px) {
    .success-quote-heading {
        padding-left: 0;
    }
}

.success-quote-heading .title {
    font-size: 24px;
    line-height: 36px;
    max-width: 370px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .success-quote-heading .title {
        max-width: 100%
    }
}

@media only screen and (max-width:767px) {
    .success-quote-heading .title {
        max-width: 100%
    }
}

.success-quote-heading .title span {
    color: #076fcc;
}

.success-quote-content {
    padding-top: 35px;
}

.artist-quote-left {
    text-align: center;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .artist-quote-left {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width:767px) {
    .artist-quote-left {
        margin-bottom: 50px;
    }
}

.artist-quote-left .quote-image {
    margin-bottom: 25px;
}

.artist-quote-left .quote-content .title {
    font-size: 48px;
    line-height: 1.34;
    font-weight: 700;
    margin-bottom: 30px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .artist-quote-left .quote-content .title {
        font-size: 40px;
    }
}

@media only screen and (max-width:767px) {
    .artist-quote-left .quote-content .title {
        font-size: 30px;
        margin-bottom: 10px;
    }
}

.artist-quote-left .quote-content .artist-name h3 {
    font-family: Conv_Rossela-Demo-2;
    font-size: 48px;
    font-weight: 400;
    color: #6ac5f1;
}

@media only screen and (max-width:767px) {
    .artist-quote-left .quote-content .artist-name h3 {
        font-size: 32px;
    }
}

.artist-quote-right p {
    font-size: 18px;
    line-height: 1.67;
    color: #333;
    margin-bottom: 30px;
}

.artist-quote-right .btn {
    border-color: transparent;
    color: #ef6f31;
}

.artist-quote-right .btn:hover {
    color: #fff;
}

.success-faq-image img {
    border-radius: 5px;
    width: 100%
}

.single-success-faq {
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #eee;
    -webkit-transition: background .3s, border .3s, border-radius .3s, -webkit-box-shadow .3s;
    transition: background .3s, border .3s, border-radius .3s, -webkit-box-shadow .3s;
    -o-transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, -webkit-box-shadow .3s;
    padding: 47px 0 50px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .single-success-faq {
        padding: 25px 30px;
    }
}

.single-success-faq .success-faq-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .single-success-faq .success-faq-heading {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:767px) {
    .single-success-faq .success-faq-heading {
        margin-bottom: 20px;
    }
}

.single-success-faq .success-faq-heading .icon {
    margin-right: 15px;
    margin-top: 5px;
    font-size: 18px;
    color: #076fcc;
}

.single-success-faq .success-faq-heading .text-wrap h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    max-width: 310px;
}

.single-success-faq .success-faq-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.single-success-faq .success-faq-content .icon {
    margin-top: 2px;
    margin-right: 15px;
    font-size: 18px;
    color: #076fcc;
}

.single-success-faq .success-faq-content .text-wrap p {
    margin-bottom: 20px;
}

.single-success-faq .success-faq-content .text-wrap p:last-child {
    margin-bottom: 0;
}

.brand-list .brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.brand-list .brand.brand-colored a img {
    opacity: 1;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.brand-list .brand.brand-colored:hover a img {
    -webkit-filter: brightness(100%) contrast(100%) saturate(1%) blur(0) hue-rotate(0);
    filter: brightness(100%) contrast(100%) saturate(1%) blur(0) hue-rotate(0);
}

.brand-list .brand a {
    display: block;
}

.brand-list .brand a img {
    opacity: .5;
}

.brand-list .brand:hover a img {
    opacity: 1;
}

.brand-title {
    max-width: 590px;
    margin: 0 auto;
    margin-top: 45px;
    text-align: center;
}

.brand-title .title {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: 400;
    font-style: italic;
    line-height: 2;
    letter-spacing: .71px;
    margin-bottom: 0;
}

@media only screen and (max-width:767px) {
    .brand-title .title {
        font-size: 18px;
    }
}

.brand-shape-animation {
    position: relative;
    z-index: 9;
}

.brand-shape-animation .shape {
    position: absolute;
    z-index: -1;
}

.brand-shape-animation .shape-1 {
    top: 0;
    right: -20px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .brand-shape-animation .shape {
        top: 40px;
        right: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .brand-shape-animation .shape {
        top: 40px;
        right: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .brand-shape-animation .shape {
        top: 40px;
        right: 0;
    }
}

@media only screen and (max-width:767px) {
    .brand-shape-animation .shape {
        display: none;
    }
}

.newsletter10-shape-animation {
    position: relative;
    z-index: 9;
    overflow: hidden;
}

.newsletter10-shape-animation .shape {
    position: absolute;
    z-index: -1;
}

.newsletter10-shape-animation .shape-1 {
    top: 0;
    left: 0;
}

.newsletter10-shape-animation .shape-2 {
    bottom: 0;
    right: 0;
}

.newsletter-bg-image {
    padding: 55px 0 80px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .newsletter-bg-image {
        padding: 55px 15px 80px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .newsletter-bg-image {
        padding: 50px 15px 60px;
    }
}

@media only screen and (max-width:767px) {
    .newsletter-bg-image {
        padding: 50px 15px 60px;
    }
}

.newsletter-content {
    position: relative;
    z-index: 9;
    text-align: center;
}

.newsletter-content .shape {
    position: absolute;
    z-index: 1;
}

.newsletter-content .shape-1 {
    z-index: 11;
    bottom: 50px;
    left: 0;
}

.newsletter-content .shape-1 span {
    display: block;
    width: 56px;
    height: 56px;
    text-indent: -9999px;
    border: 7px solid #8fd6ca;
    border-radius: 50%
}

.newsletter-content .shape-2 {
    right: 10px;
    bottom: -45px;
}

.newsletter-content .shape-3 {
    bottom: -25px;
    right: -35px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .newsletter-content .shape-1 {
        z-index: 11;
        bottom: 50px;
        left: 0;
    }

    .newsletter-content .shape-1 span {
        width: 45px;
        height: 45px;
        border-width: 6px;
    }

    .newsletter-content .shape-2 {
        right: 10px;
        bottom: -45px;
        width: 150px;
        max-width: 150px;
    }

    .newsletter-content .shape-3 {
        bottom: -25px;
        right: -35px;
        width: 100px;
        max-width: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .newsletter-content .shape-1 {
        z-index: 11;
        bottom: 50px;
        left: 0;
    }

    .newsletter-content .shape-1 span {
        width: 45px;
        height: 45px;
        border-width: 6px;
    }

    .newsletter-content .shape-2 {
        right: 10px;
        bottom: -45px;
        width: 100px;
        max-width: 100px;
    }

    .newsletter-content .shape-3 {
        bottom: -25px;
        right: -35px;
        width: 60px;
        max-width: 60px;
    }
}

@media only screen and (max-width:767px) {
    .newsletter-content .shape-1 {
        z-index: 11;
        top: 50px;
        left: -3px;
    }

    .newsletter-content .shape-1 span {
        width: 45px;
        height: 45px;
        border-width: 6px;
    }

    .newsletter-content .shape-2 {
        right: 0;
        top: 30px;
        width: 100px;
        max-width: 100px;
    }

    .newsletter-content .shape-3 {
        top: 25px;
        right: 5px;
        width: 60px;
        max-width: 60px;
    }

    .newsletter-content .shape-4 {
        width: 85px;
    }
}

.newsletter-content.light-color .title {
    color: #fff;
}

.newsletter-content.light-color p {
    color: #fff;
}

.newsletter-content .title {
    font-size: 34px;
    margin: 0;
    color: #3f3a64;
}

.newsletter-content .title.fz-48 {
    font-size: 48px;
}

.newsletter-content .title span {
    font-weight: 400;
    color: #076fcc;
}

.newsletter-content p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 25px;
}

@media only screen and (max-width:767px) {
    .newsletter-content p {
        font-size: 16px;
        margin-top: 15px;
    }
}

.newsletter-form {
    max-width: 570px;
    margin: 0 auto;
    margin-top: 50px;
}

@media only screen and (max-width:767px) {
    .newsletter-form {
        max-width: 100%;
        margin-top: 30px;
    }
}

.newsletter-form form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width:767px) {
    .newsletter-form form {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.newsletter-form form input {
    border-width: 1px 0 1px 1px;
    border-radius: 5px 0 0 5px;
    min-height: 52px;
    padding: 3px 20px;
}

@media only screen and (max-width:767px) {
    .newsletter-form form input {
        border-width: 1px;
        border-radius: 5px;
        margin-bottom: 15px;
    }
}

.newsletter-form form button {
    border-radius: 0 5px 5px 0;
    width: 160px;
}

@media only screen and (max-width:767px) {
    .newsletter-form form button {
        border-radius: 5px;
    }
}

.newsletter-section {
    padding: 38px 0 47px;
}

.newsletter-content-wrap {
    position: relative;
    z-index: 9;
}

.newsletter-content-wrap.center-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.newsletter-content-wrap .newsletter-icon {
    position: absolute;
    z-index: -1;
    top: -22px;
    right: 25px;
}

.newsletter-content-wrap .newsletter-title {
    max-width: 270px;
}

@media only screen and (max-width:767px) {
    .newsletter-content-wrap .newsletter-title {
        margin: 0 auto;
    }
}

.newsletter-content-wrap .newsletter-title.text-dark .title {
    font-family: Gilroy;
    color: #333;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .newsletter-content-wrap .newsletter-title.text-dark .title {
        font-size: 32px;
    }
}

@media only screen and (max-width:767px) {
    .newsletter-content-wrap .newsletter-title.text-dark .title {
        text-align: center;
    }
}

.newsletter-content-wrap .newsletter-title .title {
    font-size: 34px;
    font-weight: 400;
    line-height: 1.42;
    text-align: right;
    font-family: "Playfair Display", serif;
    color: #fff;
}

@media only screen and (max-width:767px) {
    .newsletter-content-wrap .newsletter-title .title {
        font-size: 26px;
        text-align: center;
        margin-bottom: 20px;
    }
}

.newsletter-form-area {
    max-width: 670px;
    position: relative;
}

.newsletter-form-area.dark-from form input {
    color: #333;
    border-bottom-color: #bac2c6;
}

.newsletter-form-area.dark-from form input:focus {
    border-bottom-color: #ef6f31;
}

.newsletter-form-area.dark-from form button {
    color: #ef6f31;
}

.newsletter-form-area form input {
    padding: 3px 20px;
    padding-left: 0;
    padding-right: 65px;
    min-height: 50px;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid rgba(255, 255, 255, .25);
    background: 0 0;
    font-size: 24px;
    color: #fff;
}

.newsletter-form-area form input:focus {
    background-color: transparent;
    border-bottom-color: #fff;
}

.newsletter-form-area form button {
    border: 0;
    background: 0 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
    font-size: 30px;
}

.cta-content {
    position: relative;
    z-index: 9;
}

.cta-content .sub-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    font-family: Gilroy;
    display: block;
    margin-bottom: 10px;
    color: #3f3a64;
}

.cta-content .sub-title span {
    font-weight: 400;
    color: #076fcc;
}

.cta-content .title {
    font-size: 34px;
    margin-bottom: 30px;
    color: #3f3a64;
}

.cta-content .title.fz-48 {
    font-size: 48px;
}

.cta-content .title span {
    font-weight: 400;
    color: #076fcc;
}

.cta-content .shape {
    position: absolute;
    z-index: 1;
}

.cta-content .shape-1 {
    z-index: 11;
    top: -50px;
    left: 50px;
}

.cta-content .shape-1 span {
    display: block;
    width: 52px;
    height: 52px;
    text-indent: -9999px;
    border: 7px solid #e6dcd2;
    border-radius: 50%
}

.cta-content .shape-2 {
    left: -20px;
    bottom: 0;
}

.cta-content .shape-3 {
    top: 10px;
    right: -70px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .cta-content .shape-3 {
        right: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .cta-content .shape-3 {
        top: 20px;
        right: -10px;
        width: 100px;
        max-width: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cta-content .shape-3 {
        top: 20px;
        right: -10px;
        width: 80px;
        max-width: 80px;
    }
}

@media only screen and (max-width:767px) {
    .cta-content .shape-2 {
        left: -10px;
        bottom: 0;
        width: 60px;
        max-width: 60px;
        z-index: -1;
    }

    .cta-content .shape-3 {
        top: 20px;
        right: -20px;
        width: 60px;
        max-width: 60px;
    }
}

.sider-title-button-box .ht-btn {
    margin-right: 15px;
}

.ht-team-member .team-image {
    position: relative;
    display: block;
    overflow: hidden;
}

.ht-team-member .team-image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.ht-team-member .social-networks {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    padding: 10px 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    padding: 6px 0;
    background-color: #fff;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.ht-team-member .social-networks a {
    display: inline-block;
    font-size: 16px;
    line-height: 0;
    color: #696969;
    padding: 10px 14px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .ht-team-member .social-networks a {
        padding: 10px 10px;
    }
}

.ht-team-member .social-networks a:hover {
    color: #076fcc;
}

.ht-team-member:hover .team-image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.ht-team-member:hover .social-networks {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.ht-team-member .team-info {
    margin-top: 20px;
}

.ht-team-member .team-info .name {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 0;
}

.ht-team-member .team-info .position {
    font-size: 15px;
    margin-top: 8px;
}

.ht-team-member-style-one .ht-team-member {
    max-width: 310px;
    margin: auto;
}

.ht-team-member-style-two .ht-team-member {
    max-width: 350px;
    margin: auto;
}

.ht-team-member-style-three .ht-team-member {
    max-width: 350px;
    margin: auto;
}

.single-speaker {
    text-align: center;
}

.single-speaker .image {
    margin-bottom: 28px;
    text-align: center;
}

.single-speaker .image img {
    width: 170px;
    height: 170px;
    border-radius: 50%
}

.single-speaker .content .speaker-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    color: #333;
    margin-bottom: 6px;
}

.team-image-wrap {
    position: relative;
    z-index: 9;
}

.team-image-wrap .team-image {
    text-align: center;
}

.team-image-wrap .shape {
    position: absolute;
    z-index: -1;
}

.team-image-wrap .shape-1 {
    top: 100px;
    left: 0;
    width: 166px;
    height: 166px;
}

.team-image-wrap .shape-1 svg {
    width: 100%;
    height: 100%
}

.team-image-wrap .shape-1 svg * {
    fill: #8fd6ca;
}

.team-image-wrap .shape-2 {
    top: 100px;
    left: 0;
}

.team-image-wrap .shape-3 {
    z-index: 11;
    top: 0;
    right: 0;
}

.team-image-wrap .shape-3 span {
    display: block;
    width: 60px;
    height: 60px;
    text-indent: -9999px;
    border: 8px solid #ecc5ab;
    border-radius: 50%
}

.team-image-wrap .shape-4 {
    left: 50%;
    bottom: -70px;
}

.team-image-wrap .shape-5 {
    left: -214px;
    bottom: 60px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .team-image-wrap .shape {
        position: absolute;
        z-index: -1;
    }

    .team-image-wrap .shape-1 {
        top: 100px;
        left: 0;
        width: 120px;
        height: 120px;
    }

    .team-image-wrap .shape-1 svg {
        width: 100%;
        height: 100%
    }

    .team-image-wrap .shape-1 svg * {
        fill: #8fd6ca;
    }

    .team-image-wrap .shape-2 {
        top: 100px;
        left: 0;
        width: 120px;
    }

    .team-image-wrap .shape-3 {
        z-index: 11;
        top: 0;
        right: 0;
    }

    .team-image-wrap .shape-3 span {
        display: block;
        width: 60px;
        height: 60px;
        text-indent: -9999px;
        border: 8px solid #ecc5ab;
        border-radius: 50%
    }

    .team-image-wrap .shape-4 {
        left: 50%;
        bottom: -20px;
        width: 120px;
    }

    .team-image-wrap .shape-5 {
        left: 0;
        bottom: 60px;
        width: 120px;
    }
}

@media only screen and (max-width:767px) {
    .team-image-wrap .shape {
        position: absolute;
        z-index: -1;
    }

    .team-image-wrap .shape-1 {
        top: 100px;
        left: 0;
        width: 120px;
        height: 120px;
    }

    .team-image-wrap .shape-1 svg {
        width: 100%;
        height: 100%
    }

    .team-image-wrap .shape-1 svg * {
        fill: #8fd6ca;
    }

    .team-image-wrap .shape-2 {
        top: 100px;
        left: 0;
        width: 120px;
    }

    .team-image-wrap .shape-3 {
        z-index: 11;
        top: 0;
        right: 0;
    }

    .team-image-wrap .shape-3 span {
        display: block;
        width: 60px;
        height: 60px;
        text-indent: -9999px;
        border: 8px solid #ecc5ab;
        border-radius: 50%
    }

    .team-image-wrap .shape-4 {
        left: 50%;
        bottom: -20px;
        width: 120px;
    }

    .team-image-wrap .shape-5 {
        left: 0;
        bottom: 60px;
        width: 120px;
    }
}

[class*=hint--] {
    position: relative;
    display: inline-block;
}

[class*=hint--]:after,
[class*=hint--]:before {
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}

[class*=hint--]:hover:after,
[class*=hint--]:hover:before {
    visibility: visible;
    opacity: 1;
}

[class*=hint--]:hover:after,
[class*=hint--]:hover:before {
    -webkit-transition-delay: .1s;
    -moz-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
}

[class*=hint--]:before {
    content: '';
    position: absolute;
    background: 0 0;
    border: 7px solid transparent;
    z-index: 1000001;
}

[class*=hint--]:after {
    background: #076fcc;
    color: #fff;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    border-radius: 5px;
    -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, .3);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, .3);
}

[class*=hint--][aria-label]:after {
    content: attr(aria-label);
}

[class*=hint--][data-hint]:after {
    content: attr(data-hint);
}

[aria-label='']:after,
[aria-label='']:before,
[data-hint='']:after,
[data-hint='']:before {
    display: none !important;
}

.hint--top-left:before {
    border-top-color: #076fcc;
}

.hint--top-right:before {
    border-top-color: #076fcc;
}

.hint--top:before {
    border-top-color: #076fcc;
}

.hint--bottom-left:before {
    border-bottom-color: #076fcc;
}

.hint--bottom-right:before {
    border-bottom-color: #076fcc;
}

.hint--bottom:before {
    border-bottom-color: #076fcc;
}

.hint--left:before {
    border-left-color: #076fcc;
}

.hint--right:before {
    border-right-color: #076fcc;
}

.hint--white:after {
    background: #fff;
    color: #333;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    border-radius: 5px;
}

.hint--white:before {
    border-top-color: #fff;
}

.hint--black:after {
    background: #000;
    color: #fff;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    border-radius: 5px;
}

.hint--black:before {
    border-top-color: #000;
}

.hint--black.hint--bottom::before {
    border-bottom-color: #000;
}

.hint--black.hint--top-left::before {
    border-top-color: #000;
}

.hint--black.hint--bottom-left::before {
    border-bottom-color: #000;
}

.hint--black.hint--top::before {
    border-top-color: #000;
}

.hint--black.hint--left::before {
    border-left-color: #000;
}

.hint--black:before {
    border-top-color: transparent;
}

.hint--top:before {
    margin-bottom: -13px;
}

.hint--top:after,
.hint--top:before {
    bottom: 100%;
    left: 50%
}

.hint--top:before {
    left: calc(50% - 7px);
}

.hint--top:after {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.hint--top:hover:before {
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
}

.hint--top:hover:after {
    -webkit-transform: translateX(-50%) translateY(-8px);
    -moz-transform: translateX(-50%) translateY(-8px);
    -ms-transform: translateX(-50%) translateY(-8px);
    transform: translateX(-50%) translateY(-8px);
}

.hint--bottom:before {
    margin-top: -13px;
}

.hint--bottom:after,
.hint--bottom:before {
    top: 100%;
    left: 50%
}

.hint--bottom:before {
    left: calc(50% - 7px);
}

.hint--bottom:after {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.hint--bottom:hover:before {
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
}

.hint--bottom:hover:after {
    -webkit-transform: translateX(-50%) translateY(8px);
    -moz-transform: translateX(-50%) translateY(8px);
    -ms-transform: translateX(-50%) translateY(8px);
    transform: translateX(-50%) translateY(8px);
}

.hint--right:before {
    margin-left: -13px;
    margin-bottom: -7px;
}

.hint--right:after {
    margin-bottom: -15px;
}

.hint--right:after,
.hint--right:before {
    left: 100%;
    bottom: 50%
}

.hint--right:hover:before {
    -webkit-transform: translateX(8px);
    -moz-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
}

.hint--right:hover:after {
    -webkit-transform: translateX(8px);
    -moz-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
}

.hint--left:before {
    margin-right: -13px;
    margin-bottom: -7px;
}

.hint--left:after {
    margin-bottom: -15px;
}

.hint--left:after,
.hint--left:before {
    right: 100%;
    bottom: 50%
}

.hint--left:hover:before {
    -webkit-transform: translateX(-8px);
    -moz-transform: translateX(-8px);
    -ms-transform: translateX(-8px);
    transform: translateX(-8px);
}

.hint--left:hover:after {
    -webkit-transform: translateX(-8px);
    -moz-transform: translateX(-8px);
    -ms-transform: translateX(-8px);
    transform: translateX(-8px);
}

.hint--top-left:before {
    margin-bottom: -13px;
}

.hint--top-left:after,
.hint--top-left:before {
    bottom: 100%;
    left: 50%
}

.hint--top-left:before {
    left: calc(50% - 7px);
}

.hint--top-left:after {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.hint--top-left:after {
    margin-left: 14px;
}

.hint--top-left:hover:before {
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
}

.hint--top-left:hover:after {
    -webkit-transform: translateX(-100%) translateY(-8px);
    -moz-transform: translateX(-100%) translateY(-8px);
    -ms-transform: translateX(-100%) translateY(-8px);
    transform: translateX(-100%) translateY(-8px);
}

.hint--top-right:before {
    margin-bottom: -13px;
}

.hint--top-right:after,
.hint--top-right:before {
    bottom: 100%;
    left: 50%
}

.hint--top-right:before {
    left: calc(50% - 7px);
}

.hint--top-right:after {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.hint--top-right:after {
    margin-left: -14px;
}

.hint--top-right:hover:before {
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
}

.hint--top-right:hover:after {
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
}

.hint--bottom-left:before {
    margin-top: -13px;
}

.hint--bottom-left:after,
.hint--bottom-left:before {
    top: 100%;
    left: 50%
}

.hint--bottom-left:before {
    left: calc(50% - 7px);
}

.hint--bottom-left:after {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.hint--bottom-left:after {
    margin-left: 14px;
}

.hint--bottom-left:hover:before {
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
}

.hint--bottom-left:hover:after {
    -webkit-transform: translateX(-100%) translateY(8px);
    -moz-transform: translateX(-100%) translateY(8px);
    -ms-transform: translateX(-100%) translateY(8px);
    transform: translateX(-100%) translateY(8px);
}

.hint--bottom-right:before {
    margin-top: -13px;
}

.hint--bottom-right:after,
.hint--bottom-right:before {
    top: 100%;
    left: 50%
}

.hint--bottom-right:before {
    left: calc(50% - 7px);
}

.hint--bottom-right:after {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.hint--bottom-right:after {
    margin-left: -14px;
}

.hint--bottom-right:hover:before {
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
}

.hint--bottom-right:hover:after {
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
}

.hint--no-animate:after,
.hint--no-animate:before {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}

.hint--bounce:after,
.hint--bounce:before {
    -webkit-transition: opacity .3s ease, visibility .3s ease, -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    -moz-transition: opacity .3s ease, visibility .3s ease, -moz-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    -o-transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
    transition: opacity .3s ease, visibility .3s ease, transform .3s cubic-bezier(.71, 1.7, .77, 1.24), -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
}

.gradation-title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width:767px) {
    .gradation-title-wrapper {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.gradation-sub-heading .heading {
    position: relative;
    font-size: 13px;
    line-height: 2em;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    padding: 35px 80px 35px 42px;
    z-index: 1;
}

.gradation-sub-heading .heading::before {
    background-color: #076fcc;
    content: '';
    position: absolute;
    top: 47px;
    left: 0;
    width: 34px;
    height: 1px;
}

.gradation-sub-heading .heading mark {
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-size: 120px;
    line-height: .8;
    font-weight: 900;
    color: #ededed;
    z-index: -1;
}

@media only screen and (max-width:767px) {
    .gradation-sub-heading .heading {
        margin-top: 30px;
    }
}

.ht-gradation.style-01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
}

.ht-gradation.style-01 .item {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    padding: 0 15px;
}

.ht-gradation.style-01 .item .line {
    width: 100%;
    height: 1px;
    background: #ededed;
    position: absolute;
    top: 24px;
    left: 15px;
}

.ht-gradation.style-01 .item:last-child .line {
    display: none;
}

.ht-gradation.style-01 .item:last-child .content-wrap {
    padding-bottom: 0;
}

.ht-gradation.style-01 .item .mask {
    opacity: 0;
    visibility: hidden;
}

.ht-gradation.style-01 .item:hover .mask {
    opacity: 1;
    visibility: visible;
}

.ht-gradation.style-01 .item:hover .circle {
    color: #fff;
    background: #076fcc;
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .ht-gradation.style-01 {
        display: block;
    }

    .ht-gradation.style-01 .item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 100%
    }

    .ht-gradation.style-01 .item .line {
        left: 39px;
        height: 100%;
        width: 1px;
        top: 15px;
    }

    .ht-gradation.style-01 .content-wrap {
        padding: 10px 0 50px 30px;
    }
}

.ht-gradation.style-01 .circle-wrap {
    display: inline-block;
    position: relative;
    margin-bottom: 56px;
}

.ht-gradation.style-01 .circle {
    position: relative;
    z-index: 1;
    width: 48px;
    height: 48px;
    line-height: 44px;
    text-align: center;
    border: 2px solid #e8e8e8;
    border-radius: 50%;
    font-size: 18px;
    background-color: #fff;
    margin-bottom: 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.ht-gradation.style-01 .wave-pulse {
    width: 1px;
    height: 0;
    margin: 0 auto;
}

.ht-gradation.style-01 .wave-pulse::after,
.ht-gradation.style-01 .wave-pulse::before {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #ababab;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: gradationMask 3s linear infinite;
    animation: gradationMask 3s linear infinite;
}

.ht-gradation.style-01 .wave-pulse-2::before {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.ht-gradation.style-01 .wave-pulse-3::before {
    -webkit-animation-delay: 2.4s;
    animation-delay: 2.4s;
}

.ht-gradation.style-01 .heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    color: #3f3a64;
    margin-bottom: 9px;
}

.ht-gradation.style-01 .text {
    margin-bottom: 0;
}

@-webkit-keyframes gradationMask {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
        border-color: transparent;
    }
}

@keyframes gradationMask {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
        border-color: transparent;
    }
}

.call-out-content {
    padding: 100px 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .call-out-content {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .call-out-content {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.call-out-content .sub-title {
    display: block;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    margin-bottom: 25px;
}

.call-out-content .sub-title mark {
    color: #076fcc;
    font-weight: 700;
    background-color: transparent;
}

.call-out-content .title {
    font-size: 68px;
    line-height: 1.27;
    color: #fff;
    margin-bottom: 35px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .call-out-content .title {
        font-size: 60px;
    }
}

@media only screen and (max-width:767px) {
    .call-out-content .title {
        font-size: 40px;
    }
}

@media only screen and (max-width:479px) {
    .call-out-content .title {
        font-size: 32px;
    }
}

.call-out-image {
    text-align: center;
    padding-top: 30px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .call-out-image {
        padding-top: 0;
    }
}

@media only screen and (max-width:767px) {
    .call-out-image {
        padding-top: 0;
    }
}

.coaching-box.padding-top-50 {
    padding-top: 50px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .coaching-box.padding-top-50 {
        padding-top: 0;
    }
}

@media only screen and (max-width:767px) {
    .coaching-box.padding-top-50 {
        padding-top: 0;
    }
}

.coaching-box.cooking-box .image {
    margin-bottom: 20px;
}

.coaching-box.cooking-box .content .title {
    font-family: "Playfair Display", serif;
    font-weight: 400;
}

.coaching-box .image {
    margin-bottom: 12px;
}

.coaching-box .content .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
}

.ebook-section {
    position: relative;
    z-index: 9;
}

.ebook-section .shape {
    position: absolute;
    z-index: -1;
}

.ebook-section .shape-1 {
    top: 83px;
    left: 5vw;
    width: 20vw;
    max-width: 20vw;
}

.ebook-section .shape-2 {
    bottom: 55px;
    right: 5vw;
    width: 20vw;
    max-width: 20vw;
}



.instagram-grid-wrap .item-grid.grid-style--1 {
    position: relative;
    overflow: hidden;
}

.instagram-grid-wrap .item-grid.grid-style--1 .thumb a {
    display: block;
}

.instagram-grid-wrap .item-grid.grid-style--1 .thumb a img {
    border-radius: inherit;
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.instagram-grid-wrap .item-grid.grid-style--1 .item-info {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    cursor: pointer;
    border-radius: inherit;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.instagram-grid-wrap .item-grid.grid-style--1 .item-info .inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    color: #fff;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.instagram-grid-wrap .item-grid.grid-style--1 .item-info .inner a {
    position: relative;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    margin: 0 5px;
}

.instagram-grid-wrap .item-grid.grid-style--1 .item-info .inner a i {
    padding-right: 4px;
}

.instagram-grid-wrap .item-grid.grid-style--1:hover .thumb a img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.instagram-grid-wrap .item-grid.grid-style--1:hover .item-info {
    visibility: visible;
    opacity: 1;
}

.max-popup-section {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    background-color: rgba(63, 58, 100, .9);
}

.max-popup-section:not(.close) .max-popup-dialog {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}

.max-popup-section.close {
    visibility: hidden;
    opacity: 0;
}

.max-popup-section.close .max-popup-dialog {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}

.max-popup-dialog {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    width: 970px;
    max-width: 90vw;
    max-height: 100vh;
    border-radius: 8px;
    background-color: #fff;
}

.max-popup-close {
    line-height: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    color: #ccc;
    border: none;
    background-color: transparent;
}

.max-popup-close i {
    font-size: 32px;
    line-height: 1;
}

.max-popup-close:hover {
    color: #076fcc;
}

@media only screen and (max-width:479px) {
    .max-popup-close {
        top: 10px;
        right: 10px;
    }
}

.max-popup-dialog-inner {
    padding: 50px;
}

@media only screen and (max-width:767px) {
    .max-popup-dialog-inner {
        padding: 40px 30px;
    }
}

@media only screen and (max-width:479px) {
    .max-popup-dialog-inner {
        padding: 40px 20px;
    }
}

.freecourse-popup-content {
    text-align: center;
}

.freecourse-popup-content .sub-title {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #076fcc;
}

.freecourse-popup-content .title {
    font-size: 44px;
    line-height: 1.4;
    margin-bottom: 20px;
    color: #3f3a64;
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .freecourse-popup-content .sub-title {
        font-size: 16px;
        letter-spacing: 2px;
    }

    .freecourse-popup-content .title {
        font-size: 30px;
    }
}

@media only screen and (max-width:479px) {
    .freecourse-popup-content .sub-title {
        font-size: 14px;
        letter-spacing: 1px;
    }

    .freecourse-popup-content .title {
        font-size: 24px;
    }
}

.quick-view-modal-container {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
}

.quick-view-modal-container.show {
    visibility: visible;
    opacity: 1;
    z-index: 99999;
}

.quick-view-modal-container .modal-dialog {
    max-width: 80%
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .quick-view-modal-container .modal-dialog {
        max-width: 95%
    }
}

@media only screen and (max-width:767px) {
    .quick-view-modal-container .modal-dialog {
        max-width: 100%
    }
}

@media only screen and (max-width:575px) {
    .quick-view-modal-container .modal-dialog {
        max-width: 100%
    }
}

.quick-view-modal-container .modal-header {
    border: none;
    padding-bottom: 0;
}

.quick-view-modal-container .modal-header button {
    z-index: 999;
}

.quick-view-modal-container .modal-body {
    padding: 30px 50px 70px 50px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .quick-view-modal-container .modal-body {
        padding: 30px 30px 30px 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .quick-view-modal-container .modal-body {
        padding: 30px 30px 30px 30px;
    }
}

@media only screen and (max-width:767px) {
    .quick-view-modal-container .modal-body {
        padding: 15px 15px 15px 15px;
    }
}

@media only screen and (max-width:479px) {
    .quick-view-modal-container .modal-body {
        padding: 15px 15px 15px 15px;
    }
}

.quick-view-modal-container .modal-content {
    border-radius: 0;
    max-height: 850px;
    overflow: auto;
    z-index: 9999;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .quick-view-modal-container .modal-content {
        max-height: 500px;
        overflow: auto;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .quick-view-modal-container .modal-content {
        max-height: 500px;
        overflow: auto;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .quick-view-modal-container .modal-content {
        max-height: 500px;
        overflow: auto;
    }
}

@media only screen and (max-width:767px) {
    .quick-view-modal-container .modal-content {
        max-height: 500px;
        overflow: auto;
    }
}

.modal-backdrop {
    z-index: 9999;
}

.page-title-section {
    position: relative;
    padding-top: 50px;
    /* padding-bottom: 80px; */
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .page-title-section {
        padding-top: 80px;
        /* padding-bottom: 60px; */
    }
}

@media only screen and (max-width:767px) {
    .page-title-section {
        padding-top: 60px;
        /* padding-bottom: 40px; */
    }
}

.page-title .title {
    font-size: 37px;
    margin: 0;
    text-align: center;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .page-title .title {
        font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .page-title .title {
        font-size: 36px;
    }
}

@media only screen and (max-width:767px) {
    .page-title .title {
        font-size: 30px;
    }
}

.page-breadcrumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

.section-title {
    position: relative;
    z-index: 99;
    margin-bottom: 60px;
}

@media only screen and (max-width:767px) {
    .section-title {
        margin-bottom: 30px;
    }
}

.section-title .sub-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    display: block;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.section-title .title {
    font-size: 34px;
    margin: 0;
    color: #3f3a64;
}

.section-title .title.fz-48 {
    font-size: 48px !important;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title .title.fz-48 {
        font-size: 42px !important;
    }
}

@media only screen and (max-width:767px) {
    .section-title .title.fz-48 {
        font-size: 36px !important;
    }
}

@media only screen and (max-width:479px) {
    .section-title .title.fz-48 {
        font-size: 26px !important;
    }
}

.section-title .title.fz-40 {
    font-size: 40px !important;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title .title.fz-40 {
        font-size: 34px !important;
    }
}

@media only screen and (max-width:767px) {
    .section-title .title.fz-40 {
        font-size: 30px !important;
    }
}

@media only screen and (max-width:479px) {
    .section-title .title.fz-40 {
        font-size: 26px !important;
    }
}

.section-title .title.playfair-font {
    font-size: 34px;
    font-weight: 400;
    line-height: 1.42;
    font-family: "Playfair Display", serif;
}

.section-title .title.playfair-font span {
    color: #3a7e69;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title .title.playfair-font {
        font-size: 30px;
    }
}

@media only screen and (max-width:767px) {
    .section-title .title.playfair-font {
        font-size: 26px;
    }

    .section-title .title.playfair-font br {
        display: none;
    }
}

.section-title .title span {
    font-weight: 400;
    color: #076fcc;
}

.section-title h3.title {
    font-size: 24px;
}

.section-title.color-light .sub-title {
    color: #696969;
}

.section-title.color-light .title {
    color: #fff;
}

@media only screen and (max-width:767px) {
    .section-title .title {
        font-size: 26px;
    }
}

.section-title-two {
    position: relative;
    z-index: 99;
    max-width: 970px;
    margin: 0 auto;
    margin-bottom: 60px;
}

@media only screen and (max-width:767px) {
    .section-title-two {
        margin-bottom: 30px;
    }
}

.section-title-two .sub-title {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.34;
    color: #076fcc;
    margin-bottom: 30px;
}

.section-title-two .title {
    font-size: 34px;
    line-height: 1.42;
    margin: 0;
    color: #333;
}

.section-title-two .title.fz-48 {
    font-size: 48px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title-two .title.fz-48 {
        font-size: 42px;
    }
}

@media only screen and (max-width:767px) {
    .section-title-two .title.fz-48 {
        font-size: 36px;
    }
}

@media only screen and (max-width:575px) {
    .section-title-two .title.fz-48 {
        font-size: 26px;
    }
}

@media only screen and (max-width:479px) {
    .section-title-two .title.fz-48 {
        font-size: 26px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-title-two .sub-title {
        font-size: 21px;
        margin-bottom: 20px;
    }

    .section-title-two .title {
        font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title-two .sub-title {
        font-size: 21px;
        margin-bottom: 20px;
    }

    .section-title-two .title {
        font-size: 40px;
    }
}

@media only screen and (max-width:767px) {
    .section-title-two .sub-title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .section-title-two .title {
        font-size: 32px;
    }
}

@media only screen and (max-width:575px) {
    .section-title-two .sub-title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .section-title-two .title {
        font-size: 26px;
    }
}

@media only screen and (max-width:479px) {
    .section-title-two .sub-title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .section-title-two .title {
        font-size: 26px;
    }
}

.section-title-three {
    position: relative;
    z-index: 99;
    max-width: 580px;
    margin: 0 auto;
    margin-bottom: 60px;
}

@media only screen and (max-width:767px) {
    .section-title-three {
        margin-bottom: 30px;
    }
}

.section-title-three .sub-title {
    font-family: Conv_Rossela-Demo-2;
    font-size: 48px;
    font-weight: 400;
    color: #6ac5f1;
}

.section-title-three .title {
    font-size: 34px;
    line-height: 1.42;
    margin: 0;
    color: #333;
}

.section-title-three .title.fz-48 {
    font-size: 48px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title-three .title.fz-48 {
        font-size: 42px;
    }
}

@media only screen and (max-width:767px) {
    .section-title-three .title.fz-48 {
        font-size: 36px;
    }
}

@media only screen and (max-width:575px) {
    .section-title-three .title.fz-48 {
        font-size: 26px;
    }
}

@media only screen and (max-width:479px) {
    .section-title-three .title.fz-48 {
        font-size: 26px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-title-three .sub-title {
        font-size: 40px;
    }

    .section-title-three .title {
        font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title-three .sub-title {
        font-size: 40px;
    }

    .section-title-three .title {
        font-size: 30px;
    }
}

@media only screen and (max-width:767px) {
    .section-title-three .sub-title {
        font-size: 32px;
    }

    .section-title-three .title {
        font-size: 26px;
    }
}

.section-title-four {
    max-width: 520px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
}

.section-title-four .sub-title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.34;
    color: #6ac5f1;
    margin-bottom: 20px;
}

.section-title-four .title {
    font-size: 40px;
    line-height: 1.2;
    margin: 0;
}

.section-title-four p {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.67;
    color: #333;
    margin-top: 15px;
    margin-bottom: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .section-title-four .sub-title {
        font-size: 21px;
    }

    .section-title-four .title {
        font-size: 34px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .section-title-four .sub-title {
        font-size: 21px;
    }

    .section-title-four .title {
        font-size: 34px;
    }
}

@media only screen and (max-width:767px) {
    .section-title-four {
        margin-bottom: 30px;
    }

    .section-title-four .sub-title {
        font-size: 18px;
    }

    .section-title-four .title {
        font-size: 28px;
    }
}

.section-title-five {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 60px;
}

.section-title-five .title {
    font-size: 34px;
    line-height: 1.42;
    font-weight: 700;
    margin: 0;
}

@media only screen and (max-width:767px) {
    .section-title-five {
        margin-bottom: 30px;
    }

    .section-title-five .title {
        font-size: 28px;
    }
}

.instagram-section-title .title {
    font-family: "Playfair Display", serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 1.42;
    color: #3f3a64;
    margin: 0;
}

.instagram-section-title .title a {
    display: block;
}

.instagram-section-title .title a:hover {
    color: #3a7e69;
}

.instagram-section-title .sub-title {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.74;
    color: #3a7e69;
    margin-top: 7px;
}

.instagram-section-title-two {
    margin-bottom: 60px;
}

@media only screen and (max-width:767px) {
    .instagram-section-title-two {
        margin-bottom: 30px;
    }
}

.instagram-section-title-two .title {
    font-size: 40px;
    line-height: 1.2;
    margin: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .instagram-section-title-two .title {
        font-size: 34px;
    }
}

@media only screen and (max-width:767px) {
    .instagram-section-title-two .title {
        font-size: 30px;
    }
}

@media only screen and (max-width:479px) {
    .instagram-section-title-two .title {
        font-size: 26px;
    }
}

.instagram-section-title-two .sub-title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.34;
    color: #6ac5f1;
    margin-top: 20px;
}

@media only screen and (max-width:767px) {
    .instagram-section-title-two .sub-title {
        margin-top: 10px;
    }
}

.instagram-section-title-two .sub-title a:hover {
    color: #ef6f31;
}

.secondary-color {
    color: #3f3a64 !important;
}

.heading-color {
    color: #333 !important;
}

[data-aos=fade-up] {
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
}

.selectric {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    min-height: 52px;
    padding: 3px 40px 3px 20px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    color: #696969;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    outline: 0;
    background-color: #f5f5f5;
}

.selectric .label {
    font-size: inherit;
    line-height: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    margin: 0;
    color: #696969;
}

.selectric .label i {
    margin-right: 15px;
}

.selectric .label span {
    font-weight: 500;
    margin-left: 3px;
    color: #333;
}

.selectric .arrow {
    font-size: 18px;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 52px;
    color: #333;
    background-color: transparent;
}

.selectric .arrow::after {
    display: none;
}

.selectric.selectric-open,
.selectric:hover {
    border-color: #076fcc;
    background-color: #fff;
}

.selectric.selectric-open .arrow,
.selectric:hover .arrow {
    color: #333;
}

.selectric-open .selectric {
    border-color: #076fcc;
    background-color: #fff;
}

.selectric-open .selectric .arrow {
    color: #333;
}

.selectric-items {
    font-weight: 500;
    min-width: 100%;
    padding: 5px 0;
    color: #333;
    border: 0;
    border-radius: 5px;
    background: #f8f9fd;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
}

.selectric-items .selectric-scroll ul li {
    font-size: 15px;
    line-height: 26px;
    position: relative;
    margin: 0;
    padding: 5px 30px;
    cursor: pointer;
    color: #333;
}

.selectric-items .selectric-scroll ul li.highlighted {
    color: #333;
    background-color: transparent;
}

.selectric-items .selectric-scroll ul li.selected::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    margin-right: 10px;
    content: "\f00c";
    color: #076fcc;
}

.selectric-items .selectric-scroll ul li.selected:hover::before {
    color: #fff;
}

.selectric-items .selectric-scroll ul li:hover {
    color: #fff;
    background-color: #076fcc;
}

.selectric-sort-by {
    width: 270px;
}

.irs--maxCoach {
    margin: 7px 0 40px;
}

.irs--maxCoach .irs {
    position: relative;
    width: 100%
}

.irs--maxCoach .irs .irs-line {
    width: 100%;
    height: 5px;
    background-color: #eee;
}

.irs--maxCoach .irs .irs-from,
.irs--maxCoach .irs .irs-to {
    display: none !important;
}

.irs--maxCoach .irs .irs-single {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    position: absolute;
    top: calc(100% + 25px);
    left: 0 !important;
    visibility: visible !important;
    color: #8c89a2;
}

.irs--maxCoach .irs .irs-single::before {
    content: "Price: "
}

.irs--maxCoach .irs-bar {
    top: 0;
    height: 5px;
    background-color: #076fcc;
}

.irs--maxCoach .irs-handle {
    top: 50%;
    width: 18px;
    height: 18px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-indent: -9999px;
    border-radius: 50%;
    background-color: #076fcc;
}

.header-section {
    z-index: 9999;
    min-height: 80px;
}

.header-section .header-inner {
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 80px;
    background-color: #fff;
}

.header-section.is-sticky.header-sticky-bg-dark .header-inner {
    background-color: #000 !important;
}

.header-section.is-sticky .header-inner {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff !important;
    width: 100%;
    -webkit-animation: headerSlideDown .95s ease forwards;
    animation: headerSlideDown .95s ease forwards;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .1);
}

.header-section.header-fluid .container {
    max-width: 100%
}

@media only screen and (min-width:1600px) {
    .header-section.header-fluid .container {
        padding-right: 150px;
        padding-left: 150px;
    }
}

.header-section.header-fluid-two .container {
    max-width: 100%
}

@media only screen and (min-width:1600px) {
    .header-section.header-fluid-two .container {
        padding-right: 240px;
        padding-left: 240px;
    }
}

.header-section.header-shadow .header-inner {
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .05);
}

.header-section.header-transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

.header-section.header-transparent .header-inner {
    background-color: transparent;
}

.header-section.header-light .header-login a {
    color: #fff;
}

.header-section.header-light .header-login a:hover {
    color: #fff;
}

@-webkit-keyframes headerSlideDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes headerSlideDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.header-logo a {
    position: relative;
    max-width: 158px;
}

.header-logo a img {
    width: 100%;
    padding: 15px 0;
}

.header-logo a img.light-logo {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

@media only screen and (max-width:479px) {
    .header-logo a {
        max-width: 120px;
    }
}

.header-light .header-logo a img.light-logo {
    opacity: 1;
}

.header-light .header-logo a img.dark-logo {
    opacity: 0;
}

.header-right>.inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 -10px;
}

@media only screen and (max-width:479px) {
    .header-right>.inner {
        margin: 0 -8px;
    }
}

.header-cart {
    position: relative;
    padding: 0 10px;
}

.header-cart:hover .header-mini-cart {
    visibility: visible;
    margin-top: 0;
    opacity: 1;
}

@media only screen and (max-width:479px) {
    .header-cart {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.header-cart-btn {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: #111;
}

.header-cart-btn .cart-count {
    font-size: 10px;
    line-height: 16px;
    position: absolute;
    top: -4px;
    right: 0;
    width: 15px;
    height: 15px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    background-color: #076fcc;
}

.header-cart-btn i {
    font-size: 18px;
    line-height: 1;
}

.header-cart-btn:hover {
    color: #076fcc;
}

.header-mini-cart {
    position: absolute;
    z-index: 99;
    top: 100%;
    right: 0;
    visibility: hidden;
    overflow: auto;
    width: 360px;
    max-height: 80vh;
    margin-top: 20px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    opacity: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, .12);
    box-shadow: 0 10px 30px rgba(0, 0, 0, .12);
}

.header-mini-cart>.inner {
    padding: 30px 20px;
}

@media only screen and (max-width:767px) {
    .header-mini-cart {
        display: none;
    }
}

.mini-cart-product {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.mini-cart-product:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f3f3f3;
}

.mini-cart-product .thumb {
    width: 80px;
}

.mini-cart-product .content {
    max-width: calc(100% - 80px);
    padding-right: 25px;
    padding-left: 20px;
}

.mini-cart-product .content .title {
    font-weight: 500;
}

.mini-cart-product .content .quantity {
    font-weight: 500;
    display: block;
}

.mini-cart-product .content .quantity .price {
    color: #8c89a2;
}

.mini-cart-product .remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    text-align: center;
}

.mini-cart-product .remove i {
    line-height: 20px;
    display: block;
}

.mini-cart-footer {
    margin-top: 30px;
    padding-top: 16px;
    border-top: 2px solid #f3f3f3;
}

.mini-cart-total {
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.mini-cart-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 6px;
    margin-right: -10px;
}

.mini-cart-buttons .btn {
    line-height: 46px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    height: 48px;
    margin-top: 10px;
    margin-right: 10px;
    padding-right: 20px;
    padding-left: 20px;
}

.header-light .header-cart-btn {
    color: #fff;
}

.header-light .header-cart-btn .cart-count {
    color: #3f3a64;
    background-color: #fff;
}

.header-light .header-cart-btn:hover {
    color: #fff;
}

.header-login a {
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 10px;
    color: #111;
}

.header-login a i {
    font-size: 18px;
}

.header-login a:hover {
    color: #076fcc;
}

@media only screen and (max-width:479px) {
    .header-login a {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.header-top-section {
    padding: 10px 0;
}

.header-top-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width:767px) {
    .header-top-content {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.header-top-content p {
    margin-right: 30px;
    margin-bottom: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .header-top-content p {
        margin-right: 18px;
    }
}

@media only screen and (max-width:767px) {
    .header-top-content p {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width:479px) {
    .header-top-content p {
        text-align: center;
    }
}

.header-top-content .header-top-countdown .countdown-item:first-child::before {
    display: none;
}

.header-top-countdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 45px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .header-top-countdown {
        margin-right: 20px;
    }
}

@media only screen and (max-width:479px) {
    .header-top-countdown {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.header-top-countdown .ht-icon {
    margin-right: 5px;
}

.header-top-countdown .ht-icon i {
    font-size: 18px;
    color: #3f3a64;
}

.header-top-countdown .countdown-item {
    position: relative;
    padding: 0 5px;
    margin: 0 5px;
}

.header-top-countdown .countdown-item.before-none::before {
    display: none;
}

.header-top-countdown .countdown-item::before {
    position: absolute;
    top: 50%;
    left: -8px;
    content: ':';
    font-size: 18px;
    font-weight: 700;
    color: #3f3a64;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-top-countdown .countdown-item span {
    font-size: 18px;
    color: #3f3a64;
    font-weight: 700;
    line-height: 1;
}

.header-language {
    position: relative;
    display: block;
    padding: 10px;
}

.header-language>a {
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
}

.header-language>a img {
    width: 30px;
}

.header-language>a .text {
    line-height: 1;
    display: block;
    padding-top: 3px;
    padding-right: 7px;
    padding-left: 10px;
}

.header-language>a i {
    font-size: 14px;
}

.header-language:hover>a {
    color: #076fcc;
}

.header-language:hover .language-list {
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.header-language .language-list {
    position: absolute;
    z-index: 102;
    top: 100%;
    right: 0;
    left: 0;
    visibility: hidden;
    min-width: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 0 37px rgba(0, 0, 0, .07);
    box-shadow: 0 0 37px rgba(0, 0, 0, .07);
}

.header-language .language-list li a {
    font-size: 14px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    text-transform: uppercase;
    color: #333;
}

.header-language .language-list li a img {
    width: 25px;
}

.header-language .language-list li a .text {
    line-height: 1;
    display: block;
    padding-top: 3px;
    padding-right: 7px;
    padding-left: 10px;
}

.header-language .language-list li a:hover {
    color: #333;
    background-color: #f8f9fa;
}

.header-light .header-language>a {
    color: #fff;
}

.header-light .header-language:hover>a {
    color: #fff;
}

.header-social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.header-social a {
    padding: 0 10px;
}

.header-social a i {
    font-size: 18px;
    line-height: 30px;
}

@media only screen and (max-width:767px) {
    .header-social a {
        padding: 0 6px;
    }

    .header-social a i {
        font-size: 16px;
    }
}

.header-light .header-social a {
    color: #fff;
}

.header-orange .header-social a:hover {
    color: #ef6f31;
}

.header-search {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 250px;
    padding: 0 10px;
}

.header-search.open .header-search-toggle i::before {
    content: "\f00d"
}

@media only screen and (max-width:767px) {
    .header-search {
        padding: 0;
    }
}

.header-search-toggle {
    line-height: 1;
    display: none;
    padding: 6px 10px;
    color: #111;
    border: none;
    background-color: transparent;
}

.header-search-toggle i {
    font-size: 18px;
    width: 18px;
}

.header-search-toggle:hover {
    color: #076fcc;
}

@media only screen and (max-width:767px) {
    .header-search-toggle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media only screen and (max-width:479px) {
    .header-search-toggle {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.header-search-form form {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}

.header-search-form form input {
    min-height: 48px;
    padding-right: 50px;
}

.header-search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: #076fcc;
    border: none;
    background-color: transparent;
}

.header-search-form form button:hover {
    color: #fff;
    background-color: #076fcc;
}

.header-search-form.light form input {
    background-color: #fff;
}

@media only screen and (max-width:767px) {
    .header-search-form {
        position: fixed;
        top: 80px;
        left: 0;
        display: none;
        width: 100%;
        padding: 15px;
        border-top: 1px solid #f3f3f3;
        background-color: #fff;
        z-index: 99;
    }
}

.header-search-form-2 {
    margin-right: -10px;
    border-left: 1px solid #eee;
}

.header-search-form-2 form {
    position: relative;
    overflow: hidden;
    border-radius: 0;
}

.header-search-form-2 form input {
    min-height: 80px;
    padding-right: 50px;
    border: none !important;
    border-radius: 0;
    background-color: #fff;
}

.header-search-form-2 form button {
    position: absolute;
    top: 50%;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #111;
    border: none;
    background-color: transparent;
}

.header-search-form-2 form button:hover {
    color: #111;
    background-color: transparent;
}

@media only screen and (min-width:678px) {
    .header-search-form-2 {
        display: block !important;
    }
}

@media only screen and (max-width:767px) {
    .header-search-form-2 {
        position: fixed;
        top: 80px;
        left: 0;
        display: none;
        width: 100%;
        padding: 15px;
        border-top: 1px solid #f3f3f3;
        border-left: none;
        background-color: #fff;
    }

    .header-search-form-2 form input {
        min-height: 48px;
    }
}

.header-fs-search-toggle {
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 10px;
    color: #111;
    border: none;
    background-color: transparent;
}

.header-fs-search-toggle i {
    font-size: 18px;
}

.header-fs-search-toggle:hover {
    color: #076fcc;
}

@media only screen and (max-width:479px) {
    .header-fs-search-toggle {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.fullscreen-search {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    opacity: 0;
    background-color: #fff;
}

.fullscreen-search.open {
    visibility: visible;
    opacity: 1;
}

.fullscreen-search.open .fullscreen-search-form {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.fullscreen-search-close {
    position: absolute;
    top: 40px;
    right: 55px;
}

.fullscreen-search-form {
    width: 1170px;
    max-width: 90%;
    -webkit-transition: all .5s ease .2s;
    -o-transition: all .5s ease .2s;
    transition: all .5s ease .2s;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
}

.fullscreen-search-form form input {
    font-size: 50px;
    font-weight: 300;
    height: 100px;
    padding: 15px 0;
    color: #333;
    border: 0 solid transparent;
    border-bottom: 3px solid #076fcc;
    border-radius: 0;
    background-color: transparent;
}

@media only screen and (min-width:992px) and (max-width:1199px),
only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .fullscreen-search-form form input {
        font-size: 40px;
    }
}

.header-light .header-search-toggle {
    color: #fff;
}

.header-light .header-search-toggle:hover {
    color: #fff;
}

.header-light .header-fs-search-toggle {
    color: #fff;
}

.header-light .header-fs-search-toggle:hover {
    color: #fff;
}

.site-main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.site-main-menu>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.site-main-menu>ul>li {
    position: relative;
}

.site-main-menu>ul>li>a {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.375;
    display: block;
    padding: 29px 17px;
    color: #3f3a64;
}

.site-main-menu>ul>li>a .menu-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.site-main-menu>ul>li .menu-toggle {
    display: none;
}

.site-main-menu>ul>li.has-children>a .menu-text::after {
    font-family: "Font Awesome 5 Pro";
    font-size: 8px;
    font-weight: 900;
    margin-left: 10px;
}

.site-main-menu>ul>li:hover>a {
    color: #076fcc;
}

.site-main-menu>ul>li:hover .mega-menu,
.site-main-menu>ul>li:hover>.sub-menu {
    visibility: visible;
    margin-top: 0;
    opacity: 1;
}

.site-main-menu .mega-menu,
.site-main-menu .sub-menu {
    position: absolute;
    z-index: 9999;
    top: 100%;
    left: 0;
    visibility: hidden;
    width: 240px;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 18px 0;
    list-style: none;
    -webkit-transition: all .4s ease .2s;
    -o-transition: all .4s ease .2s;
    transition: all .4s ease .2s;
    opacity: 0;
    border-bottom: 4px solid #076fcc;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 29px rgba(0, 0, 0, .05);
    box-shadow: 0 2px 29px rgba(0, 0, 0, .05);
}

.site-main-menu .mega-menu li,
.site-main-menu .sub-menu li {
    position: relative;
}

.site-main-menu .mega-menu li a,
.site-main-menu .sub-menu li a {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375;
    display: block;
    padding: 8px 30px;
    white-space: nowrap;
    color: #696969;
}

.site-main-menu .mega-menu li a .menu-text,
.site-main-menu .sub-menu li a .menu-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.site-main-menu .mega-menu li .menu-toggle,
.site-main-menu .sub-menu li .menu-toggle {
    display: none;
}

.site-main-menu .mega-menu li.has-children>a .menu-text::after,
.site-main-menu .sub-menu li.has-children>a .menu-text::after {
    font-family: "Font Awesome 5 Pro";
    font-size: 8px;
    font-weight: 900;
    margin-left: auto;
    content: "\f054"
}

.site-main-menu .mega-menu li.active>a,
.site-main-menu .mega-menu li:hover>a,
.site-main-menu .sub-menu li.active>a,
.site-main-menu .sub-menu li:hover>a {
    color: #111;
}

.site-main-menu .mega-menu li:hover>.sub-menu,
.site-main-menu .sub-menu li:hover>.sub-menu {
    visibility: visible;
    margin-top: 0;
    opacity: 1;
}

.site-main-menu .mega-menu .sub-menu,
.site-main-menu .sub-menu .sub-menu {
    top: -18px;
    left: 100%
}

.site-main-menu .mega-menu {
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 15px 34px;
    list-style: none;
}

.site-main-menu .mega-menu>li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    padding: 0 15px;
}

.site-main-menu .mega-menu>li.menu-item-50 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    padding: 0 15px;
}

.site-main-menu .mega-menu>li>ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.site-main-menu .mega-menu>li>ul>li>a {
    font-size: 15px;
    line-height: 1.78;
    font-weight: 500;
    padding: 8px 0;
}

.site-main-menu .mega-menu>li>ul>li>a .badge {
    background-color: transparent;
    background-image: -webkit-linear-gradient(45deg, #fe378c 0, #fe5b34 100%);
    background-image: -o-linear-gradient(45deg, #fe378c 0, #fe5b34 100%);
    background-image: linear-gradient(45deg, #fe378c 0, #fe5b34 100%);
    display: inline-block;
    border-radius: 2px;
    padding: 4px 8px 3px;
    color: #fff;
    font-size: 11px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin: 0 0 0 7px;
}

.site-main-menu .mega-menu>li>ul>li>a .badge.primary {
    background-image: none;
    background-color: #076fcc !important;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .site-main-menu.laptop-space>ul>li>a {
        padding-right: 9px;
        padding-left: 9px;
    }
}

.site-main-menu.menu-hover-1>ul>li>a .menu-text {
    position: relative;
}

.site-main-menu.menu-hover-1>ul>li>a .menu-text::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    content: "";
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    background-color: #076fcc;
}

.site-main-menu.menu-hover-1>ul>li:hover>a .menu-text::before {
    left: 0;
    width: 100%
}

.header-light .site-main-menu>ul>li>a {
    color: #fff;
}

.header-light .site-main-menu>ul>li:hover>a {
    color: #fff;
}

.header-light .site-main-menu.menu-hover-1>ul>li>a .menu-text::before {
    background-color: #fff;
}

.header-orange .site-main-menu>ul>li>a {
    color: #3f3a64;
}

.header-orange .site-main-menu>ul>li:hover>a {
    color: #ef6f31;
}

.header-orange .site-main-menu .sub-menu {
    border-bottom: 4px solid #ef6f31;
}

.header-orange .site-main-menu .sub-menu li.active>a,
.header-orange .site-main-menu .sub-menu li:hover>a {
    color: #ef6f31;
}

.header-orange .site-main-menu .mega-menu {
    border-bottom: 4px solid #ef6f31;
}

.header-orange .site-main-menu .mega-menu li.active>a,
.header-orange .site-main-menu .mega-menu li:hover>a {
    color: #ef6f31;
}

.header-orange .site-main-menu.menu-hover-1>ul>li>a .menu-text::before {
    background-color: #ef6f31;
}

.header-fluid .site-main-menu .mega-menu,
.header-fluid-two .site-main-menu .mega-menu {
    width: 1170px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
}

.header-left .site-main-menu .mega-menu {
    left: 150px;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    text-align: center;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .header-left .site-main-menu .mega-menu {
        left: 20px;
    }
}

.fullscreen-search-close,
.header-mobile-menu-toggle,
.mobile-menu-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 10px;
}

.fullscreen-search-close .toggle,
.header-mobile-menu-toggle .toggle,
.mobile-menu-close .toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    border: none;
    background-color: transparent;
}

.fullscreen-search-close .toggle i,
.header-mobile-menu-toggle .toggle i,
.mobile-menu-close .toggle i {
    position: relative;
    display: block;
    overflow: hidden;
    width: 24px;
    height: 2px;
}

.fullscreen-search-close .toggle i+i,
.header-mobile-menu-toggle .toggle i+i,
.mobile-menu-close .toggle i+i {
    margin-top: 6px;
}

.fullscreen-search-close .toggle i::after,
.fullscreen-search-close .toggle i::before,
.header-mobile-menu-toggle .toggle i::after,
.header-mobile-menu-toggle .toggle i::before,
.mobile-menu-close .toggle i::after,
.mobile-menu-close .toggle i::before {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #333;
}

.fullscreen-search-close .toggle i.icon-bottom::before,
.fullscreen-search-close .toggle i.icon-top::before,
.header-mobile-menu-toggle .toggle i.icon-bottom::before,
.header-mobile-menu-toggle .toggle i.icon-top::before,
.mobile-menu-close .toggle i.icon-bottom::before,
.mobile-menu-close .toggle i.icon-top::before {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
}

.fullscreen-search-close .toggle i.icon-bottom::after,
.fullscreen-search-close .toggle i.icon-top::after,
.header-mobile-menu-toggle .toggle i.icon-bottom::after,
.header-mobile-menu-toggle .toggle i.icon-top::after,
.mobile-menu-close .toggle i.icon-bottom::after,
.mobile-menu-close .toggle i.icon-top::after {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1), -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -webkit-transform: scaleX(0) translateZ(0);
    transform: scaleX(0) translateZ(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}

.fullscreen-search-close .toggle i.icon-middle::before,
.header-mobile-menu-toggle .toggle i.icon-middle::before,
.mobile-menu-close .toggle i.icon-middle::before {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}

.fullscreen-search-close .toggle i.icon-middle::after,
.header-mobile-menu-toggle .toggle i.icon-middle::after,
.mobile-menu-close .toggle i.icon-middle::after {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1), -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -webkit-transform: scaleX(0) translateZ(0);
    transform: scaleX(0) translateZ(0);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
}

.fullscreen-search-close .toggle:hover i::after,
.fullscreen-search-close .toggle:hover i::before,
.header-mobile-menu-toggle .toggle:hover i::after,
.header-mobile-menu-toggle .toggle:hover i::before,
.mobile-menu-close .toggle:hover i::after,
.mobile-menu-close .toggle:hover i::before {
    background-color: #076fcc;
}

.fullscreen-search-close .toggle:hover i.icon-bottom::before,
.fullscreen-search-close .toggle:hover i.icon-top::before,
.header-mobile-menu-toggle .toggle:hover i.icon-bottom::before,
.header-mobile-menu-toggle .toggle:hover i.icon-top::before,
.mobile-menu-close .toggle:hover i.icon-bottom::before,
.mobile-menu-close .toggle:hover i.icon-top::before {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1), -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -webkit-transform: scaleX(0) translateZ(0);
    transform: scaleX(0) translateZ(0);
}

.fullscreen-search-close .toggle:hover i.icon-bottom::after,
.fullscreen-search-close .toggle:hover i.icon-top::after,
.header-mobile-menu-toggle .toggle:hover i.icon-bottom::after,
.header-mobile-menu-toggle .toggle:hover i.icon-top::after,
.mobile-menu-close .toggle:hover i.icon-bottom::after,
.mobile-menu-close .toggle:hover i.icon-top::after {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
}

.fullscreen-search-close .toggle:hover i.icon-middle::before,
.header-mobile-menu-toggle .toggle:hover i.icon-middle::before,
.mobile-menu-close .toggle:hover i.icon-middle::before {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    transition: transform .6s cubic-bezier(.165, .84, .44, 1), -webkit-transform .6s cubic-bezier(.165, .84, .44, 1);
    -webkit-transform: scaleX(0) translateZ(0);
    transform: scaleX(0) translateZ(0);
}

.fullscreen-search-close .toggle:hover i.icon-middle::after,
.header-mobile-menu-toggle .toggle:hover i.icon-middle::after,
.mobile-menu-close .toggle:hover i.icon-middle::after {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -o-transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
}

@media only screen and (max-width:479px) {

    .fullscreen-search-close,
    .header-mobile-menu-toggle,
    .mobile-menu-close {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.header-light .fullscreen-search-close .toggle i::after,
.header-light .fullscreen-search-close .toggle i::before,
.header-light .header-mobile-menu-toggle .toggle i::after,
.header-light .header-mobile-menu-toggle .toggle i::before,
.header-light .mobile-menu-close .toggle i::after,
.header-light .mobile-menu-close .toggle i::before {
    background-color: #fff;
}

.header-light .fullscreen-search-close .toggle:hover i::after,
.header-light .fullscreen-search-close .toggle:hover i::before,
.header-light .header-mobile-menu-toggle .toggle:hover i::after,
.header-light .header-mobile-menu-toggle .toggle:hover i::before,
.header-light .mobile-menu-close .toggle:hover i::after,
.header-light .mobile-menu-close .toggle:hover i::before {
    background-color: #fff;
}


.site-main-mobile-menu {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    /* Align the menu to the right edge */
    width: 0;
    /* Initial width */
    height: 100%;
    transition: width 0.25s cubic-bezier(.645, .045, .355, 1);
    background: rgba(0, 0, 0, .7);
    /* Ensure the content is hidden by default */
    overflow: hidden;
}
.site-main-mobile-menu-inner {
    position: relative;
    z-index: 9;
    float: right;
    width: 360px;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    /* -webkit-transform: translateX(100%); */
    /* -ms-transform: translateX(100%); */
    /* transform: translateX(100%); */
    background-color: #3f3a64;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    background-size: cover;
}

@media only screen and (max-width:479px) {
    .site-main-mobile-menu-inner {
        width: 300px;
    }
}

.site-main-mobile-menu-inner::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: rgba(63, 58, 100, .9);
}

#page {
    position: relative;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.mobile-menu-open {
    overflow: hidden;
}

.mobile-menu-open #page {
    -webkit-transform: translateX(-360px);
    -ms-transform: translateX(-360px);
    transform: translateX(-360px);
}

.mobile-menu-open .site-main-mobile-menu {
    visibility: visible;
    opacity: 1;
}

.mobile-menu-open .site-main-mobile-menu-inner {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.mobile-menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 80px;
    padding: 0 15px 0 30px;
    background-color: #fff;
}

.mobile-menu-logo a {
    width: 158px;
}

.fullscreen-search-close,
.mobile-menu-close {
    padding: 0;
}

.fullscreen-search-close .toggle,
.mobile-menu-close .toggle {
    position: relative;
    width: 40px;
    height: 40px;
}

.fullscreen-search-close .toggle i,
.mobile-menu-close .toggle i {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 29px;
    height: 2px;
    margin: 0 !important;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

.fullscreen-search-close .toggle i.icon-top,
.mobile-menu-close .toggle i.icon-top {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}

.fullscreen-search-close .toggle i.icon-bottom,
.mobile-menu-close .toggle i.icon-bottom {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.fullscreen-search-close .toggle:hover i::after,
.fullscreen-search-close .toggle:hover i::before,
.mobile-menu-close .toggle:hover i::after,
.mobile-menu-close .toggle:hover i::before {
    background-color: #111;
}

.mobile-menu-content {
    overflow-y: auto;
    height: calc(100% - 80px);
    padding: 20px 30px 100px;
}

.kobo-site-mobile-menu>ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.kobo-site-mobile-menu>ul>li {
    position: relative !important;
}

.kobo-site-mobile-menu>ul>li+li>a {
    border-top: 1px solid rgba(255, 255, 255, .15);
}

.kobo-site-mobile-menu>ul>li>a {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    display: block;
    padding-top: 19px;
    padding-right: 0;
    padding-bottom: 19px;
    padding-left: 0;
    color: #fff;
    border-bottom: 1px solid transparent;
}

.kobo-site-mobile-menu>ul>li .menu-toggle {
    position: absolute;
    top: 11px;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    background-color: transparent;
}

.kobo-site-mobile-menu>ul>li .menu-toggle i {
    font-size: 18px;
    line-height: 1;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.kobo-site-mobile-menu>ul>li .menu-toggle:hover {
    background-color: rgba(255, 255, 255, .2);
}

.kobo-site-mobile-menu>ul>li.open>a {
    border-bottom-color: rgba(255, 255, 255, .15);
}

.kobo-site-mobile-menu>ul>li.open>.menu-toggle {
    background-color: rgba(255, 255, 255, .2);
}

.kobo-site-mobile-menu>ul>li.open>.menu-toggle i {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.kobo-site-mobile-menu .mega-menu,
.kobo-site-mobile-menu .sub-menu {
    display: none;
    margin: 14px 0;
    padding-left: 0;
    list-style: none;
}

.kobo-site-mobile-menu .mega-menu li,
.kobo-site-mobile-menu .sub-menu li {
    position: relative;
}

.kobo-site-mobile-menu .mega-menu li>a,
.kobo-site-mobile-menu .sub-menu li>a {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    padding-top: 10px;
    padding-right: 0;
    padding-bottom: 10px;
    padding-left: 0;
    color: rgba(255, 255, 255, .7);
}

.kobo-site-mobile-menu .mega-menu li>a:hover,
.kobo-site-mobile-menu .sub-menu li>a:hover {
    color: #fff;
}

.kobo-site-mobile-menu .mega-menu li .menu-toggle,
.kobo-site-mobile-menu .sub-menu li .menu-toggle {
    top: 1px;
}

.kobo-site-mobile-menu .mega-menu li.open>a,
.kobo-site-mobile-menu .sub-menu li.open>a {
    color: #fff;
}

.kobo-site-mobile-menu .mega-menu li.open>.menu-toggle,
.kobo-site-mobile-menu .sub-menu li.open>.menu-toggle {
    background-color: rgba(255, 255, 255, .2);
}

.kobo-site-mobile-menu .mega-menu li.open>.menu-toggle i,
.kobo-site-mobile-menu .sub-menu li.open>.menu-toggle i {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.kobo-site-mobile-menu .mega-menu ul,
.kobo-site-mobile-menu .sub-menu ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.kobo-site-mobile-menu .mega-menu ul li a .badge,
.kobo-site-mobile-menu .sub-menu ul li a .badge {
    background-color: transparent;
    background-image: -webkit-linear-gradient(45deg, #fe378c 0, #fe5b34 100%);
    background-image: -o-linear-gradient(45deg, #fe378c 0, #fe5b34 100%);
    background-image: linear-gradient(45deg, #fe378c 0, #fe5b34 100%);
    display: inline-block;
    border-radius: 2px;
    padding: 4px 8px 3px;
    color: #fff;
    font-size: 11px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin: 0 0 0 7px;
}

.kobo-site-mobile-menu .mega-menu ul li a .badge.primary,
.kobo-site-mobile-menu .sub-menu ul li a .badge.primary {
    background-image: none;
    background-color: #076fcc !important;
}

.kobo-site-mobile-menu .mega-menu .sub-menu,
.kobo-site-mobile-menu .sub-menu .sub-menu {
    padding-left: 15px;
}

.intro1-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 820px;
    padding: 50px 0;
}

@media only screen and (max-width:767px) {
    .intro1-section {
        background-image: none !important;
        background-color: #155740;
    }
}

.intro1-section .container {
    z-index: 9;
}

.intro1-section .section-bottom-shape {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

@media only screen and (min-width:992px) and (max-width:1199px),
only screen and (min-width:768px) and (max-width:991px) {
    .intro1-section {
        height: 750px;
    }
}

@media only screen and (max-width:767px) {
    .intro1-section {
        height: auto;
        background-position: left top;
    }
}

.intro1-content .sub-title {
    font-weight: 700;
    line-height: 2;
    display: block;
    margin-bottom: 18px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #3f3a64;
}

.intro1-content .title {
    font-size: 63px;
    line-height: 1.13;
    color: #3f3a64;
}

.intro1-content .desc {
    max-width: 540px;
    margin-top: 12px;
}

.intro1-content .desc p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
}

.intro1-content .btn {
    margin-top: 28px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro1-content .title {
        font-size: 54px;
    }

    .intro1-content .desc p {
        font-size: 17px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .intro1-content .sub-title {
        font-size: 13px;
        letter-spacing: 3px;
    }

    .intro1-content .title {
        font-size: 40px;
    }

    .intro1-content .desc p {
        font-size: 16px;
    }
}

@media only screen and (max-width:479px) {
    .intro1-content .sub-title {
        font-size: 12px;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }

    .intro1-content .title {
        font-size: 30px;
    }

    .intro1-content .desc p {
        font-size: 14px;
    }
}

.intro1-course {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.intro1-course .popular-course-icon {
    z-index: 9;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 20px;
    margin-bottom: 100px;
    margin-left: auto;
}

.intro1-course .course,
.intro1-course .course-2 {
    min-width: 320px;
    max-width: 370px;
}

@media only screen and (max-width:479px) {

    .intro1-course .course,
    .intro1-course .course-2 {
        min-width: 100%;
        max-width: 100%
    }
}

.intro1-course .intro1-scene {
    position: absolute;
    z-index: -1;
    right: -45px;
    bottom: -45px;
    width: 136px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro1-course .popular-course-icon {
        width: 100px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro1-course .popular-course-icon {
        width: 90px;
        margin-right: -30px;
        margin-left: -60px;
    }
}

@media only screen and (max-width:767px) {
    .intro1-course .popular-course-icon {
        width: 120px;
    }
}

@media only screen and (max-width:575px) {
    .intro1-course .popular-course-icon {
        width: 100px;
        margin-right: -20px;
    }
}

@media only screen and (max-width:479px) {
    .intro1-course .popular-course-icon {
        position: absolute;
        top: -30px;
        left: 0;
        width: 100px;
    }
}

.intro2-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 65px;
}

.intro2-section .container {
    z-index: 9;
}

.intro2-section .section-bottom-shape {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

@media only screen and (max-width:767px) {
    .intro2-section {
        height: auto;
        background-position: left top;
    }
}

.intro2-content {
    max-width: 460px;
}

@media only screen and (max-width:767px) {
    .intro2-content {
        max-width: 100%
    }
}

.intro2-content .title {
    font-size: 48px;
    line-height: 1.17;
    color: #3f3a64;
}

.intro2-content .desc {
    max-width: 540px;
    margin-top: 12px;
}

.intro2-content .desc p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    color: #8c89a2;
}

.intro2-content .btn {
    margin-top: 35px;
}

.intro2-content .link {
    display: block;
    font-size: 15px;
    margin-top: 25px;
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .intro2-content .title {
        font-size: 36px;
    }

    .intro2-content .desc p {
        font-size: 16px;
    }
}

@media only screen and (max-width:479px) {
    .intro2-content .title {
        font-size: 30px;
    }

    .intro2-content .desc p {
        font-size: 14px;
    }
}

.intro3-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 240px 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro3-section {
        padding: 170px 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro3-section {
        padding: 150px 0;
    }
}

@media only screen and (max-width:767px) {
    .intro3-section {
        padding: 120px 0;
    }
}

.intro3-section .container {
    z-index: 9;
}

.intro3-content .sub-title {
    font-size: 34px;
    line-height: 1;
    font-weight: 700;
    color: #fff;
    display: block;
    font-family: Gilroy;
    margin-bottom: 10px;
}

.intro3-content .title {
    font-size: 64px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
}

.intro3-content .desc {
    max-width: 540px;
    margin-top: 12px;
}

.intro3-content .desc p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
}

.intro3-content .btn {
    margin-top: 28px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro3-content .title {
        font-size: 54px;
    }

    .intro3-content .desc p {
        font-size: 17px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .intro3-content .sub-title {
        font-size: 26px;
        letter-spacing: 3px;
    }

    .intro3-content .title {
        font-size: 46px;
    }

    .intro3-content .desc p {
        font-size: 16px;
    }
}

@media only screen and (max-width:479px) {
    .intro3-content .sub-title {
        font-size: 23px;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }

    .intro3-content .title {
        font-size: 34px;
    }

    .intro3-content .desc p {
        font-size: 14px;
    }
}

.intro4-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 270px 0 248px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro4-section {
        padding: 170px 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro4-section {
        padding: 170px 0;
    }
}

@media only screen and (max-width:767px) {
    .intro4-section {
        padding: 100px 0 130px;
    }
}

.intro4-section .container {
    z-index: 9;
}

.ah-headline.rotate-2 i {
    font-style: normal !important;
}

.intro4-content .title {
    font-size: 56px;
    line-height: 1.29;
    font-weight: 500;
    color: #fff;
}

.intro4-content .title span {
    color: #076fcc;
}

.intro4-content .title span b {
    font-weight: 500;
}

.intro4-content .title span b i em {
    font: inherit;
}

.intro4-content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.78;
    color: #fff;
    margin-bottom: 45px;
}

.intro4-content .btn {
    margin: 0 10px;
}

@media only screen and (max-width:575px) {
    .intro4-content .btn {
        margin: 0 5px;
    }
}

@media only screen and (max-width:479px) {
    .intro4-content .btn {
        margin: 10px 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro4-content .title {
        font-size: 54px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .intro4-content .title {
        font-size: 46px;
    }
}

@media only screen and (max-width:479px) {
    .intro4-content .title {
        font-size: 46px;
    }

    .intro4-content p {
        margin-bottom: 20px;
    }
}

.intro5-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.intro5-section .container {
    z-index: 9;
}

.intro5-section .section-bottom-shape {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

@media only screen and (max-width:767px) {
    .intro5-section {
        height: auto;
        background-position: left top;
    }
}

.intro5-content {
    max-width: 460px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro5-content {
        padding: 140px 15px 40px;
    }
}

@media only screen and (max-width:767px) {
    .intro5-content {
        padding: 140px 15px 40px;
        max-width: 100%
    }
}

.intro5-content .title {
    font-size: 48px;
    line-height: 1.17;
    color: #3f3a64;
}

.intro5-content .desc {
    max-width: 540px;
    margin-top: 12px;
}

.intro5-content .desc p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    color: #8c89a2;
}

.intro5-content .btn {
    margin-top: 35px;
}

.intro5-content .link {
    display: block;
    font-size: 15px;
    margin-top: 25px;
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .intro5-content .title {
        font-size: 36px;
    }

    .intro5-content .desc p {
        font-size: 16px;
    }
}

@media only screen and (max-width:479px) {
    .intro5-content .title {
        font-size: 30px;
    }

    .intro5-content .desc p {
        font-size: 14px;
    }
}

.intro5-image {
    padding: 137px 15px 55px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro5-image {
        padding: 0 15px 50px;
    }
}

@media only screen and (max-width:767px) {
    .intro5-image {
        padding: 0 15px 50px;
    }
}

.intro6-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 176px 0 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro6-section {
        padding: 176px 0 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro6-section {
        padding: 176px 0 0;
    }
}

@media only screen and (max-width:767px) {
    .intro6-section {
        padding: 120px 0 0;
    }
}

.intro6-section .container {
    z-index: 9;
}

.intro6-content {
    margin-bottom: 80px;
}

@media only screen and (max-width:767px) {
    .intro6-content {
        margin-bottom: 40px;
    }
}

.intro6-content .title {
    font-size: 48px;
    line-height: 1.17;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
}

.intro6-content p {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, .7);
    margin: 0 0 24px;
}

.intro6-content .btn:hover {
    color: #076fcc;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro6-content .title {
        font-size: 48px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .intro6-content .title {
        font-size: 42px;
    }
}

@media only screen and (max-width:479px) {
    .intro6-content .title {
        font-size: 34px;
    }
}

.intro-left-image {
    position: relative;
    left: -168px;
    bottom: 25px;
    width: 410px;
    height: 504px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro-left-image {
        left: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro-left-image {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro-left-image {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%
    }
}

@media only screen and (max-width:767px) {
    .intro-left-image {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%
    }
}

.intro-left-image img {
    width: 100%
}

.intro-right-image {
    position: relative;
    right: 20px;
    bottom: -80px;
    width: 230px;
    height: 353px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro-right-image {
        right: 50px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro-right-image {
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro-right-image {
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%
    }
}

@media only screen and (max-width:767px) {
    .intro-right-image {
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%
    }
}

.intro-right-image img {
    width: 100%
}

.intro7-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 820px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro7-section {
        height: 700px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro7-section {
        height: 620px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro7-section {
        height: 620px;
    }
}

@media only screen and (max-width:767px) {
    .intro7-section {
        height: 500px;
    }
}

.intro7-content {
    max-width: 770px;
    padding: 30px 0;
    margin: auto;
}

.intro7-content>* {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

.intro7-content .sub-title {
    font-size: 15px;
    font-weight: 600;
    line-height: 2;
    display: block;
    margin-bottom: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.intro7-content .title {
    font-size: 64px;
    line-height: 1.17;
    color: #3f3a64;
    font-family: "Playfair Display", serif;
}

.intro7-content .title span {
    color: #3a7e69;
    font-style: italic;
    font-weight: 400;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro7-content .title {
        font-size: 52px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro7-content .title {
        font-size: 52px;
    }
}

@media only screen and (max-width:767px) {
    .intro7-content .title {
        font-size: 40px;
    }
}

.intro7-content .btn {
    margin-top: 35px;
}

.swiper-slide-active .intro7-content>* {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.swiper-slide-active .intro7-content>:nth-child(1) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.swiper-slide-active .intro7-content>:nth-child(2) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.swiper-slide-active .intro7-content>:nth-child(3) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.swiper-slide-active .intro7-content>:nth-child(4) {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.swiper-slide-active .intro7-content>:nth-child(5) {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.swiper-slide-active .intro7-content>:nth-child(6) {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.intro7-section-wrap .intro7-section {
    -webkit-transform: scale(1.35);
    -ms-transform: scale(1.35);
    transform: scale(1.35);
    transform: scale(1.35);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-slide-active .intro7-section {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    -o-transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1), -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
}

.intro8-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro8-section {
        height: 100vh;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro8-section {
        height: 780px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro8-section {
        height: 780px;
    }
}

@media only screen and (max-width:767px) {
    .intro8-section {
        height: 700px;
    }
}

.intro8-content {
    max-width: 670px;
    padding: 60px 0 30px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro8-content {
        max-width: 470px;
        padding: 60px 0 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro8-content {
        max-width: 470px;
        padding: 60px 0 30px;
    }
}

@media only screen and (max-width:767px) {
    .intro8-content {
        max-width: 470px;
        padding: 60px 0 30px;
    }
}

.intro8-content>* {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

.intro8-content .label {
    display: inline-block;
    padding: 6px 15px 3px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: .65px;
    color: #201c3f;
    background-color: #eab75f;
    margin: 0 0 28px;
}

.intro8-content .title {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.27;
    color: #fff;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro8-content .title {
        font-size: 48px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro8-content .title {
        font-size: 48px;
    }
}

@media only screen and (max-width:767px) {
    .intro8-content .title {
        font-size: 36px;
    }
}

.intro8-content p {
    font-size: 18px;
    line-height: 1.67;
    color: rgba(255, 255, 255, .7);
    margin: 18px 0 0;
}

@media only screen and (max-width:767px) {
    .intro8-content p {
        font-size: 15px;
    }
}

.intro8-content .btn {
    margin-top: 40px;
}

.intro8-slide-pages {
    position: absolute;
    top: 30%;
    right: 10px;
    font-size: 24px;
    color: rgba(255, 255, 255, .7);
    font-weight: 600;
    letter-spacing: 3.2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width:767px) {
    .intro8-slide-pages {
        display: none;
    }
}

.intro8-slide-pages .current {
    font-size: 60px;
    line-height: 1;
    color: #fff;
}

.swiper-slide-active .intro8-content>* {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.swiper-slide-active .intro8-content>:nth-child(1) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.swiper-slide-active .intro8-content>:nth-child(2) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.swiper-slide-active .intro8-content>:nth-child(3) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.swiper-slide-active .intro8-content>:nth-child(4) {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.swiper-slide-active .intro8-content>:nth-child(5) {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.swiper-slide-active .intro8-content>:nth-child(6) {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.intro8-section-wrap .intro8-section {
    -webkit-transform: scale(1.35);
    -ms-transform: scale(1.35);
    transform: scale(1.35);
    transform: scale(1.35);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-slide-active .intro8-section {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    -o-transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1), -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
}

.intro9-section {
    position: relative;
    overflow: hidden;
}

.intro9-content {
    max-width: 680px;
    width: 100%;
    float: right;
    padding-left: 110px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro9-content {
        padding-left: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro9-content {
        padding-left: 0;
    }
}

@media only screen and (max-width:767px) {
    .intro9-content {
        padding-left: 0;
        float: inherit;
        margin: 60px auto 45px;
        text-align: center;
    }
}

@media only screen and (max-width:479px) {
    .intro9-content {
        padding-left: 0;
        float: inherit;
        margin: 60px auto 0;
        text-align: center;
    }
}

.intro9-content .sub-title {
    display: block;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #076fcc;
    margin-bottom: 20px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro9-content .sub-title {
        font-size: 22px;
    }
}

@media only screen and (max-width:479px) {
    .intro9-content .sub-title {
        font-size: 20px;
    }
}

.intro9-content .title {
    font-size: 48px;
    line-height: 56px;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #3f3a64;
    max-width: 470px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro9-content .title {
        font-size: 38px;
    }
}

@media only screen and (max-width:767px) {
    .intro9-content .title {
        font-size: 38px;
        margin: 0 auto;
    }
}

@media only screen and (max-width:479px) {
    .intro9-content .title {
        font-size: 26px;
        line-height: 40px;
        margin: 0 auto;
    }
}

.intro9-content .desc {
    max-width: 400px;
    margin-top: 12px;
}

@media only screen and (max-width:767px) {
    .intro9-content .desc {
        margin: 0 auto;
        margin-top: 12px;
    }
}

.intro9-content .desc p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    color: #8c89a2;
}

.intro9-content .btn {
    margin-top: 35px;
}

.intro9-image {
    position: relative;
    margin-top: 40px;
}

.inner-img-one {
    position: absolute;
    z-index: -1;
    bottom: 0;
}

@media only screen and (max-width:767px) {
    .inner-img-one {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
    }

    .inner-img-one img {
        width: 400px;
    }
}

@media only screen and (max-width:767px) {
    .inner-img-two {
        margin: auto !important;
        text-align: center;
    }

    .inner-img-two img {
        max-width: 254px;
    }
}

.worldRotate {
    -webkit-animation: worldRotate 20s linear infinite;
    animation: worldRotate 20s linear infinite;
}

@-webkit-keyframes worldRotate {
    0% {
        -webkit-transform: rotate(0) translateX(0) rotate(0);
        transform: rotate(0) translateX(0) rotate(0);
    }

    100% {
        -webkit-transform: rotate(0) translateY(0) rotate(-360deg);
        transform: rotate(0) translateY(0) rotate(-360deg);
    }
}

@keyframes worldRotate {
    0% {
        -webkit-transform: rotate(0) translateX(0) rotate(0);
        transform: rotate(0) translateX(0) rotate(0);
    }

    100% {
        -webkit-transform: rotate(0) translateY(0) rotate(-360deg);
        transform: rotate(0) translateY(0) rotate(-360deg);
    }
}

.intro10-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 750px;
    padding: 120px 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro10-section {
        height: 700px;
        padding: 120px 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro10-section {
        height: 600px;
        padding: 120px 0;
    }
}

@media only screen and (max-width:767px) {
    .intro10-section {
        height: 500px;
        padding: 120px 0;
    }
}

.intro10-section .container {
    z-index: 9;
}

.intro10-section .shape {
    position: absolute;
    z-index: 1;
}

.intro10-section .shape-1 {
    top: 0;
    right: -8%;
    width: 50%;
    max-width: 50%
}

.intro10-section .shape-2 {
    top: 0;
    left: 0;
    width: 50%;
    max-width: 50%
}

.intro10-section .shape-3 {
    z-index: -1;
    bottom: 30px;
    left: -60px;
    width: 478px;
    height: 478px;
}

.intro10-section .shape-3 svg {
    width: 100%;
    height: 100%
}

.intro10-section .shape-3 svg * {
    fill: #b2e0f6;
}

.intro10-section .shape-4 {
    left: 15px;
    bottom: 75px;
}

.intro10-section .shape-5 {
    left: 25%;
    bottom: 30px;
}

.intro10-section .shape-6 {
    right: 5%;
    bottom: 0;
}

.intro10-section .shape-7 {
    right: 23%;
    bottom: 10%;
    width: 166px;
    height: 166px;
}

.intro10-section .shape-7 svg {
    width: 100%;
    height: 100%
}

.intro10-section .shape-7 svg * {
    fill: #ffcd00;
}

.intro10-section .shape-8 {
    right: 19%;
    bottom: 5%
}

.intro10-section .shape-9 {
    right: 8%;
    bottom: 350px;
}

.intro10-section .shape-10 {
    left: 15%;
    bottom: 30%
}

.intro10-section .shape-10 img {
    -webkit-box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .25);
}

.intro10-section .shape-11 {
    left: 10%;
    bottom: 15%
}

.intro10-section .shape-11 img {
    -webkit-box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .25);
}

.intro10-section .shape-12 {
    right: 10%;
    bottom: 14%
}

.intro10-section .shape-12 img {
    -webkit-box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .25);
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro10-section .shape {
        position: absolute;
        z-index: 1;
    }

    .intro10-section .shape-1 {
        right: 0;
    }

    .intro10-section .shape-10 {
        left: 5%;
        bottom: 30%
    }

    .intro10-section .shape-11 {
        left: 0;
        bottom: 15%
    }

    .intro10-section .shape-12 {
        right: 5%;
        bottom: 14%
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro10-section .shape {
        position: absolute;
        z-index: 1;
    }

    .intro10-section .shape-1 {
        top: 0;
        right: 8%;
        width: 20%;
        max-width: 20%
    }

    .intro10-section .shape-2 {
        top: 0;
        left: 0;
        width: 280px;
        max-width: 280px;
    }

    .intro10-section .shape-3 {
        bottom: 30px;
        left: 0;
        width: 180px;
        height: 180px;
    }

    .intro10-section .shape-3 svg {
        width: 100%;
        height: 100%
    }

    .intro10-section .shape-3 svg * {
        fill: #b2e0f6;
    }

    .intro10-section .shape-4 {
        left: 15px;
        bottom: 75px;
        width: 20%;
        max-width: 20%
    }

    .intro10-section .shape-5 {
        left: 25%;
        bottom: 30px;
        width: 150px;
        max-width: 150px;
    }

    .intro10-section .shape-6 {
        right: 5%;
        bottom: 0;
        width: 220px;
        max-width: 220px;
    }

    .intro10-section .shape-7 {
        display: none;
    }

    .intro10-section .shape-8 {
        display: none;
    }

    .intro10-section .shape-9 {
        right: 60px;
        bottom: 350px;
        width: 80px;
        max-width: 80px;
    }

    .intro10-section .shape-10 {
        left: 5%;
        bottom: 30%;
        width: 200px;
        max-width: 200px;
    }

    .intro10-section .shape-11 {
        left: 0;
        bottom: 15%;
        width: 100px;
        max-width: 100px;
    }

    .intro10-section .shape-12 {
        right: 5%;
        bottom: 14%;
        width: 200px;
        max-width: 200px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro10-section .shape {
        position: absolute;
        z-index: 1;
    }

    .intro10-section .shape-1 {
        top: 0;
        right: 8%;
        width: 20%;
        max-width: 20%
    }

    .intro10-section .shape-2 {
        top: 0;
        left: 0;
        width: 280px;
        max-width: 280px;
    }

    .intro10-section .shape-3 {
        bottom: 30px;
        left: 0;
        width: 180px;
        height: 180px;
    }

    .intro10-section .shape-3 svg {
        width: 100%;
        height: 100%
    }

    .intro10-section .shape-3 svg * {
        fill: #b2e0f6;
    }

    .intro10-section .shape-4 {
        left: 15px;
        bottom: 75px;
        width: 20%;
        max-width: 20%
    }

    .intro10-section .shape-5 {
        left: 25%;
        bottom: 30px;
        width: 150px;
        max-width: 150px;
    }

    .intro10-section .shape-6 {
        right: 5%;
        bottom: 0;
        width: 220px;
        max-width: 220px;
    }

    .intro10-section .shape-7 {
        display: none;
    }

    .intro10-section .shape-8 {
        display: none;
    }

    .intro10-section .shape-9 {
        right: 60px;
        bottom: 350px;
        width: 80px;
        max-width: 80px;
    }

    .intro10-section .shape-10 {
        left: 5%;
        bottom: 30%;
        width: 200px;
        max-width: 200px;
    }

    .intro10-section .shape-11 {
        left: 0;
        bottom: 15%;
        width: 100px;
        max-width: 100px;
    }

    .intro10-section .shape-12 {
        right: 5%;
        bottom: 14%;
        width: 200px;
        max-width: 200px;
    }
}

@media only screen and (max-width:767px) {
    .intro10-section .shape {
        position: absolute;
        z-index: 1;
    }

    .intro10-section .shape-1 {
        top: 0;
        right: 8%;
        width: 20%;
        max-width: 20%
    }

    .intro10-section .shape-2 {
        top: 0;
        left: 0;
        width: 280px;
        max-width: 280px;
    }

    .intro10-section .shape-3 {
        bottom: 30px;
        left: 0;
        width: 120px;
        height: 120px;
    }

    .intro10-section .shape-3 svg {
        width: 100%;
        height: 100%
    }

    .intro10-section .shape-3 svg * {
        fill: #b2e0f6;
    }

    .intro10-section .shape-4 {
        left: 15px;
        bottom: 75px;
        width: 20%;
        max-width: 20%
    }

    .intro10-section .shape-5 {
        left: 25%;
        bottom: 30px;
        width: 100px;
        max-width: 100px;
    }

    .intro10-section .shape-6 {
        right: 5%;
        bottom: 0;
        width: 180px;
        max-width: 180px;
    }

    .intro10-section .shape-7 {
        display: none;
    }

    .intro10-section .shape-8 {
        display: none;
    }

    .intro10-section .shape-9 {
        right: 60px;
        bottom: 350px;
        width: 50px;
        max-width: 50px;
    }

    .intro10-section .shape-10 {
        left: 5%;
        bottom: 15%;
        width: 100px;
        max-width: 100px;
    }

    .intro10-section .shape-11 {
        left: 5%;
        bottom: 6%;
        width: 70px;
        max-width: 70px;
    }

    .intro10-section .shape-12 {
        right: 5%;
        bottom: 5%;
        width: 100px;
        max-width: 100px;
    }
}

.intro10-content .sub-title {
    font-family: Conv_Rossela-Demo-2;
    font-size: 64px;
    font-weight: 400;
    color: #6ac5f1;
}

.intro10-content .title {
    font-size: 40px;
    line-height: 1.4;
    font-weight: 700;
    color: #333;
    max-width: 580px;
    margin: 0 auto;
    margin-bottom: 35px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro10-content .sub-title {
        font-size: 50px;
    }

    .intro10-content .title {
        font-size: 34px;
        line-height: 1.4;
    }
}

@media only screen and (max-width:767px) {
    .intro10-content .sub-title {
        font-size: 36px;
    }

    .intro10-content .title {
        font-size: 28px;
        line-height: 1.4;
    }
}

.intro11-slider-wrap {
    position: relative;
    z-index: 9;
}

.intro11-slider-wrap .shape {
    position: absolute;
    z-index: 1;
}

.intro11-slider-wrap .shape-1 {
    top: 0;
    left: -100px;
}

.intro11-slider-wrap .shape-2 {
    bottom: -70px;
    left: 45%
}

.intro11-slider-wrap .shape-3 {
    bottom: 300px;
    right: -60px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro11-slider-wrap .shape-1 {
        top: 0;
        left: 0;
    }

    .intro11-slider-wrap .shape-1 img {
        width: 150px;
    }

    .intro11-slider-wrap .shape-2 {
        bottom: 0;
        left: 45%
    }

    .intro11-slider-wrap .shape-2 img {
        width: 100px;
    }

    .intro11-slider-wrap .shape-3 {
        bottom: 300px;
        right: 0;
    }

    .intro11-slider-wrap .shape-3 img {
        width: 200px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro11-slider-wrap .shape-1 {
        top: 0;
        left: 0;
    }

    .intro11-slider-wrap .shape-1 img {
        width: 120px;
    }

    .intro11-slider-wrap .shape-2 {
        bottom: 0;
        left: 45%
    }

    .intro11-slider-wrap .shape-2 img {
        width: 80px;
    }

    .intro11-slider-wrap .shape-3 {
        bottom: 300px;
        right: 0;
    }

    .intro11-slider-wrap .shape-3 img {
        width: 150px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro11-slider-wrap .shape-1 {
        top: 0;
        left: 0;
    }

    .intro11-slider-wrap .shape-1 img {
        width: 120px;
    }

    .intro11-slider-wrap .shape-2 {
        bottom: 0;
        left: 45%
    }

    .intro11-slider-wrap .shape-2 img {
        width: 80px;
    }

    .intro11-slider-wrap .shape-3 {
        bottom: 300px;
        right: 0;
    }

    .intro11-slider-wrap .shape-3 img {
        width: 150px;
    }
}

@media only screen and (max-width:767px) {
    .intro11-slider-wrap .shape {
        display: none;
    }
}

.intro11-section {
    background-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(65%, #edf4f8), color-stop(65%, #fff));
    background-image: -webkit-linear-gradient(left, #edf4f8 65%, #fff 65%);
    background-image: -o-linear-gradient(left, #edf4f8 65%, #fff 65%);
    background-image: linear-gradient(90deg, #edf4f8 65%, #fff 65%);
    position: relative;
    z-index: 9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 750px;
    padding: 120px 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro11-section {
        height: 700px;
        padding: 120px 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro11-section {
        height: 500px;
        padding: 80px 0;
    }
}

@media only screen and (max-width:767px) {
    .intro11-section {
        /* background-image: -webkit-gradient(linear, left top, right top, color-stop(100%, #edf4f8), to(#fff)); */
        background-image: -webkit-linear-gradient(left, #edf4f8 100%, #fff 100%);
        background-image: -o-linear-gradient(left, #edf4f8 100%, #fff 100%);
        background-image: linear-gradient(90deg, #edf4f8 100%, #fff 100%);
        height: 100%;
        padding: 60px 0;
    }
}

.intro11-section .container {
    z-index: 9;
}

.intro11-content>* {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

.intro11-content .sub-title {
    display: block;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
}

.intro11-content .title {
    font-size: 56px;
    line-height: 72px;
    font-weight: 700;
    color: #333;
    max-width: 550px;
    margin-bottom: 50px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro11-content .sub-title {
        font-size: 21px;
        margin-bottom: 20px;
    }

    .intro11-content .title {
        font-size: 46px;
        line-height: 56px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro11-content .sub-title {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    .intro11-content .title {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:767px) {
    .intro11-content {
        margin-bottom: 30px;
    }

    .intro11-content .sub-title {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    .intro11-content .title {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 30px;
    }
}

.intro-image>* {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
}

.intro11-slider .main-slider-nav {
    cursor: pointer;
    background: rgba(0, 0, 0, .25);
    width: 100px;
    height: 100px;
    line-height: 100px;
    display: block;
    text-align: center;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.intro11-slider .main-slider-nav::after {
    display: none;
}

.intro11-slider .main-slider-nav i {
    font-size: 40px;
    line-height: 1;
    vertical-align: middle;
}

.intro11-slider .main-slider-nav:hover {
    color: #ef6f31;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro11-slider .main-slider-nav {
        width: 80px;
        height: 80px;
        line-height: 80px;
    }

    .intro11-slider .main-slider-nav i {
        font-size: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro11-slider .main-slider-nav {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }

    .intro11-slider .main-slider-nav i {
        font-size: 24px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro11-slider .main-slider-nav {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }

    .intro11-slider .main-slider-nav i {
        font-size: 24px;
    }
}

@media only screen and (max-width:767px) {
    .intro11-slider .main-slider-nav {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .intro11-slider .main-slider-nav i {
        font-size: 24px;
    }
}

.intro11-slider .home11-slider-prev {
    left: 30px;
}

.intro11-slider .home11-slider-next {
    right: 30px;
}

.intro11-slider .home11-slider-next::after {
    display: none;
}

.intro11-slider:hover .main-slider-nav {
    opacity: 1;
    visibility: visible;
}

.swiper-slide-active .intro11-content>* {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.swiper-slide-active .intro11-content>:nth-child(1) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.swiper-slide-active .intro11-content>:nth-child(2) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.swiper-slide-active .intro11-content>:nth-child(3) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.swiper-slide-active .intro11-content>:nth-child(4) {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.swiper-slide-active .intro11-content>:nth-child(5) {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.swiper-slide-active .intro11-content>:nth-child(6) {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.swiper-slide-active .intro-image>* {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.swiper-slide-active .intro-image>:nth-child(1) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.swiper-slide-active .intro-image>:nth-child(2) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.intro11-slider .intro11-section {
    -webkit-transform: scale(1.35);
    -ms-transform: scale(1.35);
    transform: scale(1.35);
    transform: scale(1.35);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-slide-active .intro11-section {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
    -o-transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1);
    transition: transform 11s cubic-bezier(.1, .2, .7, 1), -webkit-transform 11s cubic-bezier(.1, .2, .7, 1);
}

.intro12-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-repeat: repeat !important;
    background-position: inherit !important;
    background-size: inherit !important;
    padding: 137px 0 0;
    overflow: hidden;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro12-section {
        padding: 100px 0 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro12-section {
        padding: 100px 0 0;
    }
}

@media only screen and (max-width:767px) {
    .intro12-section {
        padding: 100px 0 0;
    }
}

.intro12-section .container {
    z-index: 9;
}

.intro12-content {
    max-width: 440px;
}

@media only screen and (max-width:767px) {
    .intro12-content {
        max-width: 100%
    }
}

.intro12-content .sub-title {
    display: block;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2;
    letter-spacing: 2px;
    color: #333;
    margin-bottom: 10px;
}

.intro12-content .title {
    font-family: "Playfair Display", serif;
    font-size: 56px;
    font-weight: 400;
    line-height: 1.34;
    color: #333;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro12-content .title {
        font-size: 46px;
    }
}

.intro12-content .desc {
    margin-top: 25px;
}

.intro12-content .desc p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.875;
    color: #696969;
}

.intro12-content .btn {
    margin-top: 30px;
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .intro12-content .title {
        font-size: 36px;
    }

    .intro12-content .desc p {
        font-size: 16px;
    }
}

@media only screen and (max-width:479px) {
    .intro12-content .title {
        font-size: 30px;
    }

    .intro12-content .desc p {
        font-size: 14px;
    }
}

.intro12-image {
    position: relative;
    z-index: 9;
    text-align: center;
}

.intro12-image img {
    width: 100%
}

@media only screen and (max-width:767px) {
    .intro12-image img {
        width: 50%
    }
}

.intro12-image .shape {
    position: absolute;
    z-index: -1;
}

.intro12-image .shape-1 {
    bottom: 0;
    right: -120px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .intro12-image .shape-1 {
        right: 0;
    }

    .intro12-image .shape-1 img {
        max-width: 450px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .intro12-image .shape-1 {
        right: 0;
    }

    .intro12-image .shape-1 img {
        max-width: 450px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .intro12-image .shape-1 {
        right: 0;
    }

    .intro12-image .shape-1 img {
        max-width: 350px;
    }
}

@media only screen and (max-width:767px) {
    .intro12-image .shape-1 {
        right: 0;
    }

    .intro12-image .shape-1 img {
        max-width: 50%;
        max-width: 50%
    }
}

.teacher-quote-wrapper {
    padding-bottom: 50px;
    padding-left: 200px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .teacher-quote-wrapper {
        padding-left: 135px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .teacher-quote-wrapper {
        padding-left: 0;
        padding-bottom: 0;
    }
}

.teacher-quote {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #faf8f6;
}

.teacher-quote .image {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 270px;
    margin-left: -135px;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
}

.teacher-quote .content {
    padding: 70px 30px 70px 50px;
}

.teacher-quote .content .section-title {
    margin-bottom: 25px;
}

.teacher-quote .content .quote {
    width: 64.5%;
    padding: 0 15px;
}

.teacher-quote .content .quote p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
}

.teacher-quote .content .experience {
    width: 35.406%;
    padding: 0 15px;
}

.teacher-quote .content .experience .amount {
    font-size: 48px;
    font-weight: 800;
    line-height: 1.3;
    display: block;
    color: #076fcc;
}

.teacher-quote .content .experience .amount span {
    font-size: 24px;
    margin-left: 5px;
}

.teacher-quote .content .experience .title {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #3f3a64;
}

.teacher-quote .content .experience .link {
    font-size: 14px;
    font-weight: 700;
    color: #8c89a2;
}

.teacher-quote .content .experience .link:hover {
    color: #076fcc;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .teacher-quote .content .quote p {
        font-size: 20px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .teacher-quote .image {
        display: none;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .teacher-quote .content .quote p {
        font-size: 22px;
    }
}

@media only screen and (max-width:767px) {
    .teacher-quote .content {
        padding: 40px;
    }

    .teacher-quote .content .quote {
        width: 100%;
        margin-bottom: 20px;
    }

    .teacher-quote .content .experience {
        width: 100%
    }
}

@media only screen and (max-width:575px) {
    .teacher-quote .content {
        padding: 30px;
    }

    .teacher-quote .content .quote p {
        font-size: 22px;
    }
}

.author-quote {
    padding-left: 50px;
    max-width: 400px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .author-quote {
        padding-left: 0;
        margin-top: 30px;
    }
}

@media only screen and (max-width:767px) {
    .author-quote {
        padding-left: 0;
        margin-top: 30px;
    }
}

.author-quote .title {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.916;
    margin-bottom: 50px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .author-quote .title {
        font-size: 21px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:767px) {
    .author-quote .title {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

.author-quote .motivation-name-icon {
    margin-bottom: 25px;
}

.author-quote .author-name h3 {
    font-size: 21px;
    font-weight: 600;
    color: #3f3a64;
    margin-bottom: 12px;
}

.author-quote .author-name span {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: 1.38px;
    color: #8c89a2;
}

.testimonial-slider {
    margin: -30px;
    padding: 30px;
}

.testimonial-slider .swiper-slide:not(.swiper-slide-visible) .testimonial {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.testimonial-slider-two .swiper-slide.swiper-slide-prev {
    opacity: .5;
}

.testimonial-slider-two .swiper-slide.swiper-slide-next {
    opacity: .5;
}

.testimonial {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    padding: 40px 28px;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0 0 30px rgba(51, 51, 51, .1);
    box-shadow: 0 0 30px rgba(51, 51, 51, .1);
}

.testimonial .image {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 70px;
}

.testimonial .image img {
    width: 70px;
    border-radius: 50%
}

.testimonial .content {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: calc(100% - 70px);
    padding-left: 30px;
}

.testimonial .content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    margin-bottom: 34px;
}

.testimonial .content .name {
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.testimonial .content .position {
    font-size: 14px;
    display: block;
    margin-top: 11px;
    color: #7e7e7e;
}

@media only screen and (max-width:479px) {
    .testimonial .content {
        width: 100%;
        padding-top: 20px;
        padding-left: 0;
    }
}

.testimonial-two {
    height: 100%;
    padding: 31px 50px 47px;
    background: #fff;
    -webkit-box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    border-radius: 5px;
}

@media only screen and (max-width:767px) {
    .testimonial-two {
        padding: 31px 25px 47px;
    }
}

.testimonial-two.testimonial-three {
    padding: 41px 50px 36px;
    -webkit-box-shadow: 0 16px 40px -40px rgba(51, 51, 51, .13);
    box-shadow: 0 16px 40px -40px rgba(51, 51, 51, .13);
}

@media only screen and (max-width:767px) {
    .testimonial-two.testimonial-three {
        padding: 30px 20px 30px;
    }
}

.testimonial-two.testimonial-three .content .title {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
}

.testimonial-two.testimonial-three .content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
}

.testimonial-two.testimonial-three .author-info {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    padding-top: 40px;
    padding-bottom: 0;
}

@media only screen and (max-width:767px) {
    .testimonial-two.testimonial-three .author-info {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-top: 20px;
    }
}

.testimonial-two.testimonial-three .author-info .image {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 20px 0 0;
}

.testimonial-two.testimonial-three .author-info .image img {
    width: 70px;
    border-radius: 50%
}

.testimonial-two.testimonial-three .author-info .cite .name {
    display: block;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.testimonial-two.testimonial-three .author-info .cite .position {
    display: block;
    margin-left: 0;
    margin-top: 10px;
    font-size: 14px;
    color: #7e7e7e;
}

.testimonial-two.testimonial-kitchen {
    position: relative;
    background-color: #faf8f6;
    padding: 59px 50px 53px;
}

@media only screen and (max-width:767px) {
    .testimonial-two.testimonial-kitchen {
        padding: 30px 25px 30px;
    }
}

.testimonial-two.testimonial-kitchen .testimonial-quote-icon {
    position: absolute;
    top: 34px;
    right: 47px;
    font-size: 40px;
    line-height: 1;
}

.testimonial-two.testimonial-kitchen .testimonial-quote-icon svg {
    -webkit-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    width: 40px;
    height: auto;
}

.testimonial-two.testimonial-kitchen .testimonial-quote-icon svg path {
    fill: #f8e4d9;
}

.testimonial-two.testimonial-kitchen .content p {
    font-family: "Playfair Display", serif;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    line-height: 1.78;
    letter-spacing: 1px;
}

.testimonial-two .author-info {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    padding-bottom: 20px;
}

@media only screen and (max-width:767px) {
    .testimonial-two .author-info {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.testimonial-two .author-info .image {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 30px 0 0;
}

@media only screen and (max-width:767px) {
    .testimonial-two .author-info .image {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.testimonial-two .author-info .image img {
    width: 90px;
    border-radius: 50%
}

.testimonial-two .author-info .cite .testimonial-rating {
    font-size: 14px;
    color: #fb0;
    margin-bottom: 11px;
}

.testimonial-two .author-info .cite .name {
    display: inline-block;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.testimonial-two .author-info .cite .position {
    display: inline-block;
    margin: 0 0 0 13px;
    font-size: 14px;
    color: #7e7e7e;
}

.testimonial-two .content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
}

.testimonial-four {
    width: 770px;
    margin: 0 auto;
    text-align: center;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-four {
        width: 100%
    }
}

@media only screen and (max-width:767px) {
    .testimonial-four {
        width: 100%
    }
}

.testimonial-four .content .title {
    font-size: 34px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 0;
}

@media only screen and (max-width:767px) {
    .testimonial-four .content .title {
        font-size: 26px;
        line-height: 36px;
    }
}

@media only screen and (max-width:479px) {
    .testimonial-four .content .title {
        font-size: 18px;
        line-height: 28px;
    }
}

.testimonial-four .author-info {
    display: inline-block;
    padding-top: 40px;
}

@media only screen and (max-width:767px) {
    .testimonial-four .author-info {
        padding-top: 20px;
    }
}

.testimonial-four .author-info .cite .name {
    display: block;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.testimonial-four .author-info .cite .position {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #7e7e7e;
}

.testimonial-five {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 9;
    padding: 0 15px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .testimonial-five {
        width: 100%
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-five {
        width: 100%
    }
}

@media only screen and (max-width:767px) {
    .testimonial-five {
        width: 100%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.testimonial-five .shape {
    position: absolute;
    z-index: 1;
}

.testimonial-five .shape-1 {
    top: 0;
    left: -39px;
    z-index: -1;
    width: 166px;
    height: 166px;
    -webkit-transform: rotate(0) scale(1, 1);
    -ms-transform: rotate(0) scale(1, 1);
    transform: rotate(0) scale(1, 1);
}

.testimonial-five .shape-1 svg {
    width: 100%;
    height: 100%
}

.testimonial-five .shape-1 svg * {
    fill: #d2a98e;
}

.testimonial-five .shape-2 {
    bottom: 0;
    left: 110px;
    width: 124px;
    z-index: -1;
}

.testimonial-five .author-image {
    margin: 0 100px 0 0;
    width: 200px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .testimonial-five .author-image {
        margin: 0 50px 0 0;
        width: 140px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 140px;
        flex: 0 0 140px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-five .author-image {
        margin: 0 50px 0 0;
        width: 140px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 140px;
        flex: 0 0 140px;
    }
}

@media only screen and (max-width:767px) {
    .testimonial-five .author-image {
        margin: 0 0 20px 0;
        width: 140px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 140px;
        flex: 0 0 140px;
    }
}

.testimonial-five .author-image img {
    width: 200px;
    height: 200px;
    border-radius: 50%
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .testimonial-five .author-image img {
        width: 140px;
        height: 140px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-five .author-image img {
        width: 140px;
        height: 140px;
    }
}

@media only screen and (max-width:767px) {
    .testimonial-five .author-image img {
        width: 140px;
        height: 140px;
    }
}

.testimonial-five .author-content .content .title {
    font-size: 34px;
    font-weight: 500;
    line-height: 1.42;
    color: #333;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-five .author-content .content .title {
        font-size: 28px;
    }
}

@media only screen and (max-width:767px) {
    .testimonial-five .author-content .content .title {
        font-size: 24px;
    }
}

.testimonial-five .author-content .author-info {
    display: inline-block;
    padding-top: 35px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-five .author-content .author-info {
        padding-top: 20px;
    }
}

@media only screen and (max-width:767px) {
    .testimonial-five .author-content .author-info {
        padding-top: 20px;
    }
}

.testimonial-five .author-content .author-info .cite .name {
    display: block;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.testimonial-five .author-content .author-info .cite .position {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #7e7e7e;
}

.h5-testimonial-slider .swiper-wrapper .swiper-slide {
    padding-top: 70px;
}

.static-testimonial {
    margin: 30px 0;
    padding: 44px 26px 44px;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
    text-align: center;
}

.static-testimonial.h5-testimonial {
    position: relative;
    padding: 41px 28px 38px;
    padding-top: 100px;
    -webkit-box-shadow: 0 0 30px rgba(51, 51, 51, .1);
    box-shadow: 0 0 30px rgba(51, 51, 51, .1);
    height: 100%;
    margin: 0;
}

.static-testimonial.h5-testimonial .testimonial-image {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-bottom: 0;
}

.static-testimonial.h5-testimonial .testimonial-image img {
    width: 140px;
    height: 140px;
    border-radius: 50%
}

.static-testimonial.testimonial-home-8 {
    margin: 0 30px;
    padding: 41px 50px 36px;
    -webkit-box-shadow: 0 16px 40px -40px rgba(51, 51, 51, .13);
    box-shadow: 0 16px 40px -40px rgba(51, 51, 51, .13);
}

@media only screen and (max-width:767px) {
    .static-testimonial.testimonial-home-8 {
        margin: 0;
    }
}

@media only screen and (max-width:479px) {
    .static-testimonial.testimonial-home-8 {
        padding: 35px 25px 35px;
    }
}

.static-testimonial.testimonial-home-8 .testimonial-image {
    display: inline-block;
    border: 6px solid #50e3c2;
    border-radius: 50%;
    padding: 3px;
}

.static-testimonial.testimonial-home-8 .testimonial-image img {
    width: 124px;
    height: 124px;
    border-radius: 50%
}

.static-testimonial.testimonial-home-8 .testimonial-content {
    position: relative;
    max-width: 530px;
    margin: 0 auto;
    text-align: center;
    padding-top: 45px;
}

.static-testimonial.testimonial-home-8 .testimonial-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: url(../images/icons/testimonial-icon-8.png) no-repeat center;
    width: 36px;
    height: 31px;
}

.static-testimonial.testimonial-home-8 .testimonial-content p {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.62;
    color: #3f3a64;
    margin-bottom: 0;
}

@media only screen and (max-width:479px) {
    .static-testimonial.testimonial-home-8 .testimonial-content p {
        font-size: 16px;
    }
}

.static-testimonial.testimonial-home-8 .author-info {
    padding: 21px 0 0;
}

.static-testimonial .testimonial-image {
    margin-bottom: 30px;
}

.static-testimonial .testimonial-image img {
    border-radius: 50%
}

.static-testimonial .testimonial-content {
    margin-bottom: 40px;
}

.static-testimonial .testimonial-content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #3f3a64;
    margin-bottom: 0;
}

.static-testimonial .author-info {
    display: inline-block;
}

.static-testimonial .author-info .cite .name {
    display: block;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.static-testimonial .author-info .cite .position {
    display: block;
    margin-top: 10px;
    font-size: 15px;
    color: #7e7e7e;
}

.testimonial-modern-heading {
    max-width: 270px;
    width: 270px;
    margin: 0 0 30px;
    padding: 30px 26px;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
    float: right;
}

.testimonial-modern-heading .title {
    font-size: 20px;
    line-height: 30px;
    color: #3f3a64;
}

.testimonial-rating-area {
    padding: 56px 25px 36px;
    max-width: 210px;
    width: 210px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
    border-radius: 5px;
    text-align: center;
}

.testimonial-rating-area .rating-number h2 {
    font-size: 24px;
    line-height: 1;
    color: #3f3a64;
}

.testimonial-rating-area .testimonial-rating {
    font-size: 18px;
    color: #fb0;
    margin: 5px 0 10px;
}

.animation-shape {
    position: relative;
    z-index: 9;
}

.animation-shape .shape {
    position: absolute;
    z-index: 1;
}

.animation-shape .shape-1 {
    z-index: 11;
    top: -35px;
    left: -242px;
}

.animation-shape .shape-1 span {
    display: block;
    width: 52px;
    height: 52px;
    text-indent: -9999px;
    border: 6px solid rgba(32, 173, 150, .5);
    border-radius: 50%
}

.animation-shape .shape-2 {
    left: -70px;
    top: 164px;
}

.animation-shape .shape-2 span {
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    background: rgba(255, 77, 36, .7);
    border-radius: 50%
}

.animation-shape .shape-3 {
    top: 314px;
    left: -285px;
}

.animation-shape .shape-3 span {
    display: block;
    width: 54px;
    height: 54px;
    text-indent: -9999px;
    background: #ebb860;
    border-radius: 50%
}

.animation-shape .shape-4 {
    top: -100px;
    right: -205px;
}

.animation-shape .shape-4 span {
    display: block;
    width: 46px;
    height: 46px;
    text-indent: -9999px;
    background: #bce6df;
    border-radius: 50%
}

.animation-shape .shape-5 {
    top: 0;
    right: 30px;
}

.animation-shape .shape-5 span {
    display: block;
    width: 36px;
    height: 36px;
    text-indent: -9999px;
    background: rgba(114, 136, 232, .6);
    border-radius: 50%
}

.animation-shape .shape-6 {
    top: 233px;
    right: -216px;
}

.animation-shape .shape-6 span {
    display: block;
    width: 38px;
    height: 38px;
    text-indent: -9999px;
    background: rgba(223, 91, 108, .7);
    border-radius: 50%
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .animation-shape .shape-1 {
        z-index: 11;
        top: -35px;
        left: 0;
    }

    .animation-shape .shape-2 {
        left: 50px;
        top: 100px;
    }

    .animation-shape .shape-3 {
        top: 180px;
        left: 0;
    }

    .animation-shape .shape-4 {
        top: -100px;
        right: 0;
    }

    .animation-shape .shape-5 {
        top: 0;
        right: 30px;
    }

    .animation-shape .shape-6 {
        top: 180px;
        right: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .animation-shape .shape-1 {
        z-index: 11;
        top: -35px;
        left: 0;
    }

    .animation-shape .shape-2 {
        left: 50px;
        top: 100px;
    }

    .animation-shape .shape-3 {
        top: 180px;
        left: 0;
    }

    .animation-shape .shape-4 {
        top: -100px;
        right: 0;
    }

    .animation-shape .shape-5 {
        top: 0;
        right: 30px;
    }

    .animation-shape .shape-6 {
        top: 180px;
        right: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .animation-shape .shape-1 {
        z-index: 11;
        top: -35px;
        left: 0;
    }

    .animation-shape .shape-2 {
        left: 50px;
        top: 100px;
    }

    .animation-shape .shape-3 {
        top: 180px;
        left: 0;
    }

    .animation-shape .shape-4 {
        top: -100px;
        right: 0;
    }

    .animation-shape .shape-5 {
        top: 0;
        right: 30px;
    }

    .animation-shape .shape-6 {
        top: 180px;
        right: 0;
    }
}

@media only screen and (max-width:767px) {
    .animation-shape .shape {
        z-index: -1;
    }

    .animation-shape .shape-1 {
        top: -35px;
        left: 0;
    }

    .animation-shape .shape-2 {
        left: 50px;
        top: 100px;
    }

    .animation-shape .shape-3 {
        top: 155px;
        left: 0;
    }

    .animation-shape .shape-4 {
        top: -100px;
        right: 0;
    }

    .animation-shape .shape-5 {
        top: 0;
        right: 30px;
    }

    .animation-shape .shape-6 {
        top: 155px;
        right: 0;
    }
}

.animation-shape-two {
    position: relative;
    z-index: 9;
}

.animation-shape-two .shape {
    position: absolute;
    z-index: 1;
}

.animation-shape-two .shape-1 {
    z-index: 11;
    top: 0;
    left: 0;
}

.animation-shape-two .shape-1 span {
    display: block;
    width: 52px;
    height: 52px;
    text-indent: -9999px;
    border: 6px solid rgba(32, 173, 150, .5);
    border-radius: 50%
}

.animation-shape-two .shape-2 {
    left: -195px;
    bottom: 99px;
}

.animation-shape-two .shape-2 span {
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    background: #fa7d61;
    border-radius: 50%
}

.animation-shape-two .shape-3 {
    top: 62px;
    right: 0;
}

.animation-shape-two .shape-3 span {
    display: block;
    width: 46px;
    height: 46px;
    text-indent: -9999px;
    background: #bce6df;
    border-radius: 50%
}

.animation-shape-two .shape-4 {
    bottom: 0;
    right: 0;
}

.animation-shape-two .shape-4 span {
    display: block;
    width: 38px;
    height: 38px;
    text-indent: -9999px;
    background: #e28794;
    border-radius: 50%
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .animation-shape-two .shape {
        position: absolute;
        z-index: 1;
    }

    .animation-shape-two .shape-1 {
        z-index: 11;
        top: 0;
        left: 0;
    }

    .animation-shape-two .shape-2 {
        left: 10px;
        bottom: 99px;
    }

    .animation-shape-two .shape-3 {
        top: 62px;
        right: 0;
    }

    .animation-shape-two .shape-4 {
        bottom: 0;
        right: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .animation-shape-two .shape {
        position: absolute;
        z-index: 1;
    }

    .animation-shape-two .shape-1 {
        z-index: 11;
        top: 0;
        left: 0;
    }

    .animation-shape-two .shape-2 {
        left: 10px;
        bottom: 99px;
    }

    .animation-shape-two .shape-2 span {
        display: block;
        width: 35px;
        height: 35px;
        text-indent: -9999px;
        background: #fa7d61;
        border-radius: 50%
    }

    .animation-shape-two .shape-3 {
        top: 62px;
        right: 0;
    }

    .animation-shape-two .shape-4 {
        bottom: 0;
        right: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .animation-shape-two .shape {
        position: absolute;
        z-index: 1;
    }

    .animation-shape-two .shape-1 {
        z-index: 11;
        top: 0;
        left: 0;
    }

    .animation-shape-two .shape-2 {
        left: -10px;
        bottom: 99px;
    }

    .animation-shape-two .shape-3 {
        top: 62px;
        right: 0;
    }

    .animation-shape-two .shape-4 {
        bottom: 0;
        right: 0;
    }
}

@media only screen and (max-width:767px) {
    .animation-shape-two .shape {
        position: absolute;
        z-index: 1;
    }

    .animation-shape-two .shape-1 {
        z-index: -1;
        top: 0;
        left: 0;
    }

    .animation-shape-two .shape-2 {
        left: 10px;
        bottom: 99px;
    }

    .animation-shape-two .shape-3 {
        top: 62px;
        right: 0;
    }

    .animation-shape-two .shape-4 {
        bottom: 0;
        right: 0;
    }
}

.animation-shape-three {
    position: relative;
    z-index: 9;
}

.animation-shape-three .shape {
    position: absolute;
    z-index: 1;
}

.animation-shape-three .shape-1 {
    z-index: 11;
    top: 25px;
    left: -184px;
}

.animation-shape-three .shape-1 span {
    display: block;
    width: 54px;
    height: 54px;
    text-indent: -9999px;
    border: 0;
    background: #ebb860;
    border-radius: 50%
}

.animation-shape-three .shape-2 {
    top: 56px;
    left: auto;
    right: -156px;
}

.animation-shape-three .shape-2 span {
    display: block;
    width: 36px;
    height: 36px;
    text-indent: -9999px;
    background: #aab8f1;
    border-radius: 50%
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .animation-shape-three .shape {
        position: absolute;
        z-index: 1;
    }

    .animation-shape-three .shape-1 {
        z-index: 11;
        top: 25px;
        left: 0;
    }

    .animation-shape-three .shape-2 {
        top: 56px;
        left: auto;
        right: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .animation-shape-three .shape {
        position: absolute;
        z-index: 1;
    }

    .animation-shape-three .shape-1 {
        z-index: 11;
        top: 25px;
        left: 0;
    }

    .animation-shape-three .shape-2 {
        top: 56px;
        left: auto;
        right: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .animation-shape-three .shape {
        position: absolute;
        z-index: 1;
    }

    .animation-shape-three .shape-1 {
        z-index: -1;
        top: 25px;
        left: 0;
    }

    .animation-shape-three .shape-2 {
        top: 56px;
        left: auto;
        right: 0;
    }
}

@media only screen and (max-width:767px) {
    .animation-shape-three .shape {
        position: absolute;
        z-index: -1;
    }

    .animation-shape-three .shape-1 {
        top: 25px;
        left: 0;
    }

    .animation-shape-three .shape-2 {
        top: 56px;
        left: auto;
        right: 0;
    }
}

.testimonial-area {
    position: relative;
    z-index: 9;
}

.testimonial-area .shape {
    position: absolute;
    z-index: 1;
}

.testimonial-area .shape-1 {
    bottom: 130px;
    left: -110px;
    z-index: -1;
    width: 166px;
    height: 166px;
    -webkit-transform: rotate(0) scale(1, 1);
    -ms-transform: rotate(0) scale(1, 1);
    transform: rotate(0) scale(1, 1);
}

.testimonial-area .shape-1 svg {
    width: 100%;
    height: 100%
}

.testimonial-area .shape-1 svg * {
    fill: #8fd6ca;
}

.testimonial-area .shape-2 {
    bottom: 80px;
    left: -70px;
    z-index: -1;
}

.testimonial-area .shape-3 {
    z-index: 11;
    top: 100px;
    right: 50px;
}

.testimonial-area .shape-3 span {
    display: block;
    width: 54px;
    height: 54px;
    text-indent: -9999px;
    border: 8px solid #ecc5ab;
    border-radius: 50%
}

.testimonial-area .shape-4 {
    right: -10px;
    bottom: 60px;
    z-index: -1;
}

@media only screen and (max-width:767px) {
    .testimonial-area .shape-1 {
        top: -90px;
        width: 120px;
        height: 130px;
    }

    .testimonial-area .shape-2 {
        width: 90px;
        height: 90px;
    }

    .testimonial-area .shape-3 {
        right: -20px;
    }

    .testimonial-area .shape-3 span {
        width: 45px;
        height: 45px;
        border-width: 6px;
    }

    .testimonial-area .shape-4 {
        width: 85px;
    }
}

.health-section-bg {
    background-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(70%, #f1f4f2), color-stop(70%, #fff));
    background-image: -webkit-linear-gradient(left, #f1f4f2 70%, #fff 70%);
    background-image: -o-linear-gradient(left, #f1f4f2 70%, #fff 70%);
    background-image: linear-gradient(90deg, #f1f4f2 70%, #fff 70%);
    position: relative;
    z-index: 9;
}

.health-section-bg .shape {
    position: absolute;
    z-index: 1;
}

.health-section-bg .shape-1 {
    z-index: -1;
    bottom: 20px;
    left: -70px;
}

.health-section-bg .shape-2 {
    z-index: -1;
    bottom: 0;
    left: 45%
}

.health-section-bg .shape-3 {
    z-index: -1;
    bottom: 0;
    right: 0;
}

.health-section-bg .shape-4 {
    z-index: -1;
    top: 40px;
    right: 15%
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .health-section-bg .shape {
        position: absolute;
        z-index: 1;
    }

    .health-section-bg .shape-1 {
        z-index: -1;
        bottom: 20px;
        left: 0;
    }

    .health-section-bg .shape-2 {
        z-index: -1;
        bottom: 0;
        left: 45%
    }

    .health-section-bg .shape-3 {
        z-index: -1;
        bottom: 0;
        right: 0;
    }

    .health-section-bg .shape-4 {
        z-index: -1;
        top: 40px;
        right: 15%
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .health-section-bg .shape {
        position: absolute;
        z-index: 1;
    }

    .health-section-bg .shape-1 {
        z-index: -1;
        bottom: 20px;
        left: 0;
    }

    .health-section-bg .shape-2 {
        z-index: -1;
        bottom: 0;
        left: 45%
    }

    .health-section-bg .shape-3 {
        z-index: -1;
        bottom: 0;
        right: 0;
    }

    .health-section-bg .shape-4 {
        z-index: -1;
        top: 40px;
        right: 15%
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .health-section-bg .shape {
        position: absolute;
        z-index: 1;
    }

    .health-section-bg .shape-1 {
        z-index: -1;
        bottom: 20px;
        left: 0;
        width: 120px;
    }

    .health-section-bg .shape-2 {
        z-index: -1;
        bottom: 0;
        left: 45%;
        width: 120px;
    }

    .health-section-bg .shape-3 {
        z-index: -1;
        bottom: 0;
        right: 0;
        width: 120px;
    }

    .health-section-bg .shape-4 {
        z-index: -1;
        top: 40px;
        right: 15%;
        width: 120px;
    }
}

@media only screen and (max-width:767px) {
    .health-section-bg .shape {
        position: absolute;
        z-index: 1;
    }

    .health-section-bg .shape-1 {
        z-index: -1;
        bottom: 20px;
        left: 0;
        width: 100px;
    }

    .health-section-bg .shape-2 {
        z-index: -1;
        bottom: 0;
        left: 45%;
        width: 100px;
    }

    .health-section-bg .shape-3 {
        z-index: -1;
        bottom: 0;
        right: 0;
        width: 100px;
    }

    .health-section-bg .shape-4 {
        z-index: -1;
        top: 40px;
        right: 15%;
        width: 100px;
    }
}

.health-testimonial-wrapper {
    max-width: 600px;
    margin: auto;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .health-testimonial-wrapper {
        max-width: 100%
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .health-testimonial-wrapper {
        max-width: 100%
    }
}

@media only screen and (max-width:767px) {
    .health-testimonial-wrapper {
        max-width: 100%
    }
}

.health-testimonial {
    position: relative;
}

.health-testimonial .testimonial-quote {
    position: absolute;
    left: -107px;
    top: -27px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .health-testimonial .testimonial-quote {
        left: 0;
    }
}

@media only screen and (max-width:767px) {
    .health-testimonial .testimonial-quote {
        left: 0;
        max-width: 40px;
        width: 40px;
        top: -40px;
    }
}

.health-testimonial .testimonial-content h2 {
    font-family: "Playfair Display", serif;
    font-size: 34px;
    font-weight: 400;
    font-style: italic;
    line-height: 1.42em;
    color: #3f3a64;
    margin: 0 0 41px;
}

@media only screen and (max-width:767px) {
    .health-testimonial .testimonial-content h2 {
        font-size: 26px;
        margin-bottom: 25px;
    }
}

.health-testimonial .testimonial-content .cite .name {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;
}

.health-testimonial .testimonial-content .cite .position {
    font-size: 14px;
    font-weight: 500;
    margin-top: 7px;
    display: inline-block;
}

.health-testimonial-image-wrapper {
    width: 695px;
    max-width: 695px;
    overflow: hidden;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .health-testimonial-image-wrapper {
        width: 100%;
        max-width: 100%
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .health-testimonial-image-wrapper {
        width: 100%;
        max-width: 100%
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .health-testimonial-image-wrapper {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width:767px) {
    .health-testimonial-image-wrapper {
        width: 100%;
        max-width: 100%;
        margin-bottom: 50px;
    }
}

.testimonial-slider-wrap {
    position: relative;
    z-index: 9;
}

.testimonial-slider-wrap .shape {
    position: absolute;
    z-index: -1;
}

.testimonial-slider-wrap .shape-1 {
    bottom: 20px;
    left: -70px;
    width: 166px;
    height: 166px;
}

.testimonial-slider-wrap .shape-1 svg {
    width: 100%;
    height: 100%
}

.testimonial-slider-wrap .shape-1 svg * {
    fill: #ebb860;
}

.testimonial-slider-wrap .shape-2 {
    right: -90px;
    top: -55px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-slider-wrap .shape {
        position: absolute;
        z-index: -1;
    }

    .testimonial-slider-wrap .shape-1 {
        bottom: 20px;
        left: 0;
        width: 120px;
        height: 120px;
    }

    .testimonial-slider-wrap .shape-1 svg {
        width: 100%;
        height: 100%
    }

    .testimonial-slider-wrap .shape-1 svg * {
        fill: #ebb860;
    }

    .testimonial-slider-wrap .shape-2 {
        right: 0;
        top: -55px;
        width: 130px;
    }
}

@media only screen and (max-width:767px) {
    .testimonial-slider-wrap .shape {
        position: absolute;
        z-index: -1;
    }

    .testimonial-slider-wrap .shape-1 {
        bottom: 20px;
        left: 0;
        width: 80px;
        height: 80px;
    }

    .testimonial-slider-wrap .shape-1 svg {
        width: 100%;
        height: 100%
    }

    .testimonial-slider-wrap .shape-1 svg * {
        fill: #ebb860;
    }

    .testimonial-slider-wrap .shape-2 {
        right: 0;
        top: -40px;
        width: 90px;
    }
}

.testimonial-slider-three-item .ht-swiper-button-nav {
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    color: #111;
    border: 1px solid #fff;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 10px;
    pointer-events: auto;
}

.testimonial-slider-three-item .ht-swiper-button-nav::after {
    display: none;
}

.testimonial-slider-three-item:hover .ht-swiper-button-nav {
    opacity: 1;
    visibility: visible;
}

.testimonial-banner-image img {
    width: 100%
}

.testimonial-title {
    margin-bottom: 50px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .testimonial-title {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .testimonial-title {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .testimonial-title {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:767px) {
    .testimonial-title {
        margin-bottom: 15px;
    }
}

.testimonial-title .sub-title {
    display: block;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2;
    letter-spacing: 2px;
    color: #076fcc;
}

.motivation-testimonial-section {
    background-repeat: repeat !important;
    background-position: inherit !important;
    background-size: inherit !important;
}

.motivation-testimonial {
    max-width: 530px;
    padding: 0 30px;
    margin: 0 auto;
    text-align: center;
}

.motivation-testimonial .content p {
    color: #111;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7;
    margin: 0 0 32px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .motivation-testimonial .content p {
        margin: 0 0 15px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .motivation-testimonial .content p {
        font-size: 21px;
        margin: 0 0 10px;
    }
}

@media only screen and (max-width:767px) {
    .motivation-testimonial .content p {
        font-size: 18px;
        margin: 0 0 10px;
    }
}

.motivation-testimonial .author-info {
    padding-top: 20px;
}

.motivation-testimonial .author-info .image {
    margin-bottom: 28px;
}

.motivation-testimonial .author-info .image img {
    width: 85px;
    height: 85px;
    border-radius: 50%
}

.motivation-testimonial .author-info .cite .name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.3;
    text-transform: capitalize;
    margin-bottom: 0;
}

.motivation-testimonial .author-info .cite .position {
    display: block;
    font-size: 14px;
    color: #7e7e7e;
    margin-top: 10px;
}

.motivation-testimonial-slider-wrap {
    position: relative;
}

.motivation-testimonial-slider-wrap .swiper-pagination-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 9;
}

.motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
}

@media only screen and (max-width:767px) {
    .motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
}

.motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner {
    position: relative;
    margin-right: -45px;
    margin-top: -70px;
    width: 94px;
    height: 94px;
    line-height: 94px;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, .05);
    box-shadow: 0 20px 50px rgba(0, 0, 0, .05);
    pointer-events: auto;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: -145px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: -125px;
    }
}

@media only screen and (max-width:767px) {
    .motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: -105px;
    }
}

.motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner .swiper-pagination {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    width: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    margin-top: 0;
    line-height: 94px;
    font-size: 17px;
    font-weight: 500;
    color: #111;
    letter-spacing: 1.31px;
    text-align: center;
}

.motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner .custom-arrow {
    position: absolute;
    width: 21px;
    height: 60px;
    left: 50%;
    top: 50%;
    z-index: 12;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner .custom-arrow::after {
    display: none;
}

.motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner .custom-arrow.swiper-button-next {
    -webkit-transform: translate(-10px, -35px);
    -ms-transform: translate(-10px, -35px);
    transform: translate(-10px, -35px);
    background-image: url(../images/icons/next-arrow.png);
}

.motivation-testimonial-slider-wrap .swiper-pagination-container .swiper-pagination-wrap .swiper-pagination-inner .custom-arrow.swiper-button-prev {
    -webkit-transform: translate(-10px, 15px);
    -ms-transform: translate(-10px, 15px);
    transform: translate(-10px, 15px);
    background-image: url(../images/icons/prev-arrow.png);
}

.about-me-video-wrapper {
    position: relative;
    z-index: 9;
}

.about-me-video-wrapper.about-us-one-video .shape {
    position: absolute;
    z-index: 1;
}

.about-me-video-wrapper.about-us-one-video .shape-1 {
    top: -46px;
    left: -99px;
    width: 166px;
    height: 166px;
}

.about-me-video-wrapper.about-us-one-video .shape-1 svg {
    width: 100%;
    height: 100%
}

.about-me-video-wrapper.about-us-one-video .shape-1 svg * {
    fill: #f1f1f1;
}

.about-me-video-wrapper.about-us-one-video .shape-2 {
    top: 8px;
    left: -65px;
}

.about-me-video-wrapper.about-us-one-video .shape-3 {
    z-index: 11;
    top: 0;
    right: 30px;
}

.about-me-video-wrapper.about-us-one-video .shape-3 span {
    display: block;
    width: 60px;
    height: 60px;
    text-indent: -9999px;
    border: 8px solid #ecc5ab;
    border-radius: 50%
}

.about-me-video-wrapper.about-us-one-video .shape-4 {
    right: 0;
    bottom: -60px;
}

@media only screen and (max-width:767px) {
    .about-me-video-wrapper.about-us-one-video .shape-1 {
        top: -90px;
        width: 120px;
        height: 130px;
    }

    .about-me-video-wrapper.about-us-one-video .shape-2 {
        width: 90px;
        height: 90px;
    }

    .about-me-video-wrapper.about-us-one-video .shape-3 {
        right: -20px;
    }

    .about-me-video-wrapper.about-us-one-video .shape-3 span {
        width: 45px;
        height: 45px;
        border-width: 6px;
    }

    .about-me-video-wrapper.about-us-one-video .shape-4 {
        width: 85px;
    }
}

.about-me-video-wrapper.about-us-one-video .about-me-video {
    max-width: 100%;
    margin-bottom: 0;
}

.about-me-video-wrapper .shape {
    position: absolute;
    z-index: 1;
}

.about-me-video-wrapper .shape-1 {
    top: -120px;
    left: 0;
    width: 226px;
    height: 226px;
}

.about-me-video-wrapper .shape-1 svg {
    width: 100%;
    height: 100%
}

.about-me-video-wrapper .shape-1 svg * {
    fill: #e5c791;
}

.about-me-video-wrapper .shape-2 {
    top: 0;
    left: 20px;
}

.about-me-video-wrapper .shape-3 {
    z-index: 11;
    top: 40px;
    right: 0;
}

.about-me-video-wrapper .shape-3 span {
    display: block;
    width: 90px;
    height: 90px;
    text-indent: -9999px;
    border: 12px solid #ecc5ab;
    border-radius: 50%
}

.about-me-video-wrapper .shape-4 {
    right: 20px;
    bottom: -45px;
}

@media only screen and (max-width:767px) {
    .about-me-video-wrapper .shape-1 {
        top: -90px;
        width: 120px;
        height: 130px;
    }

    .about-me-video-wrapper .shape-2 {
        width: 90px;
        height: 90px;
    }

    .about-me-video-wrapper .shape-3 {
        right: 0;
    }

    .about-me-video-wrapper .shape-3 span {
        width: 45px;
        height: 45px;
        border-width: 6px;
    }

    .about-me-video-wrapper .shape-4 {
        width: 85px;
    }
}

.about-me-video {
    position: relative;
    z-index: 9;
    display: block;
    overflow: hidden;
    width: 100%;
    max-width: 970px;
    margin: auto;
    margin-bottom: -140px;
    border-radius: 5px;
}

.about-me-video.success-story-video {
    max-width: 100%;
    -webkit-box-shadow: 0 130px 50px -100px rgba(51, 51, 51, .22);
    box-shadow: 0 130px 50px -100px rgba(51, 51, 51, .22);
}

.about-me-video .image {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.about-me-video .icon {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.about-me-video:hover .image {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.hero-image-section {
    margin-bottom: 240px;
}

@media only screen and (max-width:767px) {
    .hero-image-section {
        margin-bottom: 0;
    }
}

.hero-image-wrapper {
    position: relative;
    z-index: 9;
    text-align: center;
    display: block;
    overflow: hidden;
    width: 100%;
    max-width: 970px;
    margin: auto;
    margin-bottom: -240px;
    border-radius: 5px;
}

@media only screen and (max-width:767px) {
    .hero-image-wrapper {
        margin-bottom: 0;
    }
}

.h6-intro-video {
    border: 20px solid #fff;
    border-radius: 20px;
    -webkit-box-shadow: 0 2px 45px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 45px 0 rgba(0, 0, 0, .15);
    margin-bottom: 0;
}

.course {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    background-color: #fff;
}

.course .thumbnail {
    position: relative;
}

.course .thumbnail .badge {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    height: 37px;
    padding: 12px 10px 10px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    background: #ee7455;
}

.course .thumbnail .badge::before {
    position: absolute;
    top: 0;
    right: 100%;
    width: 0;
    height: 0;
    content: "";
    border-top: 19px solid #ee7455;
    border-bottom: 19px solid #ee7455;
    border-left: 13px solid transparent;
}

.course .thumbnail .image {
    display: block;
}

.course .thumbnail .image img {
    width: 100%
}

.course .info {
    padding: 30px;
}

.course .info .price {
    font-size: 24px;
    font-weight: 800;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 0 12px;
    color: #076fcc;
}

.course .info .price span {
    font-size: 18px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.course .info .title {
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
}

.course .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.course .info .meta li {
    margin-right: 28px;
}

.course .info .meta li:last-child {
    margin-right: 0;
}

.course .info .meta li i {
    margin-right: 10px;
}

.course.box-shadow:hover {
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, .08);
    box-shadow: 0 0 30px rgba(0, 0, 0, .08);
}

.course:hover {
    -webkit-box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
    box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
}

.course-2 {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    background-color: #faf8f6;
}

.course-2 .thumbnail {
    position: relative;
    overflow: hidden;
}

.course-2 .thumbnail .image {
    display: block;
}

.course-2 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.course-2 .info {
    position: relative;
    padding: 30px 30px 40px;
}

.course-2 .info .price {
    font-size: 24px;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    border-radius: 50%;
    background-color: #076fcc;
}

.course-2 .info .date {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin: 0 0 4px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.course-2 .info .title {
    font-size: 24px;
    line-height: 1.5;
    margin: 0;
    color: #3f3a64;
}

.course-2 .info .desc {
    margin-top: 10px;
}

.course-2 .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.course-2 .info .meta li {
    margin-right: 28px;
}

.course-2 .info .meta li:last-child {
    margin-right: 0;
}

.course-2 .info .meta li i {
    margin-right: 10px;
}

.course-2:hover {
    background-color: #fff;
    -webkit-box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
    box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
}

.course-2:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.course-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 100%;
    padding: 20px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    background-color: #fff;
}

.course-3 .thumbnail {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 170px;
    height: 170px;
    margin: 0 30px 0 0;
    border-radius: 50%
}

.course-3 .thumbnail .image {
    display: block;
}

.course-3 .thumbnail .image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.course-3 .info {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding: 0;
}

.course-3 .info .price {
    font-size: 24px;
    font-weight: 800;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 0 12px;
    color: #076fcc;
}

.course-3 .info .price span {
    font-size: 18px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.course-3 .info .title {
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
}

.course-3 .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.course-3 .info .meta li {
    margin-right: 28px;
}

.course-3 .info .meta li:last-child {
    margin-right: 0;
}

.course-3 .info .meta li i {
    margin-right: 10px;
}

.course-3:hover {
    -webkit-box-shadow: 0 7px 40px rgba(0, 0, 0, .05);
    box-shadow: 0 7px 40px rgba(0, 0, 0, .05);
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .course-3 .thumbnail {
        width: 120px;
        height: 120px;
    }
}

@media only screen and (max-width:575px) {
    .course-3 .thumbnail {
        width: 110px;
        height: 110px;
        margin-right: 20px;
    }
}

@media only screen and (max-width:479px) {
    .course-3 .thumbnail {
        width: 90px;
        height: 90px;
    }

    .course-3 .info .price {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .course-3 .info .price span {
        font-size: 16px;
    }

    .course-3 .info .title {
        font-size: 16px;
    }

    .course-3 .info .meta {
        font-size: 12px;
    }

    .course-3 .info .meta li {
        margin-right: 15px;
    }

    .course-3 .info .meta li i {
        margin-right: 6px;
    }
}

.course-4 {
    position: relative;
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    background-color: #faf8f6;
}

.course-4 .thumbnail {
    position: relative;
    overflow: hidden;
}

.course-4 .thumbnail .image {
    display: block;
}

.course-4 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.course-4 .info {
    padding: 30px 30px 40px;
}

.course-4 .info .price {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    border-radius: 0 5px;
    padding: 11px 16px;
    min-width: 86px;
    color: #fff;
    background-color: #076fcc;
}

.course-4 .info .price span {
    font-size: 15px;
}

.course-4 .info .course-instructor {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px;
}

.course-4 .info .course-instructor .instructor-avatar {
    margin: 0 10px 0 0;
}

.course-4 .info .course-instructor .instructor-avatar img {
    width: 32px;
    vertical-align: middle;
    border-radius: 50%
}

.course-4 .info .title {
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
    color: #3f3a64;
}

.course-4 .info .desc {
    margin-top: 10px;
}

.course-4 .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.course-4 .info .meta li {
    margin-right: 28px;
}

.course-4 .info .meta li:last-child {
    margin-right: 0;
}

.course-4 .info .meta li i {
    margin-right: 10px;
}

.course-4:hover {
    background-color: #fff;
    -webkit-box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
    box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
}

.course-4:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.course-4.dark-mode {
    position: relative;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: transparent;
}

.course-4.dark-mode .thumbnail-wrap {
    position: relative;
    border: 2px solid rgba(151, 151, 151, .3);
    border-radius: 5px 5px 0 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    overflow: hidden;
}

.course-4.dark-mode .thumbnail-wrap .thumbnail {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.course-4.dark-mode .info {
    position: relative;
    padding: 21px 30px 36px;
    border: 1px solid rgba(151, 151, 151, .3);
    border-top: 0;
    border-radius: 0 0 5px 5px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.course-4.dark-mode .info .price {
    top: auto;
    right: auto;
    bottom: 100%;
    left: -1px;
    border-radius: 0 5px 0 0;
}

.course-4.dark-mode .info .price span {
    font-size: 15px;
}

.course-4.dark-mode .info .course-instructor {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px;
}

.course-4.dark-mode .info .course-instructor .instructor-avatar {
    margin: 0 10px 0 0;
}

.course-4.dark-mode .info .course-instructor .instructor-avatar img {
    width: 32px;
    vertical-align: middle;
    border-radius: 50%
}

.course-4.dark-mode .info .course-instructor .instructor-name {
    color: rgba(255, 255, 255, .8);
}

.course-4.dark-mode .info .title {
    color: #fff;
}

.course-4.dark-mode .info .desc {
    margin-top: 10px;
}

.course-4.dark-mode .info .meta li {
    color: #fff;
}

.course-4.dark-mode:hover {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.course-4.dark-mode:hover .thumbnail-wrap {
    border-color: #076fcc;
}

.course-4.dark-mode:hover .thumbnail-wrap .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.course-5 {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    background-color: #fff;
}

.course-5 .thumbnail {
    position: relative;
    overflow: hidden;
}

.course-5 .thumbnail .image {
    display: block;
}

.course-5 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.course-5 .info {
    padding: 16px 0 10px;
}

.course-5 .info .title {
    font-size: 18px;
    line-height: 1.78;
    font-weight: 500;
    color: #333;
    margin: 0;
}

.course-5 .info .title a {
    display: block;
}

.course-5 .info .title a:hover {
    color: #3a7e69;
}

.course-5 .info .price {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 15px 0 0;
    color: #3a7e69;
}

.course-5 .info .price span {
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.course-5:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.course-6 {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.course-6 .thumbnail {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.course-6 .thumbnail .image {
    display: block;
}

.course-6 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.course-6 .thumbnail .image .course-overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.course-6 .thumbnail .image .course-overlay-bg::after,
.course-6 .thumbnail .image .course-overlay-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(43%, transparent), to(#000));
    background: -webkit-linear-gradient(top, transparent 43%, #000 100%);
    background: -o-linear-gradient(top, transparent 43%, #000 100%);
    background: linear-gradient(180deg, transparent 43%, #000 100%);
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.course-6 .thumbnail .image .course-overlay-bg::after {
    opacity: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000));
    background: -webkit-linear-gradient(top, transparent 0, #000 100%);
    background: -o-linear-gradient(top, transparent 0, #000 100%);
    background: linear-gradient(180deg, transparent 0, #000 100%);
}

.course-6 .info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px 30px 35px;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .course-6 .info {
        padding: 30px 20px 35px;
    }
}

.course-6 .info .course-caption-main {
    -webkit-transform: translateY(-100%) translateY(-60px);
    -ms-transform: translateY(-100%) translateY(-60px);
    transform: translateY(-100%) translateY(-60px);
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.course-6 .info .price {
    font-size: 24px;
    font-weight: 800;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 0 12px;
    color: #076fcc;
}

.course-6 .info .price span {
    font-size: 18px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.course-6 .info .title {
    font-size: 24px;
    line-height: 1.34;
    color: #fff;
    margin: 0;
}

.course-6 .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 0;
    color: #fff;
    list-style: none;
}

.course-6 .info .meta li {
    margin-right: 28px;
}

.course-6 .info .meta li:last-child {
    margin-right: 0;
}

.course-6 .info .meta li i {
    margin-right: 10px;
}

.course-6 .info .desc {
    margin: 9px 0 0;
}

.course-6 .info .desc p {
    line-height: 1.6;
    color: #fff;
}

.course-6:hover .thumbnail .image img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.course-6:hover .thumbnail .image .course-overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.course-6:hover .thumbnail .image .course-overlay-bg::before {
    opacity: 0;
}

.course-6:hover .thumbnail .image .course-overlay-bg::after {
    opacity: 1;
}

.course-6:hover .info {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.course-6:hover .info .course-caption-main {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.course7-animation-shape {
    position: relative;
    z-index: 9;
}

.course7-animation-shape .shape {
    position: absolute;
    z-index: -1;
}

.course7-animation-shape .shape-1 {
    top: 150px;
    right: 0;
    -webkit-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    transform: translate(50%, 0);
    width: 735px;
}

.course7-animation-shape .shape-1 svg {
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(21deg) scale(1, -1);
    -ms-transform: rotate(21deg) scale(1, -1);
    transform: rotate(21deg) scale(1, -1);
}

.course7-animation-shape .shape-1 svg * {
    fill: #fefaf7;
}

.course7-animation-shape .shape-2 {
    bottom: 15%;
    left: 0;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 728px;
}

.course7-animation-shape .shape-2 svg {
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-35deg) scale(-1, 1);
    -ms-transform: rotate(-35deg) scale(-1, 1);
    transform: rotate(-35deg) scale(-1, 1);
}

.course7-animation-shape .shape-2 svg * {
    fill: #ebf7fd;
}

.course7-animation-shape .shape-3 {
    z-index: -1;
    top: 0;
    right: 15px;
    -webkit-transform: rotate(90deg) translate(150%, 0);
    -ms-transform: rotate(90deg) translate(150%, 0);
    transform: rotate(90deg) translate(150%, 0);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
}

.course7-animation-shape .shape-3 .title {
    font-size: 180px;
    line-height: .9;
    -webkit-text-stroke-color: #f4ebe2;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .course7-animation-shape .shape {
        display: none;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .course7-animation-shape .shape {
        display: none;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .course7-animation-shape .shape {
        display: none;
    }
}

@media only screen and (max-width:767px) {
    .course7-animation-shape .shape {
        display: none;
    }
}

.course-7 {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    background-color: #fff;
}

.course-7.course-fluid .info {
    padding: 26px 0 20px;
    max-width: 100%;
    border: 0;
}

.course-7.course-fluid:hover .info {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.course-7 .thumbnail {
    position: relative;
    overflow: hidden;
}

.course-7 .thumbnail .image {
    display: block;
}

.course-7 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.course-7 .info {
    max-width: calc(100% - 30px);
    padding: 24px 25px 28px;
    border: 2px solid transparent;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.course-7 .info .price {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 0 12px;
    color: #ef6f31;
}

.course-7 .info .price span {
    font-size: 18px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.course-7 .info .title {
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
}

.course-7 .info .title a:hover {
    color: #ef6f31;
}

.course-7 .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.course-7 .info .meta li {
    margin-right: 28px;
}

.course-7 .info .meta li:last-child {
    margin-right: 0;
}

.course-7 .info .meta li i {
    margin-right: 10px;
}

.course-7:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.course-7:hover .info {
    border-color: #ef6f31;
    background: #fff;
    -webkit-box-shadow: 0 2px 26px rgba(0, 0, 0, .08);
    box-shadow: 0 2px 26px rgba(0, 0, 0, .08);
    -webkit-transform: translate(0, -41px);
    -ms-transform: translate(0, -41px);
    transform: translate(0, -41px);
}

.course-nav-tab ul {
    position: relative;
    margin-bottom: 50px;
    font-size: 0;
    border: 0;
    padding-bottom: 2px;
    border-bottom: 2px solid #eee;
}

.course-nav-tab ul li a {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    padding: 9px 25px;
    color: #ababab;
}

@media only screen and (max-width:767px) {
    .course-nav-tab ul li a {
        padding: 9px 18px;
    }
}

@media only screen and (max-width:479px) {
    .course-nav-tab ul li a {
        padding: 9px 14px;
        font-size: 15px;
        font-weight: 600;
    }
}

.course-nav-tab ul li a::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #076fcc;
    opacity: 0;
    z-index: 1;
}

.course-nav-tab ul li a:hover {
    color: #333;
}

.course-nav-tab ul li a.active {
    color: #333;
}

.course-nav-tab ul li a.active::after {
    opacity: 1;
}

.course-overview .title {
    font-size: 30px;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 18px;
}

@media only screen and (max-width:479px) {
    .course-overview .title {
        font-size: 26px;
    }
}

.course-overview p {
    margin-bottom: 25px;
}

.course-overview p:last-child {
    margin-bottom: 0;
}

.course-overview ol {
    margin-top: 15px;
    padding-left: 30px;
}

.course-overview ol li+li {
    margin-top: 10px;
}

.overview-course-video {
    margin: 45px 0 42px;
    border-radius: 5px;
}

@media only screen and (max-width:479px) {
    .overview-course-video {
        margin: 20px 0 20px;
    }
}

.overview-course-video iframe {
    width: 100%;
    height: 435px;
    border: 0;
}

@media only screen and (max-width:479px) {
    .overview-course-video iframe {
        height: 170px;
    }
}

.course-curriculum .curriculum-sections {
    list-style: none;
    padding-left: 0;
}

.course-curriculum .curriculum-sections .single-curriculum-section {
    border: 1px solid #dedede;
    border-radius: 5px;
    overflow: hidden;
}

.course-curriculum .curriculum-sections .single-curriculum-section+.single-curriculum-section {
    margin-top: 50px;
}

.section-header .section-left {
    padding: 22px 48px;
}

@media only screen and (max-width:767px) {
    .section-header .section-left {
        padding: 20px 15px;
    }
}

.section-header .section-left .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0;
}

.section-header .section-left .section-desc {
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
}

ul.section-content {
    list-style: none;
    padding-left: 0;
}

ul.section-content .course-item {
    position: relative;
    font-size: 14px;
    -webkit-transition: padding-left linear .15s;
    -o-transition: padding-left linear .15s;
    transition: padding-left linear .15s;
    background: #fff;
}

ul.section-content .course-item:nth-child(2n+1) {
    background-color: #f8f8f8;
}

ul.section-content .course-item .section-item-link {
    padding: 0 30px 0 48px;
    min-height: 56px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width:767px) {
    ul.section-content .course-item .section-item-link {
        padding: 0 15px 0 15px;
    }
}

@media only screen and (max-width:479px) {
    ul.section-content .course-item .section-item-link {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

ul.section-content .course-item .section-item-link.lesson::before {
    content: '\f15c'
}

ul.section-content .course-item .section-item-link::before {
    content: '\f017';
    font-size: 14px;
    font-family: "Font Awesome 5 Pro";
    width: 20px;
    padding: 10px 0;
}

ul.section-content .course-item .section-item-link .item-name {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 10px 10px 10px 0;
}

ul.section-content .course-item .section-item-link .course-item-meta {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    padding: 10px 0;
    text-align: right;
}

ul.section-content .course-item .section-item-link .course-item-meta .item-meta {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    height: 28px;
    line-height: 28px;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    padding: 0 15px;
}

ul.section-content .course-item .section-item-link .course-item-meta .item-meta.duration {
    color: #de7e5b;
    background: #f4ebe7;
}

ul.section-content .course-item .section-item-link .course-item-meta .item-meta.count-questions {
    color: #2dbbc4;
    background: #e3f1f2;
}

ul.section-content .course-item .section-item-link .course-item-meta .item-meta-icon {
    height: 28px;
    line-height: 28px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
}

.course-rating .title {
    font-size: 24px;
    padding: 13px 0 26px;
    margin-bottom: 0;
}

.course-rating-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width:479px) {
    .course-rating-content {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.course-rating-content .average-rating {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 70px;
    width: 200px;
}

@media only screen and (max-width:767px) {
    .course-rating-content .average-rating {
        margin-right: 30px;
    }
}

@media only screen and (max-width:479px) {
    .course-rating-content .average-rating {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%
    }
}

.course-rating-content .average-rating .rating-title {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 15px;
}

.course-rating-content .average-rating .rating-box {
    background: #fff;
    -webkit-box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    text-align: center;
    padding: 34px 30px 38px;
}

.course-rating-content .average-rating .rating-box .average-value {
    font-size: 56px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 8px;
    color: #076fcc;
}

.course-rating-content .average-rating .rating-box .review-star {
    text-align: center;
}

.course-rating-content .average-rating .rating-box .review-star .tm-star-rating {
    font-size: 14px;
}

.course-rating-content .average-rating .rating-box .review-star .tm-star-rating .fas {
    color: #f6b500;
}

.course-rating-content .average-rating .rating-box .review-amount {
    margin: 2px 0 0;
}

.course-rating-content .detailed-rating {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.course-rating-content .detailed-rating .rating-title {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 42px;
}

@media only screen and (max-width:479px) {
    .course-rating-content .detailed-rating .rating-title {
        margin-bottom: 15px;
    }
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-point {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 22px 0 0;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-point .tm-star-rating {
    font-size: 12px;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-point .tm-star-rating .fas {
    color: #f6b500;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-point .tm-star-rating .far {
    color: #ababab;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-progress {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-progress .single-progress-bar .progress {
    height: 6px;
    border-radius: 2px;
    background: #eee;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-progress .single-progress-bar .progress .progress-bar {
    background-color: #076fcc;
}

.course-rating-content .detailed-rating .rating-box .rating-rated-item .rating-count {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 15px;
    font-weight: 500;
    color: #ababab;
    min-width: 25px;
    margin: 0 0 0 5px;
    text-align: right;
}

.course-reviews-list {
    list-style: none;
    padding-left: 0;
    margin-top: 50px;
}

.course-reviews-list li {
    list-style-type: none;
    margin-bottom: 30px;
    padding: 20px;
    padding-bottom: 28px;
    padding-left: 5px;
    border: 0;
    border-bottom: 1px solid #eee;
}

@media only screen and (max-width:767px) {
    .course-reviews-list li {
        padding: 0 15px;
        padding-bottom: 28px;
    }
}

.course-reviews-list li+li {
    margin: 10px 0 0;
}

.course-reviews-list li .review-container .review-author {
    width: 80px;
    float: left;
    margin-right: 20px;
}

@media only screen and (max-width:479px) {
    .course-reviews-list li .review-container .review-author {
        width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.course-reviews-list li .review-container .review-author img {
    border-radius: 50%
}

.course-reviews-list li .review-container .review-content {
    position: relative;
    overflow: hidden;
    margin-left: 125px;
}

@media only screen and (max-width:767px) {
    .course-reviews-list li .review-container .review-content {
        margin-left: 110px;
    }
}

@media only screen and (max-width:479px) {
    .course-reviews-list li .review-container .review-content {
        margin-left: 0;
    }
}

.course-reviews-list li .review-container .review-content .title {
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.course-reviews-list li .review-container .review-content .review-stars-rated {
    position: absolute;
    top: 0;
    right: 0;
}

.course-reviews-list li .review-container .review-content .review-stars-rated .review-stars {
    width: 120px;
    height: 24px;
    font-size: 14px;
    text-align: right;
    color: #f2b827;
}

.course-reviews-list li .review-container .review-content .review-stars-rated .review-stars::before {
    content: '\f005\f005\f005\f005\f005';
    line-height: 1;
    vertical-align: top;
    white-space: nowrap;
    font-family: "Font Awesome 5 Pro"
}

.course-reviews-list li .review-container .review-content .review-title {
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    margin: 15px 0 5px;
}

.lp-course-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 40px;
    gap: 13px;
}

@media only screen and (max-width:767px) {
    .lp-course-buttons {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        display:grid
    }

    .lp-course-buttons>a {
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

.membership-price-table {
    margin-bottom: 0;
}

.membership-price-table thead tr th {
    padding: 30px 10px;
    text-align: center;
    border-bottom-width: 1px;
    border-top: 1px solid #f3f3f3;
}

.membership-price-table thead tr th .price {
    font-size: 36px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
    color: #076fcc;
}

.membership-price-table thead tr th .title {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin: 0;
    letter-spacing: 1.38px;
    text-transform: uppercase;
}

.membership-price-table thead tr th .desc {
    font-weight: 400;
    line-height: 1.3;
    margin: 10px 0 0;
}

.membership-price-table tbody tr td {
    border-color: #f3f3f3;
    padding: 10px 20px;
}

.membership-price-table tbody tr td.check {
    font-size: 16px;
    color: #7ed321;
}

.membership-price-table tbody tr td.none {
    font-size: 16px;
    color: #d85554;
}

.membership-price-table tbody tr td:not(.desc) {
    text-align: center;
}

.membership-price-table tfoot tr td {
    padding: 30px 10px;
    text-align: center;
    border-color: #f3f3f3;
}

.pricing {
    padding: 30px;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, .1);
    box-shadow: 0 0 40px rgba(0, 0, 0, .1);
}

.pricing .pricing-header .price {
    font-size: 36px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
    color: #076fcc;
}

.pricing .pricing-header .title {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin: 0;
    letter-spacing: 1.38px;
    text-transform: uppercase;
}

.pricing .pricing-header .desc {
    font-weight: 400;
    line-height: 1.3;
    margin: 10px 0 0;
}

.pricing .pricing-body ul {
    margin: 30px 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid #eee;
}

.pricing .pricing-body ul li {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.event {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    background-color: #faf8f6;
}

.event.primary-three .thumbnail .event-overlay-background {
    background-color: rgba(106, 197, 241, .6);
}

.event.primary-three .thumbnail .event-overlay-content .btn {
    color: #ef6f31 !important;
}

.event.primary-three .info .date {
    color: #ef6f31;
}

.event.primary-three .info .title {
    color: #3f3a64;
}

.event.primary-three .info .title a:hover {
    color: #ef6f31;
}

.event.primary-three .info .event-location {
    font-size: 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.event.primary-three .info .event-location li {
    margin-right: 28px;
}

.event.primary-three .info .event-location li:last-child {
    margin-right: 0;
}

.event.primary-three .info .event-location li i {
    margin-right: 10px;
}

.event .thumbnail {
    position: relative;
    overflow: hidden;
}

.event .thumbnail .image {
    display: block;
}

.event .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.event .thumbnail .event-overlay-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(63, 58, 100, .6);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.event .thumbnail .event-overlay-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    left: 0;
    text-align: center;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.event .info {
    position: relative;
    text-align: center;
    padding: 30px 30px 40px;
}

.event .info .date {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin: 0 0 4px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #076fcc;
}

.event .info .title {
    font-size: 20px;
    line-height: 1.5;
    margin: 0;
    color: #3f3a64;
}

.event .info .event-location {
    font-size: 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.event .info .event-location li {
    margin-right: 28px;
}

.event .info .event-location li:last-child {
    margin-right: 0;
}

.event .info .event-location li i {
    margin-right: 10px;
}

.event:hover {
    background-color: #fff;
    -webkit-box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
    box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
}

.event:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.event:hover .thumbnail .event-overlay-background {
    opacity: 1;
    visibility: visible;
}

.event:hover .thumbnail .event-overlay-content {
    opacity: 1;
    visibility: visible;
}

.single-event-classic {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .single-event-classic {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:767px) {
    .single-event-classic {
        margin-bottom: 30px;
    }
}

.single-event-classic .event-image {
    position: relative;
    overflow: hidden;
}

.single-event-classic .event-image .image {
    display: block;
}

.single-event-classic .event-image .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.single-event-classic .event-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width:479px) {
    .single-event-classic .event-info {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.single-event-classic .event-info .event-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: -50px 26px 0 20px;
    position: relative;
    z-index: 1;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100px;
    width: 100px;
    background: #f1f4f2;
    margin-right: 26px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.single-event-classic .event-info .event-date .event-date-day {
    font-size: 48px;
    line-height: .8;
    font-family: "Playfair Display", serif;
    font-style: italic;
    font-weight: 700;
    color: #3a7e69;
    margin-bottom: 15px;
}

.single-event-classic .event-info .event-date .event-date-month {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;
    color: #3f3a64;
}

.single-event-classic .event-info .event-caption {
    padding: 20px 0 0;
}

@media only screen and (max-width:479px) {
    .single-event-classic .event-info .event-caption {
        padding-left: 20px;
    }
}

.single-event-classic .event-info .event-caption .title {
    font-size: 23px;
    line-height: 1.5;
    font-weight: 500;
    color: #3f3a64;
    margin: 0;
}

.single-event-classic .event-info .event-caption .title a:hover {
    color: #3a7e69;
}

.single-event-classic .event-info .event-caption .event-location {
    margin: 14px 0 0;
}

.single-event-classic .event-info .event-caption .event-location i {
    margin-right: 5px;
}

.single-event-classic:hover .event-image .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.event-list-box {
    position: relative;
    display: block;
    background: #f8f8f8;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.event-list-box::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
    background: #076fcc;
    opacity: 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.event-list-box .event-caption {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 31px;
}

@media only screen and (max-width:479px) {
    .event-list-box .event-caption {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.event-list-box .event-caption .left-box {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 30px;
}

@media only screen and (max-width:479px) {
    .event-list-box .event-caption .left-box {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.event-list-box .event-caption .left-box .event-location {
    font-size: 14px;
    color: #7e7e7e;
    margin: 0 0 7px;
}

.event-list-box .event-caption .left-box .event-location i {
    margin-right: 5px;
}

.event-list-box .event-caption .left-box .title {
    font-size: 20px;
    line-height: 1.5;
    margin: 0;
}

.event-list-box .event-caption .right-box {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.event-list-box .event-caption .right-box .event-date {
    text-align: center;
    margin: 0 0 10px;
}

@media only screen and (max-width:479px) {
    .event-list-box .event-caption .right-box .event-date {
        text-align: left;
    }
}

.event-list-box .event-caption .right-box .event-date .event-date-day {
    font-size: 48px;
    font-weight: 400;
    line-height: 1;
    color: #076fcc;
}

.event-list-box .event-caption .right-box .event-date .event-date-month {
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #333;
}

.event-list-box:hover {
    background: #fff;
    -webkit-box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.event-list-box:hover::before {
    opacity: 1;
}

.single-event-classic-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.single-event-classic-list .event-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 36.333333%;
    flex: 0 0 36.333333%;
    max-width: 36.333333%;
    overflow: hidden;
}

@media only screen and (max-width:479px) {
    .single-event-classic-list .event-image {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

.single-event-classic-list .event-image .image {
    display: block;
    overflow: hidden;
}

.single-event-classic-list .event-image .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.single-event-classic-list .event-image .event-date {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #3a7e69;
    background: #f1f4f2;
    padding: 4px 0 1px;
    text-align: center;
}

.single-event-classic-list .event-info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 63.666667%;
    flex: 0 0 63.666667%;
    max-width: 63.666667%;
    padding-left: 30px;
}

@media only screen and (max-width:479px) {
    .single-event-classic-list .event-info {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 20px;
    }
}

.single-event-classic-list .event-info .title {
    font-size: 18px;
    line-height: 1.78;
    font-weight: 500;
    color: #3f3a64;
    margin: 0;
}

.single-event-classic-list .event-info .title a:hover {
    color: #3a7e69;
}

.single-event-classic-list .event-info .event-location {
    font-size: 14px;
    color: #7e7e7e;
    margin-top: 14px;
}

.single-event-classic-list .event-info .event-location i {
    margin-right: 5px;
}

.single-event-classic-list:hover .event-image .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.event-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.event-list.right-style {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.event-list.right-style .info {
    padding: 0 45px 0 0;
    text-align: right;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .event-list.right-style .info {
        padding: 0 20px 0 0;
    }
}

@media only screen and (max-width:767px) {
    .event-list.right-style .info {
        padding: 0 20px 0 0;
    }
}

.event-list .thumbnail {
    position: relative;
    overflow: hidden;
}

.event-list .thumbnail .image {
    display: block;
}

.event-list .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.event-list .info {
    padding: 0 0 0 45px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .event-list .info {
        padding: 0 0 0 20px;
    }
}

@media only screen and (max-width:767px) {
    .event-list .info {
        padding: 0 0 0 20px;
    }
}

.event-list .info .date {
    display: block;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 0 6px;
}

.event-list .info .title {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 700;
    margin: 0;
}

.event-list .info .title a:hover {
    color: #ef6f31;
}

.event-list .info .event-location {
    padding-left: 0;
    list-style: none;
    margin: 14px 0 0;
    font-size: 14px;
}

.event-list .info .event-location i {
    margin-right: 5px;
}

.event-list:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.zoom-event {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    background-color: #faf8f6;
}

.zoom-event .thumbnail {
    position: relative;
    overflow: hidden;
}

.zoom-event .thumbnail .image {
    display: block;
}

.zoom-event .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.zoom-event .info {
    position: relative;
    padding: 30px 30px 40px;
}

.zoom-event .info .title {
    font-size: 20px;
    line-height: 1.5;
    margin: 0;
    color: #3f3a64;
}

.zoom-event .info .zoom-id {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 7px 0 0;
}

.zoom-event .info .zoom-id .label {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.73px;
    color: #9b9b9b;
}

.zoom-event .info .zoom-id .value {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2.13px;
}

.zoom-event .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.zoom-event .info .meta li {
    margin-right: 28px;
}

.zoom-event .info .meta li:last-child {
    margin-right: 0;
}

.zoom-event .info .meta li .time {
    font-weight: 600;
}

.zoom-event .info .meta li i {
    margin-right: 10px;
}

.zoom-event:hover {
    background-color: #fff;
    -webkit-box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
    box-shadow: 0 14px 59px rgba(0, 0, 0, .12);
}

.zoom-event:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.zoom-event-details-wrapper .image {
    margin-bottom: 30px;
}

.zoom-event-details-wrapper .image img {
    border-radius: 5px;
}

.zoom-event-details-wrapper .content .title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 18px;
}

@media only screen and (max-width:767px) {
    .zoom-event-details-wrapper .content .title {
        font-size: 26px;
    }
}

.zoom-event-details-wrapper .content ol {
    margin-top: 15px;
    padding-left: 30px;
}

.zoom-event-details-wrapper .content ol:last-child {
    margin-bottom: 0;
}

.zoom-event-details-wrapper .content ol li+li {
    margin-top: 10px;
}

.zoom-event-details-wrapper .content ol li ul {
    margin-top: 15px;
    padding-left: 20px;
}

.zoom-event-details-wrapper .content ol li ul li+li {
    margin-top: 10px;
}

.pro-countdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: -5px;
    width: calc(100% + 10px);
}

.single-countdown-box {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    font-size: 13px;
    font-weight: 500;
    color: #777491;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 5px;
    padding: 13px 3px 11px;
    text-align: center;
}

.single-countdown-box>span {
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
    color: #076fcc;
    margin: 3px 0 0;
    display: block;
}

.event-hero-content {
    text-align: center;
}

.event-hero-content .entry-date {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #fff;
    margin: 0 0 28px;
}

.event-hero-content .entry-title {
    font-size: 56px;
    line-height: 1.15;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .event-hero-content .entry-title {
        font-size: 48px;
    }
}

@media only screen and (max-width:767px) {
    .event-hero-content .entry-title {
        font-size: 38px;
    }
}

.entry-countdown {
    max-width: 470px;
    margin: 54px auto 0;
}

@media only screen and (max-width:479px) {
    .entry-countdown {
        margin-top: 30px;
        max-width: 100%
    }
}

.pro-countdown-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.single-countdown-box-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    padding: 10px;
}

@media only screen and (max-width:479px) {
    .single-countdown-box-1 {
        padding: 5px;
    }
}

.single-countdown-box-1>span {
    font-size: 56px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    display: block;
    margin-bottom: 10px;
}

@media only screen and (max-width:479px) {
    .single-countdown-box-1>span {
        font-size: 40px;
    }
}

.event-entry-meta {
    margin: 0 -12px;
    text-align: center;
    margin-top: 20px;
}

.event-entry-meta .meta-item {
    display: inline-block;
    position: relative;
    padding: 0 12px;
    margin-bottom: 5px;
}

.event-entry-meta .meta-item i {
    color: #076fcc;
    margin-right: 5px;
}

.event-map {
    width: 100%;
    height: 390px;
    border: 0;
}

.entry-location-address {
    text-align: center;
    margin-top: 20px;
}

.entry-location-address .fal {
    color: #076fcc;
}

.event-content {
    padding-bottom: 90px;
    border-bottom: 1px solid #e7e7e7;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .event-content {
        padding-bottom: 70px;
    }
}

@media only screen and (max-width:767px) {
    .event-content {
        padding-bottom: 50px;
    }
}

.event-speakers-description {
    font-size: 18px;
    line-height: 1.67;
    width: 630px;
    max-width: 100%;
    text-align: center;
    margin: 54px auto 0;
}

.mx-timeline .col-md-6 {
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (max-width:767px) {
    .mx-timeline .col-md-6 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width:479px) {
    .mx-timeline .col-md-6 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.mx-timeline .mx-timeline-list {
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 32px 0 65px;
}

.mx-timeline .item {
    position: relative;
}

@media only screen and (max-width:767px) {
    .mx-timeline .item {
        padding-left: 45px;
        width: 100%
    }
}

.mx-timeline .item+.item {
    margin-top: 68px;
}

@media only screen and (max-width:767px) {
    .mx-timeline .item+.item {
        margin-top: 30px;
    }
}

@media only screen and (max-width:479px) {
    .mx-timeline .item+.item {
        margin-top: 30px;
    }
}

.mx-timeline .line {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    height: 100%;
    border-left: 2px solid #e2e2e8;
}

@media only screen and (max-width:767px) {
    .mx-timeline .line {
        left: 15px;
    }
}

.mx-timeline .dots {
    position: absolute;
    top: 2px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 30px;
    height: 30px;
    color: #076fcc;
}

@media only screen and (max-width:767px) {
    .mx-timeline .dots {
        right: auto;
        left: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
}

.mx-timeline .dots::before {
    content: '';
    position: absolute;
    border: 1px solid currentColor;
    border-radius: 50%;
    top: 0;
    left: 0;
    opacity: .3;
    width: 30px;
    height: 30px;
}

.mx-timeline .dots .middle-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    z-index: 2;
    border: 3px solid currentColor;
    background: #fff;
}

.mx-timeline .timeline-col .inner {
    text-align: right;
    width: 500px;
    max-width: 100%
}

@media only screen and (max-width:767px) {
    .mx-timeline .timeline-col .inner {
        text-align: left;
    }
}

.mx-timeline .timeline-col .inner .title-wrap .title {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #076fcc;
    margin-bottom: 56px;
}

@media only screen and (max-width:767px) {
    .mx-timeline .timeline-col .inner .title-wrap .title {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:479px) {
    .mx-timeline .timeline-col .inner .title-wrap .title {
        margin-bottom: 30px;
    }
}

.mx-timeline .timeline-col .inner .timeline-image img {
    border-radius: 5px;
}

.mx-timeline .timeline-info .inner {
    padding-top: 130px;
    float: right;
    width: 500px;
    max-width: 100%;
    text-align: left;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .mx-timeline .timeline-info .inner {
        padding-top: 60px;
    }
}

@media only screen and (max-width:767px) {
    .mx-timeline .timeline-info .inner {
        padding-top: 30px;
    }
}

.mx-timeline .timeline-info .timeline-content-body {
    display: inline-block;
    width: 500px;
    max-width: 100%
}

.mx-timeline .timeline-info .title {
    font-size: 24px;
    margin-bottom: 22px;
}

.mx-timeline .timeline-info p {
    font-size: 18px;
    line-height: 2;
}

@media only screen and (max-width:767px) {
    .mx-timeline .timeline-info p {
        font-size: 16px;
    }
}

.mx-timeline .item:nth-child(2n+1) .timeline-feature {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}


.mx-timeline .item:nth-child(2n+1) .timeline-info>.inner {
    float: left;
    text-align: right;
}



.mx-timeline .item:nth-child(2n+1) .timeline-feature>.inner {
    text-align: left;
    float: right;
}

.mx-timeline .item:nth-child(2n+1) .timeline-feature>.inner {
    text-align: left;
    float: right;
}

.justified-gallery {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.single-gallery {
    display: block;
    position: relative;
    overflow: hidden;
}

.single-gallery .thumbnail {
    position: relative;
}

.single-gallery .thumbnail::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    opacity: 0;
    background-color: rgba(0, 0, 0, .5);
}

.single-gallery .thumbnail img {
    width: 100%
}

.single-gallery .content {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 20px;
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    text-align: center;
    opacity: 0;
}

.single-gallery .content .title {
    font-size: 34px;
    line-height: 1.42;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0;
}

.single-gallery .content .description p {
    color: #fff;
    margin-bottom: 0;
}

.single-gallery:hover .thumbnail::before {
    opacity: 1;
}

.single-gallery:hover .content {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
}

.about-content {
    position: relative;
    z-index: 99;
    max-width: 420px;
}

.about-content.max-width-470 {
    max-width: 470px;
}

.about-content.max-width-470 p {
    font-size: 18px;
    line-height: 1.67;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-content {
        max-width: 100%
    }
}

@media only screen and (max-width:767px) {
    .about-content {
        max-width: 100%
    }
}

.about-content .sub-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 2;
    display: block;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.about-content .title {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 15px;
    color: #3f3a64;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-content .title {
        font-size: 42px;
        line-height: 50px;
    }
}

@media only screen and (max-width:767px) {
    .about-content .title {
        font-size: 36px;
        line-height: 46px;
    }
}

.about-content .title span {
    font-weight: 400;
    color: #076fcc;
}

.about-content p {
    margin-bottom: 20px;
}

.about-content a {
    font-size: 15px;
}

.about-image {
    position: relative;
    z-index: 9;
}

.about-image .about-image-one img {
    border-radius: 5px;
}

.about-image .about-image-two {
    position: absolute;
    top: -90px;
    right: -73px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .about-image .about-image-two {
        right: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .about-image .about-image-two {
        right: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-image .about-image-two {
        right: 0;
    }
}

@media only screen and (max-width:767px) {
    .about-image .about-image-two {
        right: 0;
    }
}

.about-image .about-image-two img {
    border-radius: 5px;
}

.about-image .shape {
    position: absolute;
    z-index: 1;
}

.about-image .shape-1 {
    z-index: 11;
    top: -51px;
    left: -107px;
}

.about-image .shape-1 span {
    display: block;
    width: 60px;
    height: 60px;
    text-indent: -9999px;
    border: 7px solid #ecc5ab;
    border-radius: 50%
}

.about-image .shape-2 {
    left: -40px;
    bottom: -26px;
    z-index: -1;
}

.about-image .shape-3 {
    bottom: -40px;
    right: 10px;
}

.about-image .shape-4 {
    top: -27px;
    left: 360px;
    z-index: -1;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-image .shape {
        position: absolute;
        z-index: -1;
    }

    .about-image .shape-1 {
        z-index: -1;
        top: -51px;
        left: 0;
    }

    .about-image .shape-2 {
        max-width: 80px;
        width: 80px;
        left: 0;
    }

    .about-image .shape-3 {
        max-width: 80px;
        width: 80px;
    }

    .about-image .shape-4 {
        max-width: 80px;
        width: 80px;
    }
}

@media only screen and (max-width:767px) {
    .about-image .shape {
        position: absolute;
        z-index: -1;
    }

    .about-image .shape-1 {
        z-index: -1;
        top: -51px;
        left: 0;
    }

    .about-image .shape-1 span {
        width: 45px;
        height: 45px;
    }

    .about-image .shape-2 {
        max-width: 80px;
        width: 80px;
        left: 0;
    }

    .about-image .shape-3 {
        max-width: 80px;
        width: 80px;
    }

    .about-image .shape-4 {
        max-width: 80px;
        width: 80px;
    }
}

.maxcoach-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 35px;
}

.maxcoach-list.color-light li {
    color: #fff;
}

.maxcoach-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.maxcoach-list li+li {
    margin-top: 10px;
}

.maxcoach-list li .icon {
    margin-right: 19px;
    font-size: 15px;
    color: #076fcc;
}

.register-image {
    position: relative;
    z-index: 9;
    text-align: center;
}

.register-image .shape {
    position: absolute;
    z-index: 1;
}

.register-image .shape-1 {
    top: 80px;
    left: 14%;
    z-index: -1;
    width: 446px;
    height: 446px;
    -webkit-transform: rotate(0) scale(1, 1);
    -ms-transform: rotate(0) scale(1, 1);
    transform: rotate(0) scale(1, 1);
}

.register-image .shape-1 svg {
    width: 100%;
    height: 100%
}

.register-image .shape-1 svg * {
    fill: #ece8e4;
}

.register-image .shape-2 {
    bottom: 0;
    right: 15%;
    z-index: -11;
}

@media only screen and (max-width:767px) {
    .register-image .shape {
        position: absolute;
        z-index: 1;
    }

    .register-image .shape-1 {
        top: 80px;
        left: 14%;
        z-index: -1;
        width: 200px;
        height: 200px;
        -webkit-transform: rotate(0) scale(1, 1);
        -ms-transform: rotate(0) scale(1, 1);
        transform: rotate(0) scale(1, 1);
    }

    .register-image .shape-1 svg {
        width: 100%;
        height: 100%
    }

    .register-image .shape-1 svg * {
        fill: #ece8e4;
    }

    .register-image .shape-2 {
        width: 80px;
        bottom: 20px;
        right: 15%;
        z-index: -11;
    }
}

.download-app-image {
    position: relative;
    text-align: center;
    z-index: 9;
}

.download-app-image .shape {
    position: absolute;
    z-index: 1;
}

.download-app-image .shape-1 {
    bottom: 0;
    left: 20px;
    z-index: -1;
    width: 476px;
    height: 476px;
    -webkit-transform: rotate(0) scale(1, 1);
    -ms-transform: rotate(0) scale(1, 1);
    transform: rotate(0) scale(1, 1);
}

.download-app-image .shape-1 svg {
    width: 100%;
    height: 100%
}

.download-app-image .shape-1 svg * {
    fill: #ece8e4;
}

.download-app-image .shape-2 {
    bottom: 40%;
    left: -150px;
    z-index: -1;
}

.download-app-image .shape-3 {
    z-index: 11;
    top: 0;
    right: 20px;
}

.download-app-image .shape-3 span {
    display: block;
    width: 62px;
    height: 62px;
    text-indent: -9999px;
    border: 8px solid #ecc5ab;
    border-radius: 50%
}

.download-app-image .shape-4 {
    right: 15%;
    bottom: 0;
    z-index: -1;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .download-app-image .shape {
        position: absolute;
        z-index: -1;
    }

    .download-app-image .shape-2 {
        bottom: 40%;
        left: 0;
        z-index: -1;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .download-app-image .shape {
        position: absolute;
        z-index: -1;
    }

    .download-app-image .shape-2 {
        bottom: 40%;
        left: 0;
        z-index: -1;
    }
}

@media only screen and (max-width:767px) {
    .download-app-image .shape {
        position: absolute;
        z-index: 1;
    }

    .download-app-image .shape-1 {
        bottom: 10px;
        left: 20px;
        z-index: -1;
        width: 200px;
        height: 200px;
        -webkit-transform: rotate(0) scale(1, 1);
        -ms-transform: rotate(0) scale(1, 1);
        transform: rotate(0) scale(1, 1);
    }

    .download-app-image .shape-1 svg {
        width: 100%;
        height: 100%
    }

    .download-app-image .shape-1 svg * {
        fill: #ece8e4;
    }

    .download-app-image .shape-2 {
        bottom: 40%;
        left: 0;
        z-index: -1;
    }

    .download-app-image .shape-3 {
        z-index: 11;
        top: 0;
        right: 20px;
    }

    .download-app-image .shape-3 span {
        display: block;
        width: 62px;
        height: 62px;
        text-indent: -9999px;
        border: 8px solid #ecc5ab;
        border-radius: 50%
    }

    .download-app-image .shape-4 {
        right: 30px;
        bottom: 20px;
        width: 80px;
        z-index: -1;
    }
}

.learn-content {
    padding-left: 70px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .learn-content {
        padding-left: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .learn-content {
        padding-left: 0;
    }
}

@media only screen and (max-width:767px) {
    .learn-content {
        padding-left: 0;
    }
}

.learn-image {
    position: relative;
    z-index: 9;
    text-align: center;
}

@media only screen and (max-width:767px) {
    .learn-image {
        margin-bottom: 50px;
    }
}

.learn-image .shape {
    position: absolute;
    z-index: -1;
}

.learn-image .shape-1 {
    top: -48px;
    left: 0;
}

.learn-image .shape-2 {
    top: 120px;
    right: 60px;
}

.learn-image .shape-2 span {
    display: block;
    width: 62px;
    height: 62px;
    text-indent: -9999px;
    border: 8px solid #ecc5ab;
    border-radius: 50%
}

.learn-image .shape-3 {
    right: 0;
    bottom: 0;
}

.about-app-content {
    padding: 100px 0;
    max-width: 455px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-app-content {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px) {
    .about-app-content {
        padding-top: 0;
        padding-bottom: 60px;
    }
}

.about-app-content .sub-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 2;
    display: block;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
}

.about-app-content .sub-title span {
    color: #076fcc;
}

.about-app-content .title {
    font-size: 48px;
    line-height: 1.17;
    margin-bottom: 15px;
    color: #fff;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .about-app-content .title {
        font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-app-content .title {
        font-size: 40px;
    }
}

@media only screen and (max-width:767px) {
    .about-app-content .title {
        font-size: 36px;
    }
}

.about-app-content .title span {
    font-weight: 400;
    color: #076fcc;
}

.about-app-content p {
    font-size: 18px;
    line-height: 1.67;
    color: #fff;
    max-width: 426px;
    margin-bottom: 20px;
}

.about-app-image {
    text-align: right;
    margin: -75px -35px -52px 0;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .about-app-image {
        margin: -75px -10px -52px 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .about-app-image {
        margin: -50px 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-app-image {
        margin: -50px 0;
    }
}

@media only screen and (max-width:767px) {
    .about-app-image {
        margin: -50px 0 40px;
    }
}

.cooking-about-section {
    position: relative;
    z-index: 9;
}

.cooking-about-section .shape {
    position: absolute;
    z-index: 1;
}

.cooking-about-section .shape-1 {
    top: 300px;
    left: -30px;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .cooking-about-section .shape-1 {
        left: 0;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .cooking-about-section .shape-1 {
        left: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cooking-about-section .shape-1 {
        left: 0;
    }
}

@media only screen and (max-width:767px) {
    .cooking-about-section .shape {
        display: none;
    }
}

.cooking-about-slider .swiper-pagination {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    width: auto;
    margin-top: 45px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cooking-about-slider .swiper-pagination {
        margin-top: 20px;
    }
}

@media only screen and (max-width:767px) {
    .cooking-about-slider .swiper-pagination {
        margin-top: 20px;
    }
}

.cooking-about-slider .swiper-pagination .swiper-pagination-bullet {
    position: relative;
    width: 32px;
    height: 32px;
    margin: 0 4px;
    color: #333;
}

.cooking-about-slider .swiper-pagination .swiper-pagination-bullet::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background: currentColor;
    z-index: 1;
}

.cooking-about-slider .swiper-pagination .swiper-pagination-bullet::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    opacity: 0;
    border: 1px solid transparent;
    border-radius: 50%
}

.cooking-about-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    border-color: currentColor;
    opacity: .5;
    width: 100%;
    height: 100%
}

.cooking-about-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px 0 95px;
    height: 100%
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .cooking-about-area {
        padding: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cooking-about-area {
        padding: 0;
    }
}

@media only screen and (max-width:767px) {
    .cooking-about-area {
        padding: 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.cooking-about-area .slide-image {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    max-width: 43%;
    position: relative;
    margin: 0 0 20px 20px;
}

@media only screen and (max-width:767px) {
    .cooking-about-area .slide-image {
        max-width: 100%
    }
}

.cooking-about-area .slide-image .image-frame {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    color: #bad2de;
    -webkit-transform: translate(-20px, 20px);
    -ms-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
}

.cooking-about-area .slide-image .image-pattern {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 1;
}

.cooking-about-area .slide-content {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
}

@media only screen and (max-width:767px) {
    .cooking-about-area .slide-content {
        margin-top: 30px;
    }
}

.cooking-about-area .slide-content .slide-decorate-text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 150px;
    line-height: 1;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    color: rgba(106, 197, 241, .15);
    z-index: 0;
    -webkit-transform: translate(64px, -22px);
    -ms-transform: translate(64px, -22px);
    transform: translate(64px, -22px);
}

.cooking-about-area .slide-content .slide-layers {
    position: relative;
    z-index: 1;
    max-width: 500px;
    padding: 0 0 0 30px;
}

@media only screen and (max-width:767px) {
    .cooking-about-area .slide-content .slide-layers {
        max-width: 100%;
        padding: 0;
    }
}

.cooking-about-area .slide-content .slide-layers .title {
    font-size: 40px;
    line-height: 1.2;
    margin: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cooking-about-area .slide-content .slide-layers .title {
        font-size: 32px;
    }
}

@media only screen and (max-width:767px) {
    .cooking-about-area .slide-content .slide-layers .title {
        font-size: 32px;
    }
}

@media only screen and (max-width:479px) {
    .cooking-about-area .slide-content .slide-layers .title {
        font-size: 28px;
    }
}

.cooking-about-area .slide-content .slide-layers p {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.67;
    margin-top: 32px;
    margin-bottom: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cooking-about-area .slide-content .slide-layers p {
        margin-top: 15px;
    }
}

@media only screen and (max-width:767px) {
    .cooking-about-area .slide-content .slide-layers p {
        margin-top: 15px;
        font-size: 15px;
        line-height: 1.78;
    }
}

.cooking-about-area .slide-content .slide-layers .btn {
    margin-top: 45px;
}

@media only screen and (max-width:767px) {
    .cooking-about-area .slide-content .slide-layers .btn {
        margin-top: 20px;
    }
}

.about-me-left-content {
    max-width: 430px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-me-left-content {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:767px) {
    .about-me-left-content {
        max-width: 100%;
        margin-bottom: 30px;
    }
}

.about-me-left-content .sub-title {
    display: block;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2;
    letter-spacing: 2px;
    color: #076fcc;
    margin-bottom: 18px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-me-left-content .sub-title {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:767px) {
    .about-me-left-content .sub-title {
        margin-bottom: 10px;
    }
}

.about-me-left-content .title {
    font-family: "Playfair Display", serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-me-left-content .title {
        font-size: 34px;
    }
}

@media only screen and (max-width:767px) {
    .about-me-left-content .title {
        font-size: 28px;
    }
}

.about-me-right-content .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.78;
}

.about-me-right-content .title mark {
    color: #076fcc;
    background-color: transparent;
}

.about-me-right-content p {
    font-size: 15px;
    line-height: 1.6;
    color: #696969;
    margin-top: 15px;
    margin-bottom: 0;
}

.single-service {
    margin: 0 0 30px;
    padding: 35px 24px 202px;
    background-color: #fff;
    background-position: 5px 100% !important;
    background-repeat: no-repeat !important;
    background-size: auto !important;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px 0 rgba(51, 51, 51, .1);
}

.single-service.service-bg-center {
    margin: 70px 0 30px;
    background-position: bottom center !important;
}

@media only screen and (max-width:575px) {
    .single-service.service-bg-center {
        margin-top: 0;
    }
}

.single-service.service-bg-top {
    padding: 215px 24px 22px;
    background-position: 0 15px !important;
}

.single-service.service-bg-top-center {
    padding: 215px 24px 22px;
    background-position: 50% 33px !important;
}

.single-service .service-content .title {
    font-size: 20px;
    line-height: 1.5;
    color: #3f3a64;
    margin-bottom: 0;
}

.single-service .service-content p {
    margin-top: 5px;
}

.service {
    display: block;
    height: 100%;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 30px rgba(51, 51, 51, .1);
    box-shadow: 0 10px 30px rgba(51, 51, 51, .1);
    margin-bottom: 30px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.service .image {
    position: relative;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.service .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.service .content {
    position: relative;
    text-align: center;
    padding: 30px 35px 86px;
}

.service .content .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.34;
    margin-bottom: 0;
}

.service .content p {
    margin-top: 10px;
    color: #696969;
    margin-bottom: 0;
}

.service .content .button-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    z-index: 9;
}

.service .content .button-wrap::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    background: #076fcc;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.service .content .button-wrap .link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    height: 56px;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #076fcc;
    border-radius: 0 0 5px 5px;
    text-align: center;
    border-top: 1px solid #eee;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.service .content .button-wrap .link i {
    margin-left: 10px;
}

.service:hover .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.service:hover .content .button-wrap::after {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.service:hover .content .button-wrap .link {
    color: #fff;
}

.service-image-section {
    background-color: transparent;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(rgba(234, 237, 241, .8)));
    background-image: -webkit-linear-gradient(top, #fff 0, rgba(234, 237, 241, .8) 100%);
    background-image: -o-linear-gradient(top, #fff 0, rgba(234, 237, 241, .8) 100%);
    background-image: linear-gradient(180deg, #fff 0, rgba(234, 237, 241, .8) 100%);
}

.service-image {
    margin-bottom: -30px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .service-image {
        margin-bottom: 0;
    }
}

@media only screen and (max-width:767px) {
    .service-image {
        margin-bottom: 0;
    }
}

.contact-title {
    margin-bottom: 40px;
}

.contact-title.max-width-740 {
    max-width: 740px;
    margin: 0 auto;
    text-align: center;
}

.contact-title.max-width-600 {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 60px;
}

@media only screen and (max-width:767px) {
    .contact-title.max-width-600 {
        margin-bottom: 30px;
    }
}

.contact-title .sub-title {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    font-family: Gilroy;
    color: #333;
    margin-bottom: 10px;
}

.contact-title .title {
    font-size: 34px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 0;
}

@media only screen and (max-width:479px) {
    .contact-title .title {
        font-size: 26px;
    }
}

.contact-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.contact-info .icon {
    margin-right: 20px;
    font-size: 32px;
    color: #076fcc;
}

.contact-info .icon i {
    vertical-align: top;
}

.contact-info .info .title {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 15px;
}

.contact-info .info .info-text {
    display: block;
    line-height: 32px;
}

.contact-info .info .info-text strong {
    color: #333;
}

.contact-image {
    position: relative;
    z-index: 9;
    text-align: center;
}

.contact-image .shape {
    position: absolute;
    z-index: 1;
}

.contact-image .shape-1 {
    left: 1px;
    top: 124px;
    z-index: -1;
}

.contact-image .shape-2 {
    left: 1px;
    top: 262px;
    z-index: -1;
}

.contact-image .shape-3 {
    z-index: 11;
    top: 355px;
    left: -8px;
}

.contact-image .shape-3 span {
    display: block;
    width: 62px;
    height: 62px;
    text-indent: -9999px;
    border: 8px solid #ecc5ab;
    border-radius: 50%
}

.contact-image .shape-4 {
    bottom: 55px;
    right: 45px;
    z-index: -1;
}

.contact-image .shape-5 {
    bottom: 314px;
    right: 70px;
    z-index: 1;
}

@media only screen and (max-width:767px) {
    .contact-image .shape {
        position: absolute;
        z-index: 1;
    }

    .contact-image .shape-1 {
        width: 100px;
        max-width: 100px;
        left: 1px;
        top: 124px;
        z-index: -1;
    }

    .contact-image .shape-2 {
        width: 100px;
        max-width: 100px;
        left: 1px;
        top: 262px;
        z-index: -1;
    }

    .contact-image .shape-3 {
        z-index: 11;
        top: 355px;
        left: -8px;
    }

    .contact-image .shape-3 span {
        display: block;
        width: 42px;
        height: 42px;
        text-indent: -9999px;
        border: 6px solid #ecc5ab;
        border-radius: 50%
    }

    .contact-image .shape-4 {
        width: 100px;
        max-width: 100px;
        bottom: 55px;
        right: 45px;
        z-index: -1;
    }

    .contact-image .shape-5 {
        width: 60px;
        max-width: 60px;
        bottom: 314px;
        right: 30px;
        z-index: 1;
    }
}

@media only screen and (max-width:479px) {
    .contact-image .shape {
        position: absolute;
        z-index: 1;
    }

    .contact-image .shape-1 {
        width: 80px;
        max-width: 80px;
        left: 1px;
        top: 0;
        z-index: -1;
    }

    .contact-image .shape-2 {
        width: 80px;
        max-width: 80px;
        left: 1px;
        top: 70px;
        z-index: -1;
    }

    .contact-image .shape-3 {
        z-index: 11;
        top: 255px;
        left: 8px;
    }

    .contact-image .shape-3 span {
        display: block;
        width: 42px;
        height: 42px;
        text-indent: -9999px;
        border: 6px solid #ecc5ab;
        border-radius: 50%
    }

    .contact-image .shape-4 {
        width: 100px;
        max-width: 100px;
        bottom: 15px;
        right: 20px;
        z-index: -1;
    }

    .contact-image .shape-5 {
        width: 60px;
        max-width: 60px;
        bottom: 140px;
        right: 10px;
        z-index: 1;
    }
}

.contact-image>img {
    /* border-radius: 50% */
}

#htmap2 {
    width: 100%;
    height: 400px;
}

@media only screen and (max-width:767px) {
    #htmap2 {
        height: 300px;
    }
}

.gmap-marker-wrap {
    padding: 20px;
}

.gmap-marker-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
}

.gmap-marker-content {
    font-size: 14px;
}

.gmap-marker-content i {
    font-size: 15px;
    margin-right: 5px;
    color: #076fcc;
}

.gm-style .gm-style-iw {
    -webkit-box-shadow: 0 30px 30px rgba(0, 0, 0, .12);
    box-shadow: 0 30px 30px rgba(0, 0, 0, .12);
}

.animated-dot {
    width: 100%;
    height: 100%;
    left: -9px;
    top: -5px;
    position: relative;
    background-color: #076fcc;
}

.animated-dot .middle-dot {
    width: 12px;
    height: 12px;
    border-radius: 30px;
    position: absolute;
    left: 2px;
    top: 2px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-color: #076fcc;
}

.animated-dot .middle-dot::after {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    background-color: #fff;
    border-radius: 30px;
    z-index: 100;
    opacity: 0;
    -webkit-animation: animationSignal2 cubic-bezier(0, .55, .55, 1) 2s;
    animation: animationSignal2 cubic-bezier(0, .55, .55, 1) 2s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: .78s;
    animation-delay: .78s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animated-dot .signal {
    width: 180px;
    pointer-events: none;
    height: 180px;
    border-radius: 200px;
    position: absolute;
    left: -80px;
    top: -80px;
    opacity: 0;
    -webkit-animation: animationSignal cubic-bezier(0, .55, .55, 1) 2s;
    animation: animationSignal cubic-bezier(0, .55, .55, 1) 2s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: .78s;
    animation-delay: .78s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-color: #076fcc;
}

.animated-dot .signal2 {
    width: 180px;
    height: 180px;
    pointer-events: none;
    border-radius: 200px;
    position: absolute;
    left: -80px;
    top: -80px;
    opacity: 0;
    -webkit-animation: animationSignal cubic-bezier(0, .55, .55, 1) 2s;
    animation: animationSignal cubic-bezier(0, .55, .55, 1) 2s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-color: #076fcc;
}

@-webkit-keyframes animationSignal {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    1% {
        opacity: .25;
    }

    20% {
        opacity: .25;
    }

    60% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes animationSignal2 {
    0% {
        opacity: 0;
    }

    1% {
        opacity: .2;
    }

    20% {
        opacity: .17;
    }

    60% {
        opacity: 0;
    }
}

@keyframes animationSignal {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    1% {
        opacity: .25;
    }

    20% {
        opacity: .25;
    }

    60% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes animationSignalTwo {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    1% {
        opacity: .25;
    }

    20% {
        opacity: .25;
    }

    60% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: 0;
    }
}

@keyframes animationSignalTwo {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    1% {
        opacity: .25;
    }

    20% {
        opacity: .25;
    }

    60% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: 0;
    }
}

@keyframes animationSignal2 {
    0% {
        opacity: 0;
    }

    1% {
        opacity: .2;
    }

    20% {
        opacity: .17;
    }

    60% {
        opacity: 0;
    }
}

.privacy-policy-wrapper .image,
.purchase-guide-wrapper .image,
.terms-of-service-wrapper .image {
    margin-bottom: 40px;
}

.privacy-policy-wrapper .content .title,
.purchase-guide-wrapper .content .title,
.terms-of-service-wrapper .content .title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 18px;
}

@media only screen and (max-width:479px) {

    .privacy-policy-wrapper .content .title,
    .purchase-guide-wrapper .content .title,
    .terms-of-service-wrapper .content .title {
        font-size: 28px;
        margin-bottom: 15px;
    }
}

.privacy-policy-wrapper .content h4,
.purchase-guide-wrapper .content h4,
.terms-of-service-wrapper .content h4 {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 18px;
}

.privacy-policy-wrapper .content p,
.purchase-guide-wrapper .content p,
.terms-of-service-wrapper .content p {
    margin-bottom: 25px;
}

.privacy-policy-wrapper .content p:last-child,
.purchase-guide-wrapper .content p:last-child,
.terms-of-service-wrapper .content p:last-child {
    margin-bottom: 0;
}

.privacy-policy-wrapper .content ul,
.purchase-guide-wrapper .content ul,
.terms-of-service-wrapper .content ul {
    padding-left: 20px;
    margin-top: 15px;
}

.privacy-policy-wrapper .content ul li+li,
.purchase-guide-wrapper .content ul li+li,
.terms-of-service-wrapper .content ul li+li {
    margin-top: 10px;
}

.privacy-policy-wrapper .content ol,
.purchase-guide-wrapper .content ol,
.terms-of-service-wrapper .content ol {
    padding-left: 30px;
    margin-top: 15px;
}

.privacy-policy-wrapper .content ol li+li,
.purchase-guide-wrapper .content ol li+li,
.terms-of-service-wrapper .content ol li+li {
    margin-top: 10px;
}

.terms-of-service-wrapper .content ol {
    margin-bottom: 0;
}

@media only screen and (max-width:767px) {
    .profile-image {
        margin-bottom: 30px;
    }
}

.profile-info .profile-name {
    font-size: 34px;
    line-height: 1.42;
    margin: 0;
}

.profile-info h5 {
    font-size: 24px;
}

.profile-info .author-bio {
    margin-top: 15px;
    margin-bottom: 0;
}

.profile-info .profile-contact-title {
    font-size: 18px;
    margin: 36px 0 10px;
}

.profile-info .contact-info-text .email {
    margin-top: 10px;
}

.profile-info .contact-info-text strong {
    color: #333;
}

.author-social-networks {
    margin-top: 20px;
    list-style: none;
    padding-left: 0;
}

.author-social-networks li {
    display: inline-block;
    margin: 10px 10px 0 0;
}

.author-social-networks li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #ededed;
    border-radius: 50%;
    text-align: center;
}

.author-social-networks li a:hover {
    background-color: #076fcc;
    border-color: transparent;
    color: #fff;
}

.learn-press-profile-nav {
    border-bottom: 2px solid #eee;
    margin-bottom: 15px;
}

.learn-press-profile-nav li a {
    position: relative;
    padding: 3px 18px;
    color: #ababab;
    display: block;
    font-size: 24px;
    font-weight: 700;
}

@media only screen and (max-width:575px) {
    .learn-press-profile-nav li a {
        font-size: 18px;
        padding: 3px 10px;
    }
}

.learn-press-profile-nav li a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: #076fcc;
    opacity: 0;
}

.learn-press-profile-nav li a:hover {
    color: #333;
}

.learn-press-profile-nav li a.active {
    color: #333;
}

.learn-press-profile-nav li a.active::after {
    opacity: 1;
}

.profile-progress-status {
    margin-top: 80px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .profile-progress-status {
        margin-top: 60px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .profile-progress-status {
        margin-top: 40px;
    }
}

@media only screen and (max-width:767px) {
    .profile-progress-status {
        margin-top: 30px;
    }
}

.status-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 12px 10px 15px 30px;
    margin: 0 0 30px;
    -webkit-box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.status-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
    background: #111;
}

.status-box .status-number {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    color: #076fcc;
    margin: 0 26px 0 0;
}

.status-box .status-text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.56;
    margin: 0;
    max-width: 130px;
}

.status-box.success::before {
    background: #8fd6ca;
}

.status-box.success .status-number {
    color: #076fcc;
}

.status-box.warning::before {
    background: #f5dbaf;
}

.status-box.warning .status-number {
    color: #ebb860;
}

.status-box.info::before {
    background: #b8c3f3;
}

.status-box.info .status-number {
    color: #7288e8;
}

.status-box.error::before {
    background: #efadb5;
}

.status-box.error .status-number {
    color: #df5b6c;
}

.profile-courses-progress {
    margin-top: 35px;
}

.profile-courses-progress .title {
    font-size: 34px;
    line-height: 1.42;
    margin-bottom: 33px;
}

.maxcoach-progress {
    max-width: 570px;
}

.progress-charts {
    overflow: hidden;
}

.progress-charts .title {
    font-size: 18px;
    font-weight: 600;
    color: #3f3a64;
    margin: 0 0 16px;
}

.progress-charts .progress {
    height: 32px;
    border-radius: 30px;
    background: #f2f2f2;
    padding: 6px;
}

.progress-charts .progress .progress-bar {
    overflow: visible;
    position: relative;
    background: #076fcc;
    height: 100%;
    border-radius: inherit;
}

.progress-charts .progress .progress-bar span.percent-label {
    position: absolute;
    color: #fff;
    right: 15px;
    top: 1px;
    font-size: 12px;
    font-weight: 700;
}

.lp-tab-menu {
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
}

.lp-tab-menu li {
    display: inline-block;
}

.lp-tab-menu li a {
    position: relative;
    padding: 8px 10px;
    line-height: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.lp-tab-menu li a::after {
    content: '';
    background-color: #076fcc;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    opacity: 0;
}

.lp-tab-menu li a:hover {
    color: #076fcc;
}

.lp-tab-menu li a.active {
    color: #076fcc;
}

.lp-tab-menu li a.active::after {
    opacity: 1;
}

.lp-sub-menu {
    margin: 20px 0 20px;
    text-align: center;
}

.lp-sub-menu li {
    display: inline-block;
    margin: 0 5px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .scroll {
        overflow-x: auto;
    }
}

@media only screen and (max-width:767px) {
    .scroll {
        overflow-x: auto;
    }
}

.lp-list-table {
    border: none;
    width: 100%;
    margin-bottom: 20px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .lp-list-table {
        width: 900px;
    }
}

@media only screen and (max-width:767px) {
    .lp-list-table {
        width: 800px;
    }
}

.lp-list-table thead tr th {
    background-color: #111;
    font-size: 16px;
    padding: 15px;
    line-height: 1;
    text-align: left;
    border-bottom: none;
    color: #fff;
    text-transform: capitalize;
}

.lp-list-table tbody tr td {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    background: #fff;
    padding: 10px 20px;
    border-color: #f3f3f3;
}

.lp-list-table tfoot tr td {
    border: 0;
    border-bottom: 1px solid #f3f3f3;
    padding: 10px 20px;
}

.lp-label {
    font-size: 12px;
    display: inline-block;
    color: #fff;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin-right: 6px;
    background: #111;
}

.lp-label.label-passed {
    background-color: #14c4ff;
}

.lp-label.label-failed {
    background-color: #ff4d66;
}

.lp-label.label-completed {
    background-color: #ffdc1e;
    color: #111;
}

.result-percent {
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    min-width: 40px;
    display: inline-block;
}

.blog {
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.blog .thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.blog .thumbnail .image {
    display: block;
}

.blog .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.blog .info {
    position: relative;
    padding: 30px 0;
}

.blog .info .category {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin: 0 0 4px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.blog .info .category:hover {
    color: #076fcc;
}

.blog .info .title {
    font-size: 24px;
    line-height: 1.5;
    margin: 0;
}

.blog .info .desc {
    margin-top: 10px;
}

.blog .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.blog .info .meta li {
    margin-right: 28px;
}

.blog .info .meta li:last-child {
    margin-right: 0;
}

.blog .info .meta li i {
    margin-right: 10px;
}

.blog:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.blog-2 {
    position: relative;
    overflow: hidden;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, .05);
    box-shadow: 0 20px 50px rgba(0, 0, 0, .05);
}

.blog-2.primary-three .info {
    position: relative;
    padding: 26px 20px 35px;
}

.blog-2.primary-three .info .category:hover {
    color: #ef6f31;
}

.blog-2.primary-three .info .title a:hover {
    color: #ef6f31;
}

.blog-2 .thumbnail {
    position: relative;
    overflow: hidden;
}

.blog-2 .thumbnail.overlay-thumb::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .3)));
    background-image: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, .3) 100%);
    background-image: -o-linear-gradient(top, transparent 0, rgba(0, 0, 0, .3) 100%);
    background-image: linear-gradient(-180deg, transparent 0, rgba(0, 0, 0, .3) 100%);
}

.blog-2 .thumbnail .image {
    display: block;
}

.blog-2 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.blog-2 .info {
    position: relative;
    padding: 25px 30px;
}

.blog-2 .info.content-absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 20px 38px 54px;
}

@media only screen and (max-width:767px) {
    .blog-2 .info.content-absolute {
        padding: 25px 30px;
    }
}

.blog-2 .info.content-absolute .category {
    color: #fff;
}

.blog-2 .info.content-absolute .category:hover {
    color: #fff;
}

.blog-2 .info.content-absolute .title {
    font-size: 34px;
    line-height: 1.42;
    color: #fff;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .blog-2 .info.content-absolute .title {
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
    }
}

@media only screen and (max-width:767px) {
    .blog-2 .info.content-absolute .title {
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
    }
}

.blog-2 .info.content-absolute .title a:hover {
    color: #fff;
}

.blog-2 .info.content-absolute .meta li {
    color: #fff;
}

.blog-2 .info .category {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin: 0 0 4px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.blog-2 .info .category:hover {
    color: #076fcc;
}

.blog-2 .info .title {
    font-size: 20px;
    line-height: 1.5;
    margin: 0;
}

.blog-2 .info .desc {
    margin-top: 10px;
}

.blog-2 .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.blog-2 .info .meta li {
    margin-right: 28px;
}

.blog-2 .info .meta li:last-child {
    margin-right: 0;
}

.blog-2 .info .meta li i {
    margin-right: 10px;
}

.blog-2:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.blog-2.health-blog {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}

.blog-2.health-blog .info {
    position: relative;
    padding: 21px 0 20px;
}

.blog-2.health-blog .info .category {
    font-size: 15px;
    font-weight: 600;
    display: block;
    margin: 0 0 4px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.blog-2.health-blog .info .category:hover {
    color: #3a7e69;
}

.blog-2.health-blog .info .title {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 600;
    color: #3f3a64;
    margin: 0;
}

.blog-2.health-blog .info p {
    margin: 6px 0 0;
}

.blog-2.motivation-blog {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}

.blog-2.motivation-blog .info {
    position: relative;
    padding: 24px 0 0;
}

.blog-2.motivation-blog .info .meta {
    margin-top: 0;
    margin-bottom: 10px;
}

.blog-2.motivation-blog .info .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
}

.blog-2.motivation-blog .info p {
    font-size: 15px;
    line-height: 1.6;
    margin-top: 15px;
}

.blog-shape-animation {
    position: relative;
    z-index: 9;
}

.blog-shape-animation .shape {
    position: absolute;
    z-index: 1;
}

.blog-shape-animation .shape-1 {
    z-index: 11;
    top: 140px;
    left: -60px;
}

.blog-shape-animation .shape-1 span {
    display: block;
    width: 90px;
    height: 90px;
    text-indent: -9999px;
    border: 12px solid #ecc5ab;
    border-radius: 50%
}

.blog-shape-animation .shape-2 {
    top: 220px;
    left: 260px;
    z-index: -1;
}

.blog-shape-animation .shape-3 {
    bottom: 50px;
    left: 60%;
    width: 226px;
    height: 226px;
    z-index: -1;
}

.blog-shape-animation .shape-3.secondary-three svg {
    width: 100%;
    height: 100%
}

.blog-shape-animation .shape-3.secondary-three svg * {
    fill: #b2e0f6;
}

.blog-shape-animation .shape-3 svg {
    width: 100%;
    height: 100%
}

.blog-shape-animation .shape-3 svg * {
    fill: #8fd6ca;
}

@media only screen and (min-width:1200px) and (max-width:1599px) {
    .blog-shape-animation .shape {
        position: absolute;
        z-index: 1;
    }

    .blog-shape-animation .shape-1 {
        z-index: 11;
        top: 140px;
        left: 60px;
    }

    .blog-shape-animation .shape-2 {
        top: 220px;
        left: 260px;
        z-index: -1;
    }

    .blog-shape-animation .shape-3 {
        z-index: -1;
        bottom: 50px;
        left: 60%
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .blog-shape-animation .shape {
        position: absolute;
        z-index: 1;
    }

    .blog-shape-animation .shape-1 {
        z-index: -1;
        top: 80px;
        left: 60px;
    }

    .blog-shape-animation .shape-2 {
        top: 220px;
        left: 260px;
        z-index: -1;
    }

    .blog-shape-animation .shape-3 {
        z-index: -1;
        bottom: 50px;
        left: 60%
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .blog-shape-animation .shape {
        position: absolute;
        z-index: 1;
    }

    .blog-shape-animation .shape-1 {
        z-index: -1;
        top: 80px;
        left: 60px;
    }

    .blog-shape-animation .shape-2 {
        top: 220px;
        left: 260px;
        z-index: -1;
    }

    .blog-shape-animation .shape-3 {
        z-index: -1;
        bottom: 30px;
        left: 60%
    }
}

@media only screen and (max-width:767px) {
    .blog-shape-animation .shape {
        position: absolute;
        z-index: 1;
    }

    .blog-shape-animation .shape-1 {
        z-index: -1;
        top: 80px;
        left: 60px;
    }

    .blog-shape-animation .shape-1 span {
        width: 60px;
        height: 60px;
        border: 7px solid #ecc5ab;
    }

    .blog-shape-animation .shape-2 {
        top: 220px;
        left: 260px;
        z-index: -1;
    }

    .blog-shape-animation .shape-3 {
        width: 120px;
        height: 120px;
        z-index: -1;
        bottom: 30px;
        left: 60%
    }
}

.blog-3 {
    height: 100%;
    margin-bottom: 50px;
    padding-bottom: 50px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-bottom: 1px solid #f3f3f3;
}

.blog-3 .thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.blog-3 .thumbnail .image {
    display: block;
}

.blog-3 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.blog-3 .info {
    position: relative;
    padding: 30px 0 0;
}

.blog-3 .info .category {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin: 0 0 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c89a2;
}

.blog-3 .info .category:hover {
    color: #076fcc;
}

.blog-3 .info .title {
    font-size: 34px;
    line-height: 1.42;
    margin: 0;
}

.blog-3 .info .meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: -28px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.blog-3 .info .meta li {
    margin-top: 5px;
    margin-right: 28px;
}

.blog-3 .info .meta li i {
    margin-right: 10px;
}

.blog-3 .info .meta li .avatar {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%
}

.blog-3 .info .meta li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-3 .info .desc {
    margin-top: 16px;
}

.blog-3 .info .desc .block-quote {
    border-left: 4px solid #076fcc;
    padding-left: 20px;
    margin: 50px 0 44px 48px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.78;
    max-width: 660px;
}

@media only screen and (max-width:767px) {
    .blog-3 .info .desc .block-quote {
        margin: 25px 0 20px 0;
    }
}

.blog-3 .info .entry-post-tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.blog-3 .info .entry-post-tags .tag-label {
    margin: 0 7px 0 0;
}

.blog-3 .info .post-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.blog-3 .info .post-share .label {
    font-weight: 500;
    margin-right: 15px;
}

.blog-3 .info .post-share .media {
    position: relative;
}

.blog-3 .info .post-share .media .icon {
    width: 52px;
    height: 52px;
    cursor: pointer;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    text-align: center;
    color: #076fcc;
    border: 2px solid #ededed;
    border-radius: 50%
}

.blog-3 .info .post-share .media .icon i {
    font-size: 18px;
    line-height: 48px;
}

.blog-3 .info .post-share .media .list {
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    width: auto;
    padding: 0 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
    white-space: nowrap;
    opacity: 0;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, .06);
    box-shadow: 0 2px 20px rgba(0, 0, 0, .06);
    -webkit-filter: drop-shadow(0 2px 20px rgba(0, 0, 0, .06));
    filter: drop-shadow(0 2px 20px rgba(0, 0, 0, .06));
}

.blog-3 .info .post-share .media .list::before {
    position: absolute;
    top: 100%;
    right: 20px;
    content: "";
    border-top: 8px solid #fff;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
}

.blog-3 .info .post-share .media .list a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 16px 12px;
    color: #ababab;
}

.blog-3 .info .post-share .media .list a:hover {
    color: #076fcc;
}

.blog-3 .info .post-share .media:hover .icon {
    color: #fff;
    border-color: transparent;
    background-color: #076fcc;
}

.blog-3 .info .post-share .media:hover .list {
    visibility: visible;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
}

.blog-3:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

@media only screen and (max-width:767px) {
    .blog-3 .info .title {
        font-size: 24px;
    }
}

@media only screen and (max-width:479px) {
    .blog-3 .post-share .label {
        display: none;
    }
}

.blog-list-post {
    margin: 0;
    padding: 0;
    list-style: none;
}

.blog-list-post li+li {
    margin: 20px 0 0;
}

.blog-list-post li a {
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.78;
    font-family: Gilroy;
    color: #3f3a64;
    padding-left: 30px;
}

.blog-list-post li a::after,
.blog-list-post li a::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 15px;
    line-height: 15px;
    position: absolute;
    top: 5px;
    left: 0;
    content: "\f30b";
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.blog-list-post li a::after {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    color: #076fcc;
}

.blog-list-post li a:hover::before {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
}

.blog-list-post li a:hover::after {
    visibility: visible;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.blog-4 {
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    margin-bottom: 30px;
}

.blog-4 .thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.blog-4 .thumbnail::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, rgba(51, 51, 51, 0)), color-stop(80%, #000));
    background-image: -webkit-linear-gradient(top, rgba(51, 51, 51, 0) 11%, #000 80%);
    background-image: -o-linear-gradient(top, rgba(51, 51, 51, 0) 11%, #000 80%);
    background-image: linear-gradient(180deg, rgba(51, 51, 51, 0) 11%, #000 80%);
    z-index: 9;
}

.blog-4 .thumbnail .image {
    display: block;
}

.blog-4 .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.blog-4 .thumbnail .post-overlay-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 20px 20px 15px;
}

@media only screen and (max-width:479px) {
    .blog-4 .thumbnail .post-overlay-content {
        padding: 20px 15px 15px;
    }
}

.blog-4 .thumbnail .post-overlay-content .meta {
    font-size: 14px;
    color: rgba(255, 255, 255, .8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: -28px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.blog-4 .thumbnail .post-overlay-content .meta li {
    margin-bottom: 5px;
    padding: 0 13px;
}

.blog-4 .thumbnail .post-overlay-content .meta li i {
    margin-right: 10px;
}

.blog-4 .thumbnail .post-overlay-content .meta li a {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.blog-4 .thumbnail .post-overlay-content .meta li a::after {
    content: '';
    width: 0;
    height: 1px;
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    -webkit-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
    -o-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
    transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
    background: #fff;
}

.blog-4 .thumbnail .post-overlay-content .meta li a:hover::after {
    width: 100%;
    left: 0;
    right: auto;
}

.blog-4 .info {
    position: relative;
    padding: 20px 0 0;
}

.blog-4 .info .title {
    font-size: 34px;
    line-height: 1.42;
    color: #fff;
    margin: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .blog-4 .info .title {
        font-size: 26px;
    }
}

@media only screen and (max-width:767px) {
    .blog-4 .info .title {
        font-size: 26px;
    }
}

@media only screen and (max-width:479px) {
    .blog-4 .info .title {
        font-size: 24px;
    }
}

.blog-4 .info .desc {
    margin-top: 10px;
    margin-bottom: 10px;
}

.blog-4 .info .desc p {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

.blog-4 .info .blog-btn {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    color: #076fcc;
}

.blog-4 .info .blog-btn i {
    margin-left: 14px;
    vertical-align: middle;
}

.blog-4:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.blog-list-post {
    margin: 0;
    padding: 0;
    list-style: none;
}

.blog-list-post li+li {
    margin: 20px 0 0;
}

.blog-list-post li a {
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.78;
    font-family: Gilroy;
    color: #3f3a64;
    padding-left: 30px;
}

.blog-list-post li a::after,
.blog-list-post li a::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 15px;
    line-height: 15px;
    position: absolute;
    top: 5px;
    left: 0;
    content: "\f30b";
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.blog-list-post li a::after {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    color: #076fcc;
}

.blog-list-post li a:hover::before {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
}

.blog-list-post li a:hover::after {
    visibility: visible;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.blog-list-wrap {
    border-left: 1px solid #272931;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .blog-list-wrap {
        border-left: 0;
    }
}

@media only screen and (max-width:767px) {
    .blog-list-wrap {
        border-left: 0;
    }
}

.blog-list-wrap div:first-child {
    padding-top: 0;
}

.blog-list-wrap div:last-child {
    border-bottom: 0;
}

.single-blog-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 0 20px 30px;
    border-bottom: 1px solid #272931;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .single-blog-list {
        padding-left: 0;
    }
}

@media only screen and (max-width:767px) {
    .single-blog-list {
        padding-left: 0;
    }
}

.single-blog-list .thumbnail {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 36.333333%;
    flex: 0 0 36.333333%;
    max-width: 36.333333%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

@media only screen and (max-width:479px) {
    .single-blog-list .thumbnail {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
}

.single-blog-list .thumbnail .image {
    display: block;
}

.single-blog-list .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.single-blog-list .info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 63.666667%;
    flex: 0 0 63.666667%;
    max-width: 63.666667%;
    padding-left: 30px;
}

@media only screen and (max-width:479px) {
    .single-blog-list .info {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
    }
}

.single-blog-list .info .meta {
    font-size: 14px;
    color: #7e7e7e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 10px;
    padding-left: 0;
    list-style: none;
}

.single-blog-list .info .meta li {
    margin: 5px 0 0;
    padding: 0 16px;
}

.single-blog-list .info .meta li:first-child {
    padding-left: 0;
}

.single-blog-list .info .meta li i {
    margin-right: 10px;
}

.single-blog-list .info .title {
    font-size: 24px;
    line-height: 1.5;
    color: #fff;
    margin: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .single-blog-list .info .title {
        font-size: 20px;
    }
}

@media only screen and (max-width:767px) {
    .single-blog-list .info .title {
        font-size: 20px;
    }
}

.single-blog-list:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.entry-author .author-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width:767px) {
    .entry-author .author-info {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.entry-author .author-avatar {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 170px;
    text-align: center;
}

@media only screen and (max-width:767px) {
    .entry-author .author-avatar {
        margin: 0 auto;
    }
}

.entry-author .author-avatar img {
    border-radius: 50%
}

@media only screen and (max-width:575px) {
    .entry-author .author-avatar {
        width: 120px;
    }
}

.entry-author .author-social-networks {
    margin-top: 0;
}

.entry-author .author-social-networks .inner {
    display: inline-block;
    margin: 22px -9px 0;
}

.entry-author .author-social-networks .inner a {
    display: block;
    float: left;
    padding: 6px 9px;
    font-size: 13px;
    line-height: 1;
    color: #333;
}

.entry-author .author-biographical-info {
    margin-top: 15px;
    font-size: 15px;
    color: #696969;
}

.blog-nav-links {
    margin-top: 35px;
}



@media only screen and (max-width:767px) {
    .blog-nav-links .nav-list {
        -ms-grid-columns: auto;
        grid-template-columns: auto;
    }
}

.blog-nav-links .nav-list .nav-item.next {
    text-align: right;
}

.blog-nav-links .nav-list .nav-item.next .inner a h6 {
    padding: 20px 50px 20px 30px;
}

.blog-nav-links .nav-list .nav-item.next .inner a h6::before {
    right: 20px;
    content: '\f105'
}

.blog-nav-links .nav-list .nav-item .inner a {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 120px;
    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    -webkit-box-shadow: 0 15px 50px rgba(51, 51, 51, .1);
    box-shadow: 0 15px 50px rgba(51, 51, 51, .1);
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.blog-nav-links .nav-list .nav-item .inner a .hover-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    opacity: 0;
}

.blog-nav-links .nav-list .nav-item .inner a .hover-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .5;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(51, 51, 51, 0)), color-stop(80%, #000));
    background-image: -webkit-linear-gradient(top, rgba(51, 51, 51, 0) 0, #000 80%);
    background-image: -o-linear-gradient(top, rgba(51, 51, 51, 0) 0, #000 80%);
    background-image: linear-gradient(-180deg, rgba(51, 51, 51, 0) 0, #000 80%);
    border-radius: 5px;
}

.blog-nav-links .nav-list .nav-item .inner a h6 {
    display: block;
    word-wrap: break-word;
    width: 100%;
    padding: 20px 30px 20px 50px;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.56;
    margin: 0;
}

.blog-nav-links .nav-list .nav-item .inner a h6::before {
    left: 20px;
    content: '\f104';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 24px;
    font-family: "Font Awesome 5 Pro";
    color: #076fcc;
}

.blog-nav-links .nav-list .nav-item .inner a:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.blog-nav-links .nav-list .nav-item .inner a:hover .hover-bg {
    opacity: 1;
}

.blog-nav-links .nav-list .nav-item .inner a:hover h6 {
    color: #fff;
}

.blog-nav-links .nav-list .nav-item .inner a:hover h6::before {
    color: #fff;
}

.comment-list-wrapper {
    padding-top: 30px;
    margin-top: 45px;
}

.comment-list-wrapper .title {
    display: block;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 50px;
}

.comment-list-wrapper .title span {
    color: #076fcc;
}

.comment-list {
    margin: 0;
    padding: 0;
}

.comment-list .comment {
    list-style-type: none;
    padding: 25px 0;
}

.comment-list .comment:last-child {
    padding-bottom: 0;
}

.comment-list .comment-author {
    float: left;
}

.comment-list .comment-author img {
    border-radius: 50px;
}

.comment-list .comment-content {
    position: relative;
    overflow: hidden;
    margin-left: 100px;
}

.comment-list .meta {
    margin-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width:479px) {
    .comment-list .meta {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.comment-list .meta .fn {
    font-size: 15px;
    text-transform: uppercase;
    color: #333;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 5px;
}

.comment-list .meta .comment-datetime {
    position: relative;
    display: inline-block;
    padding-left: 15px;
    margin-left: 10px;
    font-size: 14px;
    font-style: italic;
    color: #ababab;
}

.comment-list .meta .comment-datetime::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    width: 3px;
    height: 3px;
    background: #d8d8d8;
    border-radius: 50%
}

.comment-list .meta .star-rating {
    font-size: 12px;
    position: relative;
    display: block;
    margin-right: 10px;
}

@media only screen and (max-width:479px) {
    .comment-list .meta .star-rating {
        margin-top: 10px;
    }
}

.comment-list .meta .star-rating::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    content: "    ";
    color: #ababab;
}

.comment-list .meta .star-rating .rating-active {
    font-size: 0;
    position: absolute;
    top: 3px;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%
}

.comment-list .meta .star-rating .rating-active::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    font-weight: 900;
    line-height: 1;
    content: "    ";
    white-space: nowrap;
    color: #f5cc26;
}

.comment-list .comment-actions a {
    margin-right: 20px;
    font-weight: 500;
    color: #333;
}

.comment-list .comment-actions a:hover {
    color: #076fcc;
}

.comment-list .children {
    margin: 20px 0 20px 50px;
    padding: 0;
}

@media only screen and (max-width:767px) {
    .comment-list .children {
        margin: 20px 0 20px 25px;
    }
}

.comment-list .children li+li {
    margin-top: 35px;
}

.comment-list .comment-actions {
    margin-top: 16px;
    margin-top: 16px;
    color: #ababab;
}

.comment-list .comment-actions .comment-datetime {
    display: inline-block;
    margin-right: 10px;
}

.comment-list .comment-actions .comment-reply-link {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 700;
}

.comment-form-wrap {
    margin-top: 60px;
}

.comment-form-wrap .comment-respond .title {
    display: block;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 20px;
}

.comment-form-wrap .comment-respond p {
    margin-bottom: 30px;
}

.comment-form-wrap .comment-respond .comment-form-rating label {
    font-weight: 500;
    color: #111;
    margin-right: 26px;
}

.comment-form-wrap .comment-respond .comment-form-rating .rating {
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    line-height: 1;
    position: relative;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: #f5cc26;
}

.comment-form-wrap .comment-respond .comment-form-rating .rating::before {
    content: "    "
}

.comment-form-wrap .comment-respond .comment-form-rating .rating .star {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.comment-form-wrap .comment-respond .comment-form-rating .rating .star::before {
    font-weight: 700;
    content: "    "
}

.product .thumbnail {
    position: relative;
    margin-bottom: 24px;
}

.product .thumbnail .image {
    display: block;
    overflow: hidden;
}

.product .thumbnail .image img {
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    -o-transition: all 1.5s cubic-bezier(0, 0, .2, 1);
    transition: all 1.5s cubic-bezier(0, 0, .2, 1);
}

.product .thumbnail .actions {
    position: absolute;
    top: 0;
    right: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    opacity: 0;
}

.product .thumbnail .actions .action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 5px 0;
    padding: 10px;
    color: #333;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .07);
    box-shadow: 0 0 4px rgba(0, 0, 0, .07);
}

.product .thumbnail .actions .action:hover {
    color: #fff;
    background-color: #076fcc;
}

.product .info {
    text-align: center;
}

.product .info.large-size .title {
    font-size: 20px;
    line-height: 1.6;
    color: #3f3a64;
}

.product .info.large-size .price {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    margin-top: 5px;
}

.product .info .title {
    font-size: 18px;
    line-height: 1.67;
    margin-bottom: 0;
}

.product .info .price {
    font-size: 15px;
    font-weight: 600;
    display: block;
    margin-top: 2px;
    color: #076fcc;
}

.product .info .price .old {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: line-through;
    color: #8c89a2;
}

.product:hover .thumbnail .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.product:hover .thumbnail .actions {
    opacity: 1;
}

.single-product-image {
    position: relative;
    overflow: hidden;
    display: block;
}

.single-product-image img {
    width: 100%
}

.single-product-image .product-badges {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
}

.single-product-image .product-badges span {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px 3px;
    padding: 7px 7px 5px;
    min-height: 0;
    color: #fff;
    background-color: #076fcc;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.08;
    border-radius: 3px;
    min-width: 50px;
}

.single-product-content {
    padding-left: 40px;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .single-product-content {
        padding-left: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .single-product-content {
        padding-left: 0;
        margin-top: 30px;
    }
}

@media only screen and (max-width:767px) {
    .single-product-content {
        padding-left: 0;
        margin-top: 30px;
    }
}

.single-product-content .title {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;
    margin: 0 0 13px;
}

.single-product-content .prices {
    font-size: 18px;
    line-height: 1.34;
    font-weight: 600;
    margin-bottom: 8px;
}

.single-product-content .prices .price-new {
    display: inline-block;
    color: #076fcc;
}

.single-product-content .prices .price-old {
    display: inline-block;
    font-size: 13px;
    text-decoration: line-through;
    color: #8c89a2;
    margin-left: 6px;
}

.single-product-content .product-ratings {
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px;
}

.single-product-content .product-ratings .star-rating {
    font-size: 12px;
    position: relative;
    display: block;
    margin-right: 10px;
}

.single-product-content .product-ratings .star-rating::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    content: "    ";
    color: #ababab;
}

.single-product-content .product-ratings .star-rating .rating-active {
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%
}

.single-product-content .product-ratings .star-rating .rating-active::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    font-weight: 900;
    line-height: 1;
    content: "    ";
    white-space: nowrap;
    color: #f5cc26;
}

.single-product-content .product-ratings .review-link {
    margin-left: 16px;
    font-size: 15px;
    line-height: 1;
    position: relative;
    top: 2px;
}

.single-product-content .product-description {
    margin-top: 20px;
    margin-bottom: 35px;
}

.single-product-content .quantity-button-wrapper {
    margin-bottom: 30px;
}

.single-product-content .quantity-button-wrapper .quantity {
    display: inline-block;
}

.single-product-content .quantity-button-wrapper .quantity label {
    display: inline-block;
    margin: 12px 0 0;
    min-width: 81px;
    padding-right: 15px;
}

.single-product-content .quantity-button-wrapper .quantity input {
    display: inline-block;
    margin-right: 25px;
    position: relative;
    max-width: 110px;
    padding: 2px 30px 2px 10px;
    width: 100%;
    min-height: 48px;
    border: 1px solid #f8f8f8;
    background: #f8f8f8;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #333;
}

.single-product-content .quantity-button-wrapper .stock {
    display: inline-block;
    position: relative;
    padding: 9px 0 8px 20px;
    margin-bottom: 0;
    font-size: 15px;
}

.single-product-content .quantity-button-wrapper .stock::before {
    color: #38cb89;
    /* content: '\f058'; */
    font-family: "Font Awesome 5 Pro";
    font-size: 13px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.single-product-content .product-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 23px;
    margin-bottom: 18px;
    border-bottom: 1px solid #eee;
}

.single-product-content .product-action button {
    vertical-align: top;
    margin-right: 11px;
    margin-bottom: 15px;
}

.single-product-content .product-action button.btn-compare,
.single-product-content .product-action button.btn-wishlist {
    position: relative;
    display: block;
    width: 55px;
    height: 53px;
    border: 1px solid #f8f8f8;
    background: #f8f8f8;
    color: #333;
    border-radius: 5px;
    line-height: 53px;
    font-size: 16px;
    padding: 0 !important;
}

.single-product-content .product-action button.btn-compare:hover,
.single-product-content .product-action button.btn-wishlist:hover {
    border-color: #076fcc;
    background-color: #076fcc;
    color: #fff;
}

.single-product-content .product-meta {
    margin-top: 15px;
}

.single-product-content .product-meta .meta-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 2px;
}

.single-product-content .product-meta .meta-item h6 {
    font-size: 14px;
    font-weight: 400;
    min-width: 100px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 15px 0 0;
}

.single-product-content .product-meta .meta-item .meta-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.single-product-content .entry-product-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 6px;
}

.single-product-content .entry-product-share h6 {
    font-size: 14px;
    font-weight: 400;
    min-width: 100px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 15px 0 0;
}

.single-product-content .entry-product-share .author-social-networks {
    margin: 0 -10px;
}

.single-product-content .entry-product-share .author-social-networks a {
    padding: 0 10px;
    font-size: 15px;
}

.product-info-tab-list {
    position: relative;
    overflow: visible;
    margin: 0;
    padding: 0;
    text-align: center;
}

.product-info-tab-list::before {
    position: absolute;
    top: auto;
    right: auto;
    bottom: 1px;
    display: block;
    width: 100%;
    height: 1px;
    border: 0 !important;
    background: #ededed;
    content: ''
}

.product-info-tab-list li {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin: 0 18px;
}

.product-info-tab-list li a {
    display: block;
    padding: 10px 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    color: #ccc;
    border-bottom: 3px solid transparent;
}

@media only screen and (max-width:767px) {
    .product-info-tab-list li a {
        font-size: 20px;
    }
}

.product-info-tab-list li a:hover {
    color: #333;
}

.product-info-tab-list li a.active {
    color: #333;
    border-bottom-color: #076fcc;
}

.description-list {
    padding-top: 40px;
}

.description-list ul {
    padding-left: 20px;
    margin-top: 15px;
}

.description-list ul li+li {
    margin: 10px 0 0;
}

.review-list-wrapper {
    margin-top: 0;
    padding-top: 40px;
}

.cart-table .table {
    border-bottom: 2px solid #333;
    margin: 0;
}

@media only screen and (max-width:767px) {
    .cart-table .table {
        border-top: 2px solid #333;
    }
}

.cart-table .table thead {
    background-color: #333;
}

@media only screen and (max-width:767px) {
    .cart-table .table thead {
        display: none;
    }
}

/* .cart-table .table thead tr th {
    text-align: center;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding: 12px 20px;
} */
.cart-table .table thead tr th {
    text-align: center;
    border: none;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    padding: 12px 20px;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
}
@media only screen and (min-width:768px) and (max-width:991px) {
    .cart-table .table thead tr th {
        padding: 12px 12px;
        min-width: 200px;
    }
}

.cart-table .table tbody tr td {
    text-align: center;
    border: none;
    padding: 25px 20px;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
}

@media only screen and (max-width:767px) {
    .cart-table .table tbody tr td {
        display: block;
        width: 100%;
        max-width: none;
        padding: 15px;
        text-align: left;
    }
}
/* @media (max-width:600px) {
    .cart-table td.pro-thumbnail a img {
            width: 70%!important;
            background-color: #f6f7f8;
        }

} */

.cart-table td.pro-thumbnail,
.cart-table th.pro-thumbnail {
    max-width: 100px!important;
    min-width: 100px;
    width: 120px;
}

@media only screen and (max-width:767px) {

    .cart-table td.pro-thumbnail a,
    .cart-table th.pro-thumbnail a {
        width: 100px;
    }
}

.cart-table td.pro-title,
.cart-table th.pro-title {
    min-width: 200px;
}

.cart-table td.pro-thumbnail a {
    display: block;
    /* border: 1px solid #eee; */
}

.cart-table td.pro-thumbnail a img {
    width: 85%;
    background-color: #f6f7f8;
    border-radius: 10px;
    /* border: 2px solid blue; */
}

.cart-table td.pro-title a {
    font-size: 16px;
    font-weight: 600;
    color: #696969;
}

.cart-table td.pro-title a:hover {
    color: #076fcc;
}

.cart-table td.pro-price span {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #696969;
}

.cart-table td.pro-quantity .pro-qty {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-align: center;
    border: 1px solid #eee;
}

.cart-table td.pro-quantity .pro-qty .qtybtn {
    height: 42px;
    padding: 0 10px;
    border: none;
    background-color: transparent;
}

.cart-table td.pro-quantity .pro-qty input {
    min-height: 42px !important;
    width: 50px;
    text-align: center;
    border-width: 0 1px;
    border-style: solid;
    border-color: #eee;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: #696969;
}

.cart-table td.pro-subtotal span {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #696969;
}

.cart-table td.pro-stock span.in-stock {
    font-size: 14px;
    text-transform: capitalize;
    color: #30b878;
}

.cart-table td.pro-stock span.out-stock {
    color: #eb3e32;
}

.cart-table td.pro-remove a {
    display: block;
    font-weight: 600;
    color: #696969;
}

.cart-table td.pro-remove a i {
    font-size: 15px;
}

.cart-table td.pro-remove a:hover {
    color: red;
}

@media only screen and (max-width:767px) {
    .cart-table td.pro-remove a {
        width: 60px;
        text-align: center;
    }
}

.calculate-shipping {
    margin-bottom: 23px;
}

.calculate-shipping h4 {
    font-size: 20px;
    line-height: 23px;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px;
}

.calculate-shipping form select {
    width: 100%;
    border-radius: 0;
    height: 55px;
    border: 1px solid #999;
    line-height: 40px;
    padding: 9px 20px;
    color: #696969;
    background-color: transparent;
}

.discount-coupon h4 {
    font-size: 20px;
    line-height: 23px;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px;
}

.cart-summary {
    float: right;
    max-width: 410px;
    width: 100%;
    margin-left: auto;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cart-summary {
        margin-left: 0;
        max-width: 100%
    }
}

@media only screen and (max-width:767px) {
    .cart-summary {
        margin-left: 0;
        max-width: 100%
    }
}

.cart-summary .cart-summary-wrap {
    background-color: #f5f5f5;
    padding: 45px 50px;
    margin-bottom: 20px;
}

@media only screen and (max-width:575px) {
    .cart-summary .cart-summary-wrap {
        padding: 25px 30px;
    }
}

.cart-summary .cart-summary-wrap h4 {
    font-size: 20px;
    line-height: 23px;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px;
    color: #333;
}

.cart-summary .cart-summary-wrap p {
    font-weight: 600;
    line-height: 23px;
    color: #333;
}

.cart-summary .cart-summary-wrap p span {
    float: right;
}

.cart-summary .cart-summary-wrap h2 {
    border-top: 1px solid #333;
    padding-top: 9px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.cart-summary .cart-summary-wrap h2 span {
    float: right;
}

.cart-summary .cart-summary-button {
    overflow: hidden;
    width: 100%
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cart-summary .cart-summary-button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

@media only screen and (max-width:767px) {
    .cart-summary .cart-summary-button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.cart-summary .cart-summary-button button {
    margin-left: 20px;
    float: right;
}

.cart-summary .cart-summary-button button:last-child {
    margin-left: 0;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .cart-summary .cart-summary-button button {
        margin-left: 0;
        margin-right: 20px;
    }

    .cart-summary .cart-summary-button button:last-child {
        margin-right: 0;
    }
}

@media only screen and (max-width:767px) {
    .cart-summary .cart-summary-button button {
        margin-left: 0;
        margin-right: 10px;
    }

    .cart-summary .cart-summary-button button:last-child {
        margin-right: 0;
    }
}

@media only screen and (max-width:575px) {
    .cart-summary .cart-summary-button button {
        width: 130px;
    }
}

.cart-empty-content {
    text-align: center;
}

.cart-empty-content .icon {
    font-size: 80px;
    line-height: 1;
    display: block;
    margin-bottom: 30px;
}

.cart-empty-content .icon i {
    display: block;
}

.cart-empty-content .title {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 18px;
}

.cart-empty-content p {
    margin-bottom: 0;
}

.cart-empty-content .btn {
    margin-top: 30px;
}

.checkout-title {
    font-size: 20px;
    line-height: 23px;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px;
}

.checkout-form label {
    display: block;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: capitalize;
}

.checkout-form select {
    width: 100%;
    background-color: transparent;
    border: 1px solid #999;
    border-radius: 0;
    line-height: 23px;
    padding: 10px 20px;
    font-size: 14px;
    height: 55px;
    color: #696969;
    margin-bottom: 15px;
}

.checkout-form input {
    margin-bottom: 15px;
}

.checkout-form input[type=checkbox] {
    width: auto;
}

.checkout-form .check-box {
    float: left;
    margin-right: 70px;
}

@media only screen and (max-width:575px) {
    .checkout-form .check-box {
        margin-right: 20px;
    }
}

@media only screen and (max-width:479px) {
    .checkout-form .check-box {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.checkout-form .check-box:last-child {
    margin-right: 0;
}

.checkout-form .check-box input[type=checkbox] {
    display: none;
}

.checkout-form .check-box input[type=checkbox]+label {
    position: relative;
    padding-left: 30px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    margin: 0;
}

.checkout-form .check-box input[type=checkbox]+label::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    display: block;
    border: 2px solid #999;
    content: "";
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.checkout-form .check-box input[type=checkbox]+label::after {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    line-height: 20px;
    opacity: 0;
    color: #333;
    width: 20px;
    text-align: center;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.checkout-form .check-box input[type=checkbox]:checked+label::before {
    border: 2px solid #333;
}

.checkout-form .check-box input[type=checkbox]:checked+label::after {
    opacity: 1;
}

#shipping-form {
    display: none;
}

.checkout-cart-total {
    background-color: #f5f5f5;
    padding: 45px;
}

@media only screen and (max-width:575px) {
    .checkout-cart-total {
        padding: 30px;
    }
}

.checkout-cart-total h4 {
    -ms-flex-preferred-size: 18px;
    flex-basis: 18px;
    line-height: 23px;
    font-weight: 600;
    color: #333;
}

.checkout-cart-total h4:first-child {
    margin-top: 0;
    margin-bottom: 25px;
}

.checkout-cart-total h4:last-child {
    margin-top: 15px;
    margin-bottom: 0;
}

.checkout-cart-total h4 span {
    float: right;
    display: block;
}

.checkout-cart-total ul {
    padding-left: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #333;
}

.checkout-cart-total ul li {
    color: #333;
    font-size: 14px;
    line-height: 23px;
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
}

.checkout-cart-total ul li span {
    color: #333;
    float: right;
}

.checkout-cart-total p {
    line-height: 30px;
    font-weight: 600;
    color: #333;
    padding: 10px 0;
    border-bottom: 1px solid #333;
    margin: 0;
}

.checkout-cart-total p span {
    float: right;
}

.checkout-payment-method {
    background-color: #f5f5f5;
    padding: 45px;
}

@media only screen and (max-width:575px) {
    .checkout-payment-method {
        padding: 30px;
    }
}

.single-method {
    margin-bottom: 20px;
}

.single-method:last-child {
    margin-bottom: 0;
}

.single-method input[type=radio] {
    display: none;
}

.single-method input[type=radio]+label {
    position: relative;
    padding-left: 30px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    margin: 0;
}

.single-method input[type=radio]+label::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    display: block;
    border: 2px solid #333;
    content: "";
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.single-method input[type=radio]+label::after {
    position: absolute;
    left: 5px;
    top: 5px;
    display: block;
    content: "";
    opacity: 0;
    background-color: #076fcc;
    width: 10px;
    text-align: center;
    height: 10px;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.single-method input[type=radio]:checked+label::before {
    border: 2px solid #076fcc;
}

.single-method input[type=radio]:checked+label::after {
    opacity: 1;
}

.single-method input[type=checkbox] {
    display: none;
}

.single-method input[type=checkbox]+label {
    position: relative;
    padding-left: 30px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    margin: 0;
}

.single-method input[type=checkbox]+label::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    display: block;
    border: 2px solid #333;
    content: "";
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.single-method input[type=checkbox]+label::after {
    position: absolute;
    left: 4px;
    top: 4px;
    display: block;
    content: "";
    opacity: 0;
    background-color: #076fcc;
    width: 8px;
    text-align: center;
    height: 8px;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.single-method input[type=checkbox]:checked+label::before {
    border: 2px solid #076fcc;
}

.single-method input[type=checkbox]:checked+label::after {
    opacity: 1;
}

.single-method p {
    display: none;
    margin-top: 8px;
    color: #333;
    line-height: 23px;
}

.place-order {
    margin-top: 40px;
    float: left;
}

.myaccount-tab-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
}

.myaccount-tab-menu a {
    border: 1px solid #eee;
    border-bottom: none;
    color: #696969;
    font-weight: 500;
    font-size: 12px;
    display: block;
    padding: 15px 15px 13px;
    text-transform: uppercase;
    text-align: left;
    font-family: "Outfit", sans-serif;
}

.myaccount-tab-menu a:last-child {
    border-bottom: 1px solid #eee;
}

.myaccount-tab-menu a.active,
.myaccount-tab-menu a:hover {
    background-color: #076fcc;
    color: #fff;
}

.myaccount-tab-menu a i {
    font-size: 14px;
    text-align: center;
    width: 25px;
}

.myaccount-content {
    background-color: #fff;
    font-size: 14px;
    border: 1px solid #e6e6e6;
    padding: 30px;
}

@media only screen and (max-width:575px) {
    .myaccount-content {
        padding: 20px 15px;
    }
}

.myaccount-content h3 {
    border-bottom: 1px dashed #eee;
    padding-bottom: 10px;
    margin-bottom: 25px;
}

.myaccount-content .welcome a {
    color: #000;
}

.myaccount-content .welcome a:hover {
    color: #076fcc;
}

.myaccount-content .welcome strong {
    font-weight: 600;
}

.myaccount-content a.edit-address-btn {
    border-color: #333;
}

.myaccount-content a.edit-address-btn i {
    padding-right: 5px;
}

.myaccount-content a.edit-address-btn:hover {
    color: #076fcc;
}

.myaccount-table {
    white-space: nowrap;
    font-size: 15px;
}

.myaccount-table .table th,
.myaccount-table table th {
    padding: 10px;
    font-weight: 600;
}

.myaccount-table .table td,
.myaccount-table table td {
    padding: 20px 10px;
    vertical-align: middle;
}

.myaccount-table .table td a:hover,
.myaccount-table table td a:hover {
    color: #fff;
}

.saved-message {
    border-top: 3px solid #076fcc;
    border-radius: 5px 5px 0 0;
    font-weight: 600;
    font-size: 13px;
    padding: 20px;
}

.account-details-form h4 {
    margin: 0;
}

.sidebar-widget-wrapper {
    float: left;
    width: 100%;
    padding-right: 30px;
}

@media only screen and (min-width:992px) and (max-width:1199px),
only screen and (min-width:768px) and (max-width:991px),
only screen and (max-width:767px) {
    .sidebar-widget-wrapper {
        padding-right: 0;
    }
}

.sidebar-widget {
    float: left;
    width: 100%
}

.sidebar-widget+.sidebar-widget {
    margin-top: 45px;
}

.sidebar-widget-title {
    font-size: 24px;
    margin-bottom: 30px;
}

.sidebar-widget-search form {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}

.sidebar-widget-search form input {
    padding-right: 50px;
}

.sidebar-widget-search form button {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    color: #076fcc;
    border: none;
    border-radius: 4px;
    background-color: transparent;
}

.sidebar-widget-search form button:hover {
    color: #fff;
    background-color: #076fcc;
}

.sidebar-widget-price-filter {
    padding-bottom: 24px;
    border-bottom: 2px solid #f3f3f3;
}

.sidebar-widget-price-filter .irs--maxCoach {
    margin-bottom: 65px;
}

.sidebar-widget-price-filter .irs--maxCoach .irs .irs-single {
    top: calc(100% + 38px);
}

.sidebar-widget-price-filter .btn-price-filter {
    font-size: 14px;
    line-height: 40px;
    float: right;
    height: 40px;
    margin-top: -40px;
    padding: 0 30px;
}

.widget-product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.widget-product+.widget-product {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #f3f3f3;
}

.widget-product .thumbnail {
    display: block;
    min-width: 100px;
}

.widget-product .thumbnail img {
    width: 100%
}

.widget-product .info {
    padding-left: 20px;
}

.widget-product .info .title {
    font-size: 15px;
    line-height: 1.34;
}

.widget-product .info .rating {
    font-size: 11px;
    line-height: 1;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 68px;
    margin: 10px 0;
    color: #ddd;
}

.widget-product .info .rating::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f005\f005\f005\f005\f005"
}

.widget-product .info .rating span {
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #f6b500;
}

.widget-product .info .rating span::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f005\f005\f005\f005\f005"
}

.widget-product .info .price {
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-top: 7px;
    color: #076fcc;
}

.widget-product .info .price .old {
    font-size: 12px;
    margin-left: 6px;
    text-decoration: line-through;
    color: #8c89a2;
}

.sidebar-widget-list-post {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-widget-list-post li+li {
    border-top: 1px solid #eee;
}

.sidebar-widget-list-post li a {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.78;
    position: relative;
    display: block;
    padding: 16px 0 15px 30px;
}

.sidebar-widget-list-post li a::after,
.sidebar-widget-list-post li a::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 15px;
    line-height: 15px;
    position: absolute;
    top: 24px;
    left: 0;
    content: "\f30b";
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.sidebar-widget-list-post li a::after {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    color: #076fcc;
}

.sidebar-widget-list-post li a:hover::before {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
}

.sidebar-widget-list-post li a:hover::after {
    visibility: visible;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.sidebar-widget-menu {
    list-style: none;
    padding-left: 0;
}

.sidebar-widget-menu li+li {
    margin-top: -1px;
}

.sidebar-widget-menu li a {
    position: relative;
    display: block;
    padding: 16px 40px;
    border: 1px solid #eee;
}

.sidebar-widget-menu li a::before {
    content: '\f111';
    font-size: 6px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    left: 16px;
    color: #ababab;
    font-family: "Font Awesome 5 Pro"
}

.sidebar-widget-menu li a.active,
.sidebar-widget-menu li a:hover {
    background-color: #076fcc;
    border-color: #076fcc;
    color: #fff;
}

.sidebar-widget-menu li a.active::before,
.sidebar-widget-menu li a:hover::before {
    color: #fff;
}

.sidebar-widget-course {
    padding-left: 0;
    list-style: none;
}

.sidebar-widget-course .widget-course {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%
}

.sidebar-widget-course .widget-course+.widget-course {
    border-top: 1px solid #eee;
    padding-top: 20px;
    margin-top: 20px;
}

.sidebar-widget-course .widget-course .thumbnail {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    margin: 0 20px 0 0;
    border-radius: 50%
}

.sidebar-widget-course .widget-course .info {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding: 0;
}

.sidebar-widget-course .widget-course .info .price {
    font-size: 15px;
    font-weight: 800;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 0 10px;
    color: #076fcc;
}

.sidebar-widget-course .widget-course .info .title {
    font-size: 15px;
    line-height: 1.6;
    margin: 0;
}

.sidebar-entry-course-info {
    padding: 30px 30px 33px;
    background: #fff;
    -webkit-box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    border-radius: 5px;
    width: 100%;
    max-width: 340px;
    float: right;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .sidebar-entry-course-info {
        max-width: 100%
    }
}

@media only screen and (max-width:767px) {
    .sidebar-entry-course-info {
        max-width: 100%
    }
}

@media only screen and (max-width:479px) {
    .sidebar-entry-course-info {
        max-width: 100%;
        padding: 20px 20px 23px;
    }
}

.sidebar-entry-course-info .course-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-bottom: 7px;
}

.sidebar-entry-course-info .course-price .meta-label {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 15px;
    color: #333;
    font-weight: 700;
    line-height: 1.3;
}

.sidebar-entry-course-info .course-price .meta-label i {
    color: #696969;
    min-width: 28px;
    text-align: center;
}

.sidebar-entry-course-info .course-price .meta-value {
    text-align: right;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.sidebar-entry-course-info .course-price .meta-value .price {
    font-size: 24px;
    font-weight: 800;
    color: #076fcc;
}

.sidebar-entry-course-info .course-price .meta-value .price .decimals-separator {
    font-size: 18px;
}

.sidebar-entry-course-info .course-meta {
    margin-bottom: 17px;
}

.sidebar-entry-course-info .course-meta>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 15px 0;
}

.sidebar-entry-course-info .course-meta>div+div {
    border-top: 1px solid #eee;
}

.sidebar-entry-course-info .course-meta>div .meta-label {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 15px;
    color: #333;
    font-weight: 700;
    line-height: 1.3;
}

.sidebar-entry-course-info .course-meta>div .meta-label i {
    color: #696969;
    min-width: 28px;
    text-align: center;
}

.sidebar-entry-course-info .course-meta>div .meta-value {
    text-align: right;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.entry-course-share {
    margin-top: 20px;
    text-align: center;
}

.entry-course-share .share-media {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.entry-course-share .share-media:hover {
    color: #076fcc;
}

.entry-course-share .share-media .share-label {
    font-size: 14px;
    font-weight: 700;
    margin-right: 15px;
}

.entry-course-share .share-media .share-icon {
    font-size: 15px;
}

.entry-course-share .share-media .share-list {
    position: absolute;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -10px);
    -ms-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
    width: auto;
    white-space: nowrap;
    padding: 0 4px;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, .06);
    box-shadow: 0 2px 20px rgba(0, 0, 0, .06);
    -webkit-filter: drop-shadow(0 2px 20px rgba(0, 0, 0, .06));
    filter: drop-shadow(0 2px 20px rgba(0, 0, 0, .06));
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.entry-course-share .share-media .share-list::before {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    content: '';
    border-top: 8px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}

.entry-course-share .share-media .share-list a {
    display: inline-block;
    font-size: 15px;
    padding: 10px 12px;
    color: #ababab;
}

.entry-course-share .share-media .share-list a:hover {
    color: #076fcc;
}

.entry-course-share .share-media:hover .share-list {
    -webkit-transform: translate(-50%, -20px);
    -ms-transform: translate(-50%, -20px);
    transform: translate(-50%, -20px);
    visibility: visible;
    opacity: 1;
}

.sidebar-entry-event-info {
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    padding: 27px 30px 30px;
}

@media only screen and (max-width:479px) {
    .sidebar-entry-event-info {
        padding: 20px 20px 23px;
    }
}

.sidebar-entry-event-info .title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 700;
    color: #333;
}

.sidebar-entry-event-info .event-meta>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 0 !important;
    padding: 10px 0;
    min-height: 62px;
}

.sidebar-entry-event-info .event-meta>div .name {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    color: #333;
    width: 40%
}

.sidebar-entry-event-info .event-meta>div .value {
    width: 60%;
    text-align: right;
}

.sidebar-entry-event-info .event-meta .notice {
    margin-top: 20px;
    margin-bottom: 0;
}

.sidebar-entry-event-info .lp-event-buttons {
    margin-top: 30px;
}

.sidebar-entry-event-info .lp-event-buttons button {
    margin-bottom: 10px;
}

.sidebar-entry-event-info .lp-event-buttons button:last-child {
    margin-bottom: 0;
}

.sidebar-entry-event {
    padding: 14px 30px 33px;
    background: #fff;
    -webkit-box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    box-shadow: 0 0 20px rgba(51, 51, 51, .1);
    border-radius: 5px;
    width: 100%;
    max-width: 340px;
    min-height: 390px;
    float: right;
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .sidebar-entry-event {
        max-width: 100%
    }
}

@media only screen and (max-width:767px) {
    .sidebar-entry-event {
        max-width: 100%
    }
}

.sidebar-entry-event .entry-event-info {
    list-style-type: none;
    margin: 0 0 20px;
    padding: 0;
}

.sidebar-entry-event .entry-event-info li {
    margin: 0;
    padding: 14px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.sidebar-entry-event .entry-event-info li+li {
    border-top: 1px solid #eee;
}

.sidebar-entry-event .entry-event-info li.meta-price {
    padding: 8px 0;
}

.sidebar-entry-event .entry-event-info li .meta-label {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.3;
    color: #333;
}

.sidebar-entry-event .entry-event-info li .meta-label .meta-icon {
    color: #696969;
    min-width: 28px;
    text-align: center;
}

.sidebar-entry-event .entry-event-info li .meta-value {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: right;
}

.sidebar-entry-event .entry-event-info li .meta-value .event-price {
    font-size: 24px;
    font-weight: 800;
    color: #076fcc;
}

.sidebar-entry-event .entry-event-info li .meta-value .event-price .decimals-separator {
    font-size: 18px;
}

.sidebar-entry-event .event-register-message {
    margin-top: 10px;
    text-align: center;
}

.sidebar-entry-event .event-register-message a {
    font-weight: 500;
    display: inline-block;
    color: #076fcc;
    margin-top: 0;
    margin-bottom: 0;
}

.sidebar-entry-event .event-social {
    text-align: center;
}

.tagcloud {
    margin: -5px;
}

.tagcloud a {
    font-size: 13px !important;
    font-weight: 500;
    line-height: 1.5;
    display: block;
    float: left;
    overflow: hidden;
    margin: 5px;
    padding: 7px 15px 6px;
    color: #ababab;
    border: 0;
    border-radius: 3px;
    background: #f5f5f5;
}

.tagcloud a:hover {
    color: #fff;
    background-color: #076fcc;
}
.footer-widget a{
    color: #272931!important;
}
.footer-widget-title {
    font-size: 18px;
    margin-bottom: 17px;
}

.footer-widget-content {
    font-size: 14px;
    font-weight: 500;
}

.footer-widget-content.primary-three ul li a:hover {
    color: #ef6f31;
}

.footer-widget-content p {
    margin-bottom: 11px;
}

.footer-widget-content p:last-child {
    margin-bottom: 0;
}

.footer-widget-content ul {
    margin-right: -20px;
    margin-bottom: -11px;
    padding-left: 0;
    list-style: none;
}

.footer-widget-content ul li {
    margin-bottom: 11px;
    padding-right: 20px;
}

.footer-widget-content ul.column-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.footer-widget-content ul.column-2 li {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%
}

.footer-social-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-right: -25px;
    margin-bottom: -15px;
}

.footer-social-inline.primary-three a:hover {
    color: #ef6f31;
}

.footer-social-inline a {
    margin-right: 25px;
    margin-bottom: 15px;
    color: rgba(105, 105, 105, .5);
}

.footer-social-inline a i {
    font-size: 24px;
    line-height: 1;
    display: block;
}

.footer-social-inline a:hover {
    color: #076fcc;
}

.light-color .footer-widget-title {
    color: #fff;
}

.light-color .footer-widget-content {
    color: #ababab;
}

.light-color .footer-social-inline a {
    color: rgba(255, 255, 255, .5);
}

.light-color .footer-social-inline a:hover {
    color: #fff;
}

.footer-section {
    padding: 70px 0 50px;
    background-color: #f8f8f8;
    overflow: hidden;
}

.copyright {
    font-size: 14px;
    text-align: center;
    color: #ababab;
}