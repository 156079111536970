.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
html,
body {
  width: 100%!important;
  height: 100%!important;
  margin: 0!important;
  padding: 0!important;
  /* overflow-x: hidden; */
}
*,
*:before,
*:after {
  box-sizing: border-box!important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fade-in-text {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 4.5s;
}
.fade-in-text-two {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 4.8s;
}
.fade-in-text-three {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 5s;
}
.hero-anim {
  /* animation: banner-logo-spin 4s ease-in-out; */
  animation-duration: .5s;
  animation: bannerMovement 2s infinite ease-in-out;
  /* width: 50px; */
}
@keyframes bannerMovement {

  0%,
  100% {
    transform: translateY(0);
    /* opacity: .6; */
  }

  50% {
    transform: translateY(-8px);
    /* opacity: .8; */
  }
}
@keyframes zoomIn {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
    /* Optional: You can add a slight upward movement */
  }

  100% {
    opacity: 1;
    transform: translateY(22);
  }
}


#preloaderBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #16181b;
  z-index: 99999;
  overflow: hidden !important;
}

.glow-h1 {
  font-size: 2rem;
  margin-top: 45vh;
  color: white;
  text-align: center;
  /* font-family: 'Merienda', cursive; */
  animation-name: glow;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes glow {
  from {
    text-shadow: 0px 0px 5px #fff, 0px 0px 5px #fff;
  }

  to {
    text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff;
  }
}
#typewriter_body {
    position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #151515;
      z-index: 99999;
      overflow: hidden !important;
}

.typewriter_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.typewriter_h1 {
  color: #dbd56e;
  background: -webkit-linear-gradient(25deg, #eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  animation: type 3.5s steps(90) 1.5s 1 normal both, cursor 3s step-end infinite;
  position: relative;
}

#fadeInText{
  display: none;
}

@keyframes type {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}