.fill__Wrapper-ehv7z4-0 {
    margin-left: auto !important;
    margin-right: auto !important;
}

.gNBCuL {
    margin-left: auto !important;
    margin-right: auto !important;
}

.ant-spin-fullscreen {

    /* justify-content: normal */
}