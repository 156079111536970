@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
} */

.lr-section {
    position: relative;
    min-height: 100vh;
    background-color: #2f64a700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.fd-txt {
    position: absolute;
    top: 0px;
    left: 8px;
    /* font-size: 59px; */
}
.lr-section .container {
    position: relative;
    width: 800px;
    height: 500px;
    background: #f7edd5;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.lr-section .container .user {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.lr-section .container .user .imgBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #ff0;
    transition: 0.5s;
}

.lr-section .container .user .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lr-section .container .user .formBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    transition: 0.5s;
}


.lr-section .container .user .formBx form h2 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    color: #555;
}

.lr-section .container .user .formBx form input {
    position: relative;
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
}

.lr-section .container .user .formBx form input[type='button'] {
    max-width: 100px;
    background: #677eff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.5s;
}

.lr-section .container .user .formBx form .signup {
    position: relative;
    margin-top: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #555;
    text-transform: uppercase;
    font-weight: 300;
}

.lr-section .container .user .formBx form .signup a {
    font-weight: 600;
    text-decoration: none;
    color: #677eff;
}

.lr-section .container .signupBx {
    pointer-events: none;
}

.lr-section .container.active .signupBx {
    pointer-events: initial;
}

.lr-section .container .signupBx .formBx {
    left: 100%;
}

.lr-section .container.active .signupBx .formBx {
    left: 0;
}

.lr-section .container .signupBx .imgBx {
    left: -100%;
}

.lr-section .container.active .signupBx .imgBx {
    left: 0%;
}

.lr-section .container .signinBx .formBx {
    left: 0%;
}

.lr-section .container.active .signinBx .formBx {
    left: 100%;
}

.lr-section .container .signinBx .imgBx {
    left: 0%;
}

.lr-section .container.active .signinBx .imgBx {
    left: -100%;
}

@media (max-width: 991px) {
    .lr-section .container {
        max-width: 400px;
    }

    .lr-section .container .imgBx {
        display: none;
    }

    .lr-section .container .user .formBx {
        width: 100%;
    }
}